import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import {
  DocumentReference,
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'
import { toast, ToastContainer } from 'react-toastify'

export interface addAsset {
  msg: string
  message: string
  checked?: boolean | undefined
}
interface AddAssetsProps {
  isOpen: boolean
  onClose: () => void
}

const ProductDrawer: React.FC<AddAssetsProps> = ({isOpen, onClose}) => {
  const [productName, setProductName] = useState('')
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [productUrl, setProductUrl] = useState('')
  const [contactUrl, setContactUrl] = useState('')
  const [loginUrl, setLoginUrl] = useState('')
  const [policyUrl, setPolicyUrl] = useState('')
  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const drawerTopRef = useRef(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const focusRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (currentUser?.tenantId) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId)
        const companyRef = doc(tenantRef, 'settings', 'product')
        const docSnap = await getDoc(companyRef)

        if (docSnap.exists()) {
          const productData = docSnap.data().newAsset
          setProductName(productData.productName || '')
          setWebsiteUrl(productData.websiteUrl || '')
          setProductUrl(productData.productUrl || '')
          setContactUrl(productData.contactUrl || '')
          setLoginUrl(productData.loginUrl || '')
          setPolicyUrl(productData.policyUrl || '')
          setIsEditMode(true)
        } else {
          setIsEditMode(false)
        }
      }
    }

    fetchCompanyData()
  }, [currentUser])

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!productName.trim()) {
      errors.productName = 'Product name is required'
    }

    if (!websiteUrl.trim()) {
      errors.websiteUrl = 'Website name is required'
    }

    if (productUrl.length === 0) {
      errors.productUrl = 'Product URL is required'
    }

    if (!contactUrl.trim()) {
      errors.contactUrl = 'Contact URL is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const newAsset = {
      productName,
      websiteUrl,
      productUrl,
      contactUrl,
      loginUrl,
      policyUrl,
      tenantId: currentUser?.tenantId,
    }

    if (!validateForm()) {
      setIsLoading(false)
      return
    }

    try {
      if (currentUser) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
        const productRef: DocumentReference = doc(tenantRef, 'settings', 'product')

        await setDoc(productRef, {newAsset})

        const currentDate = new Date()
        const todaysDate = `${
          currentDate.getMonth() + 1
        }/${currentDate.getDate()}/${currentDate.getFullYear()}`

        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: isEditMode
            ? `Updated the product details '${productName}'`
            : `Added a new product '${productName}'`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          username: currentUser?.userName,
          date: todaysDate,
        })
        toast.success('Company product details updated successfully', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })
    
        setIsEditMode(true) // Set to edit mode after successful submission
      }
    } catch (error) {
     
    } finally {
      setIsLoading(false)
      window.scrollTo(0, 0)
    }


  }

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            {productName ? 'Product Details' : 'Add Product'}
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={onClose}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>
        <div
          className='scroll-y overflow-auto p-7 mb-4'
          tabIndex={-1}
          style={{height: 'calc(100vh - 30px)'}}
        >
          <form id='kt_risk_form' className='form' onSubmit={handleSubmit}>
            <div className='modal-content'>
              <div className='modal-body'>
                <div className=''>
                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mb-2 fs-6 required'>
                      Product Name
                    </label>
                    <input
                      type='text'
                      className={`form-control ${formErrors.productName ? 'is-invalid' : ''}`}
                      placeholder='Enter product name'
                      name='productName'
                      value={productName}
                      onChange={(e) => {
                        setProductName(e.target.value)
                        setFormErrors((prev) => ({...prev, productName: ''}))
                      }}
                      ref={focusRef}
                    />
                    {formErrors.productName && (
                      <span className='text-danger'>{formErrors.productName}</span>
                    )}
                  </div>

                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                      Website/Landing Page URL
                    </label>

                    <input
                      type='url'
                      className={`form-control ${formErrors.websiteUrl ? 'is-invalid' : ''}`}
                      placeholder='Enter website name'
                      value={websiteUrl}
                      onChange={(e) => {
                        setWebsiteUrl(e.target.value)
                        setFormErrors((prev) => ({...prev, websiteUrl: ''}))
                      }}
                    />
                    {formErrors.websiteUrl && (
                      <span className='text-danger'>{formErrors.websiteUrl}</span>
                    )}
                  </div>

                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                      Product/Application URL
                    </label>
                    <input
                      type='url'
                      className={`form-control ${formErrors.productUrl ? 'is-invalid' : ''}`}
                      placeholder='Enter product/application URL'
                      value={productUrl}
                      onChange={(e) => {
                        setProductUrl(e.target.value)
                        setFormErrors((prev) => ({...prev, productUrl: ''}))
                      }}
                    />
                    {formErrors.productUrl && (
                      <span className='text-danger'>{formErrors.productUrl}</span>
                    )}
                  </div>

                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                      Contact Page URL
                    </label>

                    <input
                      type='url'
                      className={`form-control ${formErrors.contactUrl ? 'is-invalid' : ''}`}
                      placeholder='Enter contact page URL'
                      value={contactUrl}
                      onChange={(e) => {
                        setContactUrl(e.target.value)
                        setFormErrors((prev) => ({...prev, contactUrl: ''}))
                      }}
                    />
                    {formErrors.contactUrl && (
                      <span className='text-danger'>{formErrors.contactUrl}</span>
                    )}

                    <div className='fs-7' style={{color: '#828282'}}>
                      Your product’s contact us page URL
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                      Login Page URL
                    </label>
                    <div className='input-group input-group-lg'>
                      <input
                        type='url'
                        className='form-control '
                        placeholder='Enter login page URL'
                        value={loginUrl}
                        onChange={(e) => setLoginUrl(e.target.value)}
                      />
                    </div>
                    <div className='fs-7' style={{color: '#828282'}}>
                      Your product’s login page URL
                    </div>
                  </div>

                  <div className='form-group mb-8'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                      Privacy Policy Page URL
                    </label>
                    <div className='input-group input-group-lg'>
                      <input
                        type='url'
                        className='form-control '
                        placeholder='Enter privacy policy page URL'
                        value={policyUrl}
                        onChange={(e) => setPolicyUrl(e.target.value)}
                      />
                    </div>
                    <div className='fs-7' style={{color: '#828282'}}>
                      Your product’s privacy policy page URL
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='offcanvas-footer d-flex justify-content-end  border-top py-4'
              style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
            >
              <button type='button' className='btn btn-light mx-2 mb-4' onClick={onClose}>
                Cancel
              </button>
              {isLoading ? (
                <button
                  type='submit'
                  className='btn mb-4'
                  style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                >
                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                  {isEditMode ? 'Update Product' : 'Add Product'}
                </button>
              ) : (
                <button
                  type='submit'
                  className='btn mb-4'
                  style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                >
                  {isEditMode ? 'Update Product' : 'Add Product'}
                </button>
              )}
            </div>
            {/* </div> */}
          </form>
        </div>
      </Drawer>
      <ToastContainer />
    </>
  )
}

export default ProductDrawer

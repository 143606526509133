import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import policyServices from '../services/policy.services'
import {useNavigate} from 'react-router-dom'

type Props = {
  id: string
  setPolicyID: any
}

const EditPolicyModal: React.FC<Props> = ({id, setPolicyID}) => {
  const [policy, setPolicy] = useState('')
  const [status, setStatus] = useState('')
  const [owner, setOwner] = useState('')
  const [description, setDescription] = useState('')
  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()

    const newPolicy = {
      policy,
      status,
      owner,
      description,
    }

    const modalContentElement = document.getElementById('kt_modal_1')
    if (status === '' || policy === '' || description === '' || owner === '') {
      setMessage({error: true, msg: 'Please fill all the fields'})
      setIsLoading(false)
      setShowAlert(true)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
      return
    }

    if (description.trim() !== '' && !/^[a-zA-Z\s]+$/.test(description)) {
      setMessage({error: true, msg: 'Please fill valid data in Description'})
      setShowAlert(true)
      setIsLoading(false)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
      return
    }

    if (policy.trim() !== '' && !/^[a-zA-Z\s]+$/.test(policy)) {
      setMessage({error: true, msg: 'Please fill valid data in Policy name'})
      setShowAlert(true)
      setIsLoading(false)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
      return
    }

    if (owner.trim() !== '' && !/^[a-zA-Z\s]+$/.test(owner)) {
      setMessage({error: true, msg: 'Please fill valid data in Owner name'})
      setShowAlert(true)
      setIsLoading(false)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
      return
    }

    try {
      await policyServices.updatePolicy(id, newPolicy)
      setPolicyID('')
      setMessage({error: false, msg: 'Update Successfully'})
      setShowAlert(true)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    } catch (err: any) {
      setMessage({error: true, msg: err.message})
      setShowAlert(true)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
    } finally {
      setIsLoading(false)
    }

    setStatus('')
    setOwner('')
    setPolicy('')
    setDescription('')
  }

  const handleStatusChange = (event) => {
    // Get the selected value from the event
    const selectedValue = event.target.value

    // Update the state variable with the selected value
    setStatus(selectedValue)
  }

  const editHandler = async () => {
    try {
      const docSnap = await policyServices.getPolicy(id)
      setPolicy(docSnap.data()?.policy)
      setDescription(docSnap.data()?.description)
      setStatus(docSnap.data()?.status)
      setOwner(docSnap.data()?.owner)
    } catch (err: any) {
      setMessage({error: true, msg: err.message})
      setShowAlert(true)
    }
  }

  useEffect(() => {
    if (id !== '' && id !== undefined) {
      editHandler()
    }
  }, [id])

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Edit Policy</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            {showAlert && (
              <div style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>
                {message?.msg && (
                  <div
                    className={
                      message?.error
                        ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                        : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
                    }
                  >
                    {message?.error ? (
                      <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    ) : (
                      <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    )}

                    <div
                      className={
                        message?.error
                          ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                          : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                      }
                    >
                      {message?.error ? (
                        <h5 className='mb-1'>Error!!</h5>
                      ) : (
                        <h5 className='mb-1'>Sucess!!</h5>
                      )}
                      <span>{message?.msg}</span>
                    </div>
                    <button
                      type='button'
                      className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                      onClick={() => setShowAlert(false)}
                    >
                      <span className='svg-icon svg-icon-1 svg-icon-success'>
                        <span className='svg-icon svg-icon-2x svg-icon-primary'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='mh-50px'
                          >
                            <rect
                              opacity='0.5'
                              x='6'
                              y='17.3137'
                              width='16'
                              height='2'
                              rx='1'
                              transform='rotate(-45 6 17.3137)'
                              fill='black'
                            ></rect>
                            <rect
                              x='7.41422'
                              y='6'
                              width='16'
                              height='2'
                              rx='1'
                              transform='rotate(45 7.41422 6)'
                              fill='black'
                            ></rect>
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                )}
              </div>
            )}
            <form className='form' onSubmit={handleSubmit}>
              <div className='card-body'>
                <div className='form-group'>
                  <label
                    className='text-dark fw-bold text-primary my-3 fs-6'
                    style={{float: 'left'}}
                  >
                    Policy Name
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={policy}
                    placeholder='Enter policy name'
                    onChange={(e: any) => setPolicy(e.target.value)}
                  />
                </div>
                <div className='form-group'>
                  <label
                    className='text-dark fw-bold text-primary my-3 fs-6'
                    style={{float: 'left'}}
                  >
                    Owner Name
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={owner}
                    placeholder='Enter owner name'
                    onChange={(e: any) => setOwner(e.target.value)}
                  />
                </div>
                <div className='form-group'>
                  <label
                    className='text-dark fw-bold text-primary mt-6 my-3 fs-6'
                    style={{float: 'left', marginTop: 10}}
                  >
                    Description
                  </label>
                  <textarea
                    className='form-control form-control-solid'
                    value={description}
                    id='description'
                    rows={3}
                    onChange={(e: any) => setDescription(e.target.value)}
                  ></textarea>
                </div>
                <div className='form-group'>
                  <label
                    className='text-dark fw-bold text-primary mt-6 my-3 fs-6'
                    style={{float: 'left', marginTop: 10}}
                  >
                    Status
                  </label>
                  <select
                    className='form-control form-control-solid '
                    id='status'
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value=''>Select the option below..</option>
                    <option value='Draft'>Draft</option>
                    <option value='Published'>Published</option>
                  </select>
                </div>
                {/* <div className='form-group'>
                  <label
                    className='text-dark fw-bold text-primary mt-6 my-3 fs-6'
                    style={{float: 'left', marginTop: 10}}
                  >
                    Date Added
                  </label>
                  <div className='input-group input-group-lg'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Date Added'
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                </div> */}
              </div>
              <div className='card-footer'>
                {isLoading ? (
                  <button
                    type='submit'
                    value='Submit'
                    className='btn btn-primary mr-2'
                    style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                  >
                    <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                    Update Policy
                  </button>
                ) : (
                  <button
                    type='submit'
                    value='Submit'
                    className='btn btn-primary mr-2'
                    style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                  >
                    Update Policy
                  </button>
                )}

                <a
                  className='btn btn-secondary'
                  href='/policy-library/lists'
                  role='button'
                  style={{marginLeft: 20}}
                >
                  Cancel
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {EditPolicyModal}

import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useAuth} from '../../../../modules/auth'
import {addDoc, collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../../../services/firebase'
import {ToastContainer} from 'react-toastify'

interface ViewTaskProps {
  control_id?: string
  test_id?: string
  isOpen: boolean
  onClose: () => void
}

type Assigne = {
  id: string
  email: string
  group: string
  full_name: string
  uid: string
}

const AddTask: React.FC<ViewTaskProps> = ({isOpen, onClose, test_id, control_id}) => {
  const [owners, setOwners] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [owner, setOwner] = useState<Assigne | null>(null)
  const [taskName, setTaskName] = useState('')
  const [priority, setPriority] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [sendEmal, setSendEmail] = useState(false)
  const [controlTask, setControlTask] = useState('')
  const [testTask, setTestTask] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [Tests, setTests] = useState([] as any[])

  useEffect(() => {
    getTests()
  }, [test_id])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setOwners(risksData)
    })

    return unsubscribe
  }, [])

  const getTests = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'tests')
      if (!test_id) {
        return
      }
      const testDocRef = doc(testsCollectionRef, test_id)

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const testsData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            policies: doc.data().policies,
            policyId: doc.data().policyId,
            name: doc.data().name,
          }))
          .filter((test) => test.id === test_id)
        setTests(testsData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const handleCheckedEmail = () => {
    setSendEmail(!sendEmal)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (taskName === '' || priority === '' || owner?.full_name === '') {
      return
    }

    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()
    const todaysDate = `${month}/${day}/${year}`

    if (currentUser) {
      const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
      const activitiesRef = collection(tenantRef, 'tasklists')

      try {
        setIsLoading(true)

        await addDoc(activitiesRef, {
          task_name: taskName,
          priority: priority,
          owner: owner?.full_name,
          assignee_id: owner?.uid,
          duedate: dueDate || '',
          control: controlTask || '',
          test: testTask || '',
          tenantId: currentUser?.tenantId,
          username: currentUser?.userName,
          date: todaysDate,
          checked: false,
          test_id: test_id ?? '',
          deliverable: {
            email: true,
          },
          status: 'Incomplete',
          control_id: control_id ?? '',
        })

        // toast.success('Task assigned successfully', {
        //   position: toast.POSITION.TOP_CENTER,
        //   className: 'bg-white',
        //   style: {
        //     borderRadius: '8px',
        //     boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        //     border: '1px solid #e0e0e0',
        //     color: 'black',
        //   },
        //   progressStyle: {
        //     background: '#1CBB8D',
        //   },
        //   icon: ({theme, type}) => (
        //     <svg
        //       width='26'
        //       height='26'
        //       viewBox='0 0 24 24'
        //       fill='none'
        //       xmlns='http://www.w3.org/2000/svg'
        //       style={{color: '#1CBB8D'}}
        //     >
        //       <path
        //         d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
        //         fill='currentColor'
        //       />
        //     </svg>
        //   ),
        //   autoClose: 3000,
        // })
        setTaskName('')
        setPriority('')
        setDueDate('')
        setSendEmail(false)
        setOwner(null)
        setControlTask('')
        setTestTask('')
      } catch (error) {
        console.error('Error adding document: ', error)
      } finally {
        setIsLoading(false)
        onClose()
      }
    }
  }

  return (
    <Modal show={isOpen} onHide={onClose} backdropClassName='bg-opacity-1' centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Task</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0 mt-4'>
        <form className='px-6' onSubmit={handleSubmit}>
          <div className='fv-row'>
            <label className=' fw-bold fs-7 mb-1' style={{color: '#5A5A5A'}}>
              Test
            </label>
            <p className='fs-6 fw-semibold'>
              {Tests.length > 0 && Tests[0].name ? Tests[0].name : ''}
            </p>
          </div>
          <div className='separator my-4'></div>
          <div className='fv-row mb-4 '>
            <label className=' fw-semibold fs-6 mb-1'>Task Name</label>
            <input
              placeholder='Enter Task Name'
              type='text'
              className='form-control  mb-3 mb-lg-0 w-100'
              name='taskname'
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
              autoComplete='off'
            />
          </div>
          <div className='fv-row mb-4 '>
            <label className=' fw-semibold fs-6 mb-1'>Priority</label>
            <select
              className='form-select  mb-3 mb-lg-0 w-100'
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            >
              <option selected>Select Priority</option>
              <option value='Low'>Low</option>
              <option value='Medium'>Medium</option>
              <option value='High'>High</option>
            </select>
          </div>
          <div className='fv-row  '>
            <label className=' fw-semibold fs-6 mb-1'>Assign to</label>
            <select
              data-placeholder='Select Owner'
              onChange={(e) => {
                const selectedOwner = owners.find((owner) => owner.id === e.target.value) || null
                setOwner(selectedOwner)
              }}
              value={owner?.id || ''}
              className='form-select  mb-3 mb-lg-0 w-100'
            >
              <option selected>Select Owner</option>
              {owners
                .filter((user) => user.role === 'Admin' || user.role === 'Owner')
                .map((owner) => (
                  <option key={owner.id} value={owner.id}>
                    {owner.full_name}
                  </option>
                ))}
            </select>
          </div>
          <div className='form-group'>
            <label className='fw-semibold mt-5 fs-6 mb-1'>Due date</label>
            <input
              type='date'
              className='form-control '
              placeholder='Selcted due date'
              name='dateOfLastReview'
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
            />
          </div>
          <div className='fv-row p-0 my-0 '>
            <label className=' fw-semibold mt-5 fs-6 mb-1'>Delivery</label>
            <div style={{textAlign: 'center', display: 'flex', marginTop: '4px'}}>
              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input widget-9-check'
                  type='checkbox'
                  checked={sendEmal}
                  onChange={() => handleCheckedEmail()}
                />
              </div>
              <div className='text-dark fs-6' style={{marginLeft: '6px'}}>
                Email
              </div>
            </div>
          </div>
          <div className='modal-footer  p-0 my-4 pt-4'>
            <button
              className='btn btn-sm btn-light mx-2 fs-6'
              onClick={onClose}
              // disabled={activities.length === 0}
              style={{
                height: '40px',
                borderRadius: '4px',
                transition: 'background-color 0.3s',
              }}
            >
              Cancel
            </button>
            {isLoading ? (
              <button
                className='btn btn-sm fs-6'
                type='submit'
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
                // disabled={activities.length === 0}
                style={{
                  backgroundColor: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  borderRadius: '4px',
                  transition: 'background-color 0.3s',
                }}
              >
                Adding Task...
                <span className='spinner-border spinner-border-sm align-middle'></span>
              </button>
            ) : (
              <button
                className='btn btn-sm fs-6'
                type='submit'
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
                onClick={handleSubmit}
                style={{
                  backgroundColor: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  borderRadius: '4px',
                  transition: 'background-color 0.3s',
                }}
              >
                Add Task
              </button>
            )}
          </div>
        </form>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  )
}

export default AddTask

import React, {useState, useEffect} from 'react'
import {SettingPageTitle} from '../components/header/SettingPageTitle'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {
  updateDoc,
  doc,
  serverTimestamp,
  onSnapshot,
  DocumentReference,
  getDoc,
  setDoc,
} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {useAuth} from '../../../modules/auth'

const SettingAdvanced = () => {
  const {currentUser} = useAuth()
  const [twoFactorEnable, setTwoFactorEnable] = useState(false)

  useEffect(() => {
    getAI()
  }, [])

  const getAI = () => {
    try {
      const tenantId = currentUser?.tenantId || '';
      const tenantRef = doc(db, 'tenants', tenantId);
      const settingRef: DocumentReference = doc(tenantRef, 'settings', 'secureslate_ai');
  
      const unsubscribe = onSnapshot(settingRef, (snapshot) => {
        const data = snapshot.data();
        if (data) {
          setTwoFactorEnable(data.two_factor_enable);
        }
      });
  
      return unsubscribe;
    } catch (error) {
      console.error('Error getting personnels:', error);
    }
  };
  
  const handleTwoFactor = async () => {
    const updatedStatus = !twoFactorEnable;
  
    if (currentUser) {
      const tenantRef = doc(db, 'tenants', currentUser.tenantId!);
      const settingRef: DocumentReference = doc(tenantRef, 'settings', 'secureslate_ai');
  
      try {
        // Check if the document exists
        const docSnapshot = await getDoc(settingRef);
  
        if (docSnapshot.exists()) {
          // Update existing document
          await updateDoc(settingRef, {
            two_factor_enable: updatedStatus,
            updated_at: serverTimestamp(),
          });
        } else {
          // Add new document if it doesn't exist
          const newOnboardingWithChecked = {
            two_factor_enable: updatedStatus,
            tenantId: currentUser.tenantId,
            uid: currentUser.uid,
            isChecked: false,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          };
  
          await setDoc(settingRef, newOnboardingWithChecked);
        }
  
        setTwoFactorEnable(updatedStatus);
      } catch (error) {
        console.error('Error updating two-factor authentication status:', error);
      }
    }
  };
  

  return (
    <>
      <SettingPageTitle
       icon='Settings.svg'
       title='Settings'
       desc=' Manage your settings'
      >
        Personnel Management
      </SettingPageTitle>

      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5 mb-5'>
          <div className='form-group mx-1 pt-2'>
            <div className='row align-items-center'>
              <div className='col-auto'>
                <div className='symbol symbol-25px '>
                  <img src={toAbsoluteUrl(`/media/logos/logo-mini.png`)} alt='' />
                </div>
              </div>
              <div className='col'>
                <span className='card-label fw-bold fs-2'>SecureSlate AI</span>
              </div>
            </div>
            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid pt-4'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                name='twoFactorEnable'
                checked={twoFactorEnable}
                onChange={handleTwoFactor}
              />
              <label className='fw-bold m-2'>Enable SecureSlate AI</label>
            </div>
            <p className='text-muted fs-7'>
              Enable SecureSlate AI for real-time guidance on compliance and security tasks. Ask
              questions, receive suggestions, and write policies with ease.
            </p>
            <p className='mt-2'>
              <OverlayTrigger
                key='tooltip'
                placement='bottom'
                overlay={<Tooltip id={`tooltip-top`}>Click here for more info</Tooltip>}
              >
                <a href='#'>Learn more about SecureSlate AI.</a>
              </OverlayTrigger>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingAdvanced

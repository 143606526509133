/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import posthog from 'posthog-js';
import {useAuth} from '../core/Auth'
import {Helmet} from 'react-helmet'

import db, {auth1, googleProvider} from '../../../../services/firebase'
import {signInWithPopup} from 'firebase/auth'
import {addDoc, collection, doc, getDoc, serverTimestamp} from 'firebase/firestore'
import { toast, ToastContainer } from 'react-toastify'
// import { userInfo } from 'os'
// import jwt from "jwt-decode"
// import Cookies from 'universal-cookie'

interface UserModel {
  userName: string
  password: string
  first_name: string
  last_name: string
  // ... other properties
}

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}


async function fetchUserDataFromFirestore(uid) {
  const userDocRef = doc(collection(db, 'users'), uid)

  try {
    const userDocSnapshot = await getDoc(userDocRef)

    if (userDocSnapshot.exists()) {
      return userDocSnapshot.data()
    } else {
      console.error('User data not found in Firestore')
      return {}
    }
  } catch (error) {
    console.error('Error fetching user data from Firestore:', error)
    throw error
  }
}

async function fetchPersonalDataFromFirestore(tenantId: string, userId: string, email: string) {
  try {
    const personalDocSnapshot = db
      .collection('tenants')
      .doc(tenantId)
      .collection('employees')
      .where('uid', '==', userId)
      .where('email', '==', email)
      .get()
    if ((await personalDocSnapshot).docs.length > 0) {
      return (await personalDocSnapshot).docs[0].data()
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}
// const handleGoogleSignUp = async () => {
//       try {
//         const result = await signInWithPopup(auth1, googleProvider)
//         const user = result.user
//         console.log('User', user)
//         // You can customize the data you want to store in Firestore
//         const usersCollection = collection(db, 'users') // 'users' is the Firestore collection name
//         const emailQuery = query(usersCollection, where('email', '==', user.email))
//         const emailQuerySnapshot = await getDocs(emailQuery)
//         if (!emailQuerySnapshot.empty) {
//           console.log('Email is already in use.')
//           // You can handle this case by displaying a message to the user or taking other actions.
//           return
//         }

//         // const sessionToken = await getSessionToken(user.email);
//         // if (sessionToken) {
//         //   window.localStorage.setItem('sessionToken', sessionToken);
//         //   // setSessionCookie(sessionToken);
//         // }
//       }catch(error){
//         console.error('Error signing in with Google:', error)
//         }
//       //   const userData = {
//       //     email: user.email,
//       //     displayName: user.displayName,
//       //     // Add other user data as needed
//       //   }
//       //   await addDoc(usersCollection, userData)
//       //   navigate('/dashboard')
//       //   console.log('Successfully signed up with Google:', user)
//       // } catch (error) {
//       //   console.error('Google sign-up error:', error)
//       // }
//     }

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
type LocationState = {
  accountCreated?: boolean;
};

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const location = useLocation(); 
  const { accountCreated } = location.state as LocationState || {}; 

  useEffect(() => {
    if (accountCreated) {
      toast.success('Account created successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({ theme, type }) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ color: '#1CBB8D' }}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      });

    }
  }, [accountCreated]);


  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const userCredential = await login(values.email, values.password)
        const user = userCredential.user

        if (user.email) {
          posthog.identify(user.email, {
            email: user.email 
          });
        }
        const idTokenResult = await user.getIdTokenResult();
        // await user.getIdTokenResult()
        if (idTokenResult) {
          const additionalUserData = await fetchUserDataFromFirestore(user.uid)
          const userPersonaData = await fetchPersonalDataFromFirestore(
            additionalUserData.tenantId,
            user.uid,
            additionalUserData.email,
          )
          if (additionalUserData) {
            if (userPersonaData) {
              saveAuth({
                api_token: user.refreshToken,
                uid: user.uid,
                email: user.email,
                userName: user.displayName,
          
                password: values.password,
                first_name: '', // Update these fields as necessary
                last_name: '', // Update these fields as necessary
                tenantId: additionalUserData.tenantId || '',
                role: userPersonaData.role || '',
              })
              setCurrentUser({
                uid: user.uid,
                // userName: user.displayName || '',
                userName: additionalUserData.userName,
                api_token: user.refreshToken || '',
                password: values.password,
                email: user.email || '',
                first_name: '', // Update these fields as necessary
                last_name: '',
                tenantId: additionalUserData.tenantId || '',
                role: userPersonaData.role || '',
                // ... other properties
              })
              const tenantRef = doc(db, 'tenants', additionalUserData.tenantId)

              const currentDate = new Date() // No need to import the Firestore module
              const year = currentDate.getFullYear()
              const month = currentDate.getMonth() + 1 // Note: The month is zero-based
              const day = currentDate.getDate()

              const todaysDate = month + '/' + day + '/' + year

              const activitiesRef = collection(tenantRef, 'activities')
              await addDoc(activitiesRef, {
                message: `${user.displayName} has logged in the SecureSlate`,
                timestamp: serverTimestamp(),
                tenantId: additionalUserData.tenantId,
                uid: user.uid,
                action: 'User login',
                target: 'Authentication',
                email: user.email,
                username: user.displayName,
                date: todaysDate,
              })
            } else {
              saveAuth(undefined)
              setStatus('Sorry, you have not been assign any role yet')
              setSubmitting(false)
              setLoading(false)
            }
          } else {
            saveAuth(undefined)
            setStatus('Sorry, you havent completed the registration process')
            setSubmitting(false)
            setLoading(false)
          }
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth1, googleProvider)
      const user = result.user
      // Check if the user already exists in Firebase authentication

      // const email = user.email;
      if (user) {
        const additionalUserData = await fetchUserDataFromFirestore(user.uid)
        try {
          // Explicitly type auth1 as FirebaseAuth
          // const authForEmail: FirebaseAuth = auth1 as FirebaseAuth;
          // // Attempt to sign in the user using their Google-provided email
          // const existingUser = await fetchSignInMethodsForEmail(authForEmail, user.email);

          // // Perform the login process for the existing user
          // console.log('Successfully signed in with Google:', existingUser.user);
          setCurrentUser({
            uid: user.uid,
            userName: user.displayName || '',
            api_token: user.refreshToken || '',
            password: 'temporaryPassword', // Assuming you have a default temporary password
            email: user.email || '',
            first_name: '', // Update these fields as necessary
            last_name: '',
            pic: user.photoURL || '',
            tenantId: additionalUserData.tenantId || '',
            // ... other properties
          })
          navigate('/dashboard')
        } catch (signInError) {
          // If an error occurs, handle it based on your application's requirements
          console.error('Error signing in with Google:', signInError)

          // If the error is due to user not found, you can proceed with the sign-up process
          // if (signInError.code === 'auth/user-not-found') {
          //   console.log('User not found, proceed with sign-up process');

          //   // ... rest of the sign-up process
          // }
        }
      } else {
        // If the user doesn't exist, proceed with the sign-up process
        // ... rest of the sign-up process
      }
    } catch (error) {
      console.error('Error signing in with Google:', error)
    }
  }

  return (
    <>
      <Helmet>
        <title>Secureslate</title>
      </Helmet>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-5 '>Login</h1>
          <div className='text-gray-500 fw-semibold fs-6'>
            Safeguarding Your Data with Robust Security Solutions.
          </div>
        </div>

        {/* <div className='justify-content-center  row g-3 mb-9'>
        <div className='col-md-9'>
          {/* begin::Google link */}
        {/* <a
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-dark bg-state-light flex-center text-nowrap w-100'
            onClick={handleGoogleSignIn}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a> */}
        {/* end::Google link */}
        {/* </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6'>
          
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
        </div> */}
        {/* </div> */}
        {/* end::Login options */}

        {/* begin::Separator */}
        {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}
        {/* end::Separator */}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-primary-active'>
              Please enter your <strong>Email Address</strong> and <strong>Password</strong> to
              access your <strong>Secureslate</strong> account
            </div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Enter your email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <input
            type='password'
            autoComplete='off'
            placeholder='Enter your password'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

    
          <Link to='/auth/forgot-password' className='link-primary'>
            Forgot Password ?
          </Link>
         
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Login</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}

        <div className='text-gray-500 text-center fw-semibold fs-6'>
          New to our platform?{' '}
          <Link to='/auth/registration' className='link-primary mx-2'>
            Lets get started
          </Link>
          {/* <a href='https://getsecureslate.com/pricing' className='link-primary'>
          Lets get started
        </a> */}
          {/* <button
          type='button'
          className='btn '
          style={{ margin: 0, padding: 0,color: '#31bd93' }}
          onClick={() => window.location.href = 'https://getsecureslate.com/pricing'}
        >
          <div className=' text-center fw-semibold fs-6 '>
          Lets get started
          </div>
          </button> */}
        </div>
      </form>
      <ToastContainer/>
    </>
  )
}

import React, {useEffect, useRef, useState} from 'react'
import {SettingPageTitle} from '../components/header/SettingPageTitle'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import ProductDrawer from '../components/drawer/ProductDrawer'

import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import db, {storage} from '../../../../services/firebase'
import {
  DocumentReference,
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'
import {useAuth} from '../../../modules/auth'
import {toast, ToastContainer} from 'react-toastify'

const SettingsCompany = () => {
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [image, setImage] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(blankImg)
  const fileInputRef = useRef(null)
  const [displayName, setDisplayName] = useState('')
  const [legalName, setLegalName] = useState('')
  const [address, setAddress] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [productLength, setProductLength] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (currentUser?.tenantId) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId)
        const companyRef = doc(tenantRef, 'settings', 'company')
        const docSnap = await getDoc(companyRef)

        if (docSnap.exists()) {
          const companyData = docSnap.data()
          setDisplayName(companyData.displayName || '')
          setLegalName(companyData.legalName || '')
          setAddress(companyData.address || '')
          if (companyData.logoUrl) {
            setPreviewUrl(companyData.logoUrl)
          }
        }
      }
    }

    fetchCompanyData()
  }, [currentUser])

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (currentUser?.tenantId) {
        const tenantRef = doc(db, 'tenants', currentUser?.tenantId)
        const companyRef = doc(tenantRef, 'settings', 'product')
        const docSnap = await getDoc(companyRef)

        if (docSnap.exists() && docSnap.data().newAsset) {
          // Check if product data exists and get its length
          const productDataLength = Object.keys(docSnap.data().newAsset).length
          setProductLength(productDataLength)
        } else {
          setProductLength(0)
        }
      }
    }

    fetchCompanyData()
  }, [currentUser])

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    if (
      file &&
      (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg')
    ) {
      setImage(file)
      setPreviewUrl(URL.createObjectURL(file))
    } else {
      alert('Please select an image file (png, jpg, or jpeg)')
    }
  }

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      ;(fileInputRef.current as HTMLInputElement).click()
    }
  }

  const handleDelete = () => {
    setImage(null)
    setPreviewUrl(blankImg)
    if (fileInputRef.current) {
      ;(fileInputRef.current as HTMLInputElement).value = ''
    }
    // Here you might want to delete the image from the server if it was uploaded
    // deleteUserProfileImage();
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrorMessage('')

    // if (displayName.trim() === '' || legalName.trim() === '' || address.trim() === '') {
    //   setErrorMessage('All fields are required')
    //   return
    // }

    setIsLoading(true)

    try {
      let imageUrl = previewUrl

      if (image) {
        const storageRef = ref(
          storage,
          `tenants/${currentUser?.tenantId}/settings/company_logos/${(image as File).name}`
        )
        await uploadBytes(storageRef, image)
        imageUrl = await getDownloadURL(storageRef)
      }

      const newAsset = {
        displayName,
        legalName,
        address,
        logoUrl: imageUrl,
        updatedAt: new Date().toISOString(),
      }

      if (!currentUser?.tenantId) {
        throw new Error('No tenant ID found')
      }
      const tenantId = currentUser.tenantId

      const tenantRef = doc(db, 'tenants', tenantId)
      const companyRef: DocumentReference = doc(tenantRef, 'settings', 'company')

      await setDoc(companyRef, newAsset)

      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() + 1
      const day = currentDate.getDate()
      const todaysDate = `${month}/${day}/${year}`

      // Add activity log
      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: `Company Details has been updated`,
        timestamp: serverTimestamp(),
        tenantId: currentUser.tenantId,
        uid: currentUser.uid,
        username: currentUser.userName,
        date: todaysDate,
        email: currentUser.email,
        target: 'Settings',
        action: 'Company details updated',
      })
      toast.success('Company details updated successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    } catch (error) {
      setErrorMessage('An error occurred while updating company details. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <>
      <SettingPageTitle
        icon='Settings.svg'
        title='Settings'
        desc=' Manage your settings'
        // breadcrumbs={usersBreadcrumbs}
      >
        Personnel Management
      </SettingPageTitle>

      <form onSubmit={handleSubmit}>
        <div className='card'>
          <div className='card-header px-6 pt-4'>
            <h4 className='fw-bold m-4'>Company Details</h4>
          </div>
          <div className='card-body'>
            <div className='row align-items-center  mb-4'>
              <div className='col-md-2'>
                <div
                  className='image-input image-input-empty '
                  data-kt-image-input='true'
                  style={{backgroundImage: `url('${previewUrl}')`}}
                >
                  <div className='image-input-wrapper w-125px h-125px'></div>
                  <input
                    type='file'
                    name='avatar'
                    ref={fileInputRef}
                    hidden
                    accept='.png, .jpg, .jpeg'
                    onChange={handleImageChange}
                  />
                  <input type='hidden' name='avatar_remove' />
                  {/* </label> */}
                  <span
                    className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    title='Cancel avatar'
                  >
                    <i className='ki-outline ki-cross fs-3'></i>
                  </span>
                  <span
                    className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    title='Remove avatar'
                  >
                    <i className='ki-outline ki-cross fs-3'></i>
                  </span>
                </div>
              </div>
              <div className='col-md-9'>
                <div className='mb-2 '>
                  <div className='fw-bold fs-6 fw-bliock'>Company logo</div>
                  <div className='fs-6'>Allowed file types: png, jpg and jpeg.</div>
                </div>
                <div className='mt-3'>
                  {previewUrl === blankImg && (
                    <button
                      type='submit'
                      onClick={handleUploadClick}
                      className='fs-6 fw-semibold rounded border-0 mt-2'
                      style={{
                        background: '#1CBB8D',
                        color: '#fff',
                        height: '40px',
                        width: '150px',
                        top: '20px',
                        left: '223px',
                      }}
                    >
                      Upload New Picture
                    </button>
                  )}
                  {previewUrl !== blankImg && (
                    <button
                      type='submit'
                      className='fs-6 fw-semibold rounded mt-2 mx-4'
                      onClick={handleDelete}
                      style={{
                        background: '#fff',
                        color: '#000',
                        height: '40px',
                        width: '140px',
                        top: '20px',
                        left: '223px',
                        border: '2px solid #C5C5C5',
                      }}
                    >
                      Delete Picture
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className='row align-items-center mb-4 mt-4'>
              <div className='col-md-12'>
                <div className='fv-row mb-3'>
                  <label className='fw-bold fs-6 mb-1'>Display Name</label>
                  <input
                    placeholder='Enter display name'
                    type='text'
                    className='form-control '
                    name='displayName'
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    autoComplete='off'
                  />
                </div>
              </div>
            </div>

            <div className='row align-items-center mb-4 mt-4'>
              <div className='col-md-12'>
                <div className='fv-row mb-3'>
                  <label className='fw-bold fs-6 mb-1'>Legal Name</label>
                  <input
                    placeholder='Enter legal name'
                    type='text'
                    className='form-control '
                    name='legalName'
                    value={legalName}
                    onChange={(e) => setLegalName(e.target.value)}
                    autoComplete='off'
                  />
                  <div className='fs-7' style={{color: '#828282'}}>
                    Will be used solely for audit purposes
                  </div>
                </div>
              </div>
            </div>

            <div className='row align-items-center mt-4'>
              <div className='col-md-12'>
                <div className='fv-row mb-3'>
                  <label className='fw-bold fs-6 mb-1'>Address</label>
                  <input
                    placeholder='Enter address'
                    type='text'
                    className='form-control '
                    name='address'
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    autoComplete='off'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer py-0'>
            <div className='d-flex justify-content-end'>
              <button
                type='submit'
                className='fs-6 fw-semibold rounded border-0 my-6'
                disabled={isLoading}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '90px',
                  top: '20px',
                  left: '223px',
                }}
              >
                {isLoading ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className='card mt-8'>
        <div className='card-header px-5 pt-4'>
          <h4 className='fw-bold m-4'>Product</h4>
        </div>

        <div className='card-body p-0 mx-8'>
          <div className='fs-6  fw-semibold my-4 ' style={{color: '#5A5A5A'}}>
            Please make sure to add the product that will be in scope of your audit.
          </div>
          <button
            className='fs-6 fw-semibold rounded border-0 mb-8 btn btn-sm'
            onClick={handleOpenModal}
            style={{
              background: '#1CBB8D',
              color: '#fff',
              height: '40px',
              top: '20px',
              left: '223px',
            }}
          >
            {productLength > 0 ? 'View Product Details' : 'Add Product'}
          </button>
        </div>
      </div>
      <ProductDrawer isOpen={isModalOpen} onClose={handleCloseModal} />
      <ToastContainer />
    </>
  )
}

export default SettingsCompany

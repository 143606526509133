import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import policyServices from '../services/test.services'

type Props = {
  id: string
  setTestsID: any
}

const EditTestsModal: React.FC<Props> = ({id, setTestsID}) => {
  const [name, setName] = useState('')
  const [status, setStatus] = useState('')
  const [owner, setOwner] = useState('')
  const [author, setAuthor] = useState('')
  const [vendor, setVendor] = useState('')
  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()

    const newTests = {
      name,
      status,
      owner,
      author,
      vendor,
    }

    const modalContentElement = document.getElementById('kt_modal_1')
    if (status === '' || name === '' || author === '' || vendor === '') {
      setMessage({error: true, msg: 'Please fill all the fields'})
      setIsLoading(false)
      setShowAlert(true)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
      return
    }

    if (author.trim() !== '' && !/^[a-zA-Z\s]+$/.test(author)) {
      setMessage({error: true, msg: 'Please fill valid data in Author field'})
      setShowAlert(true)
      setIsLoading(false)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
      return
    }

    if (vendor.trim() !== '' && !/^[a-zA-Z\s]+$/.test(vendor)) {
      setMessage({error: true, msg: 'Please fill valid data in Vendor field'})
      setShowAlert(true)
      setIsLoading(false)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
      return
    }

    if (name.trim() !== '' && !/^[a-zA-Z\s]+$/.test(name)) {
      setMessage({error: true, msg: 'Please fill valid data in Tests name'})
      setShowAlert(true)
      setIsLoading(false)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
      return
    }

    if (owner.trim() !== '' && !/^[a-zA-Z\s]+$/.test(owner)) {
      setMessage({error: true, msg: 'Please fill valid data in Owner name'})
      setShowAlert(true)
      setIsLoading(false)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
      return
    }

    try {
      await policyServices.updateTest(id, newTests)
      setTestsID('')
      setMessage({error: false, msg: 'Update Successfully'})
      setShowAlert(true)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    } catch (err: any) {
      setMessage({error: true, msg: err.message})
      setShowAlert(true)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
    } finally {
      setIsLoading(false)
    }

    setStatus('')
    setVendor('')
    setName('')
    setAuthor('')
  }

  const handleStatusChange = (event) => {
    // Get the selected value from the event
    const selectedValue = event.target.value

    // Update the state variable with the selected value
    setStatus(selectedValue)
  }

  const editHandler = async () => {
    try {
      const docSnap = await policyServices.getTest(id)

      setAuthor(docSnap.data()?.author)
      setName(docSnap.data()?.name)
      setStatus(docSnap.data()?.status)
      setOwner(docSnap.data()?.owner)
      setVendor(docSnap.data()?.vendor)
    } catch (err: any) {
      setMessage({error: true, msg: err.message})
      setShowAlert(true)
    }
  }

  useEffect(() => {
    if (id !== '' && id !== undefined) {
      editHandler()
    }
  }, [id])

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_edit_tests'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Edit Tests</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            {showAlert && (
              <div style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>
                {message?.msg && (
                  <div
                    className={
                      message?.error
                        ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                        : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
                    }
                  >
                    {message?.error ? (
                      <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    ) : (
                      <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    )}

                    <div
                      className={
                        message?.error
                          ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                          : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                      }
                    >
                      {message?.error ? (
                        <h5 className='mb-1'>Error!!</h5>
                      ) : (
                        <h5 className='mb-1'>Sucess!!</h5>
                      )}
                      <span>{message?.msg}</span>
                    </div>
                    <button
                      type='button'
                      className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                      onClick={() => setShowAlert(false)}
                    >
                      <span className='svg-icon svg-icon-1 svg-icon-success'>
                        <span className='svg-icon svg-icon-2x svg-icon-primary'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='mh-50px'
                          >
                            <rect
                              opacity='0.5'
                              x='6'
                              y='17.3137'
                              width='16'
                              height='2'
                              rx='1'
                              transform='rotate(-45 6 17.3137)'
                              fill='black'
                            ></rect>
                            <rect
                              x='7.41422'
                              y='6'
                              width='16'
                              height='2'
                              rx='1'
                              transform='rotate(45 7.41422 6)'
                              fill='black'
                            ></rect>
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                )}
              </div>
            )}
            <form className='form' onSubmit={handleSubmit}>
              <div className='card-body'>
                <div className='form-group'>
                  <label className='text-dark fw-bold text-primary my-2 fs-6'>Tests Name</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={name}
                    placeholder='Enter test name'
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className='form-group'>
                  <label className='text-dark fw-bold text-primary my-2  fs-6'>Owner Name</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={owner}
                    placeholder='Enter owner name'
                    onChange={(e) => setOwner(e.target.value)}
                  />
                </div>
                <div className='form-group'>
                  <label className='text-dark fw-bold text-primary mt-6 my-2  fs-6'>Author</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={author}
                    placeholder='Enter author name'
                    onChange={(e) => setAuthor(e.target.value)}
                  />
                </div>
                <div className='form-group'>
                  <label className='text-dark fw-bold text-primary mt-6 my-2  fs-6'>Vendor</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={vendor}
                    placeholder='Enter vendor name'
                    onChange={(e) => setVendor(e.target.value)}
                  />
                </div>
                <div className='form-group'>
                  <label className='text-dark fw-bold text-primary mt-6 my-2  fs-6'>Status</label>
                  <select
                    className='form-control form-control-solid '
                    id='status'
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value=''>Select the option below..</option>
                    <option value='Yes'>Draft</option>
                    <option value='No'>Published</option>
                  </select>
                </div>
              </div>
              <div className='card-footer'>
                {isLoading ? (
                  <button
                    type='submit'
                    value='Submit'
                    className='btn btn-primary mr-2'
                    style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                  >
                    <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                    Update Tests
                  </button>
                ) : (
                  <button
                    type='submit'
                    value='Submit'
                    className='btn btn-primary mr-2'
                    style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                  >
                    Update Tests
                  </button>
                )}

                <a
                  className='btn btn-secondary'
                  href='/policy-library/lists'
                  role='button'
                  style={{marginLeft: 20}}
                >
                  Cancel
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {EditTestsModal}

import {useEffect, useState} from 'react'
import {toast, ToastContainer} from 'react-toastify'
import Drawer from 'react-modern-drawer'
import 'react-toastify/dist/ReactToastify.css'
import {useAuth} from '../../../modules/auth'
import {addDoc, collection, doc, onSnapshot, serverTimestamp, updateDoc} from 'firebase/firestore'
import db from '../../../../services/firebase'

type Props = {
  id: string
  isOpen: boolean
  onClose: () => void
}
interface DateFormat {
  format: string
  regex: RegExp
}

interface Asset {
  id: string
  owner: string
  model: string
  name: string
  os_version: string
  anti_virus: string
  hd_encryption: string
  password_policy: string
  screen_policy: string
  firewall: string
  audit_scope: string
  last_checkedin: string
  tenantId: string
  uid: string
  serial_number: string
}

const predefinedOSVersions = [
  // Windows
  'Windows 7',
  'Windows 8',
  'Windows 10',
  'Windows 11',
  'Windows Server 2022',

  // macOS
  'macOS Sierra',
  'macOS High Sierra',
  'macOS Mojave',
  'macOS Catalina',
  'macOS Big Sur',
  'macOS Ventura',

  // Ubuntu
  'Ubuntu 18.04',
  'Ubuntu 20.04',
  'Ubuntu 22.04',
  'Ubuntu 24.04',
  'Ubuntu 23.10',
  'Ubuntu 23.04',

  // Linux Distros
  'Fedora 37',
  'Fedora 38',
  'Fedora 39',
  'Debian 10',
  'Debian 11',
  'Debian 12',

  // Others
  'Chrome OS ',
  'Android 12',
  'Android 13',
  'Android 14',
  'iOS 15',
  'iOS 16',
]

const EditAssetModel: React.FC<Props> = ({id, isOpen, onClose}) => {
  const [owner, setOwner] = useState('')
  const [name, setName] = useState('')
  const [model, setModel] = useState('')
  const [os_version, setOsVersion] = useState('')
  const [last_checkedin, setLast_checkedin] = useState('')
  const [hd_encryption, setHdEncryption] = useState('No')
  const [anti_virus, setAntiVirus] = useState('No')
  const [password_policy, setPasswordPolicy] = useState('No')
  const [screen_policy, setScreenPolicy] = useState('No')
  const [firewall, setFirewall] = useState('Yes')
  const [audit_scope, setAuditScope] = useState('Yes')
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [owners, setOwners] = useState<any[]>([])
  const [serial_number, setSerial_number] = useState('')
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [filteredOSVersions, setFilteredOSVersions] = useState<any[]>([])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setOwners(risksData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    setFormErrors({})
  }, [onClose])

  const handleInputChange = (e) => {
    const input = e.target.value
    setOsVersion(input)

    if (input.length > 0) {
      const filtered = predefinedOSVersions.filter((version) =>
        version.toLowerCase().includes(input.toLowerCase())
      )
      setFilteredOSVersions(filtered)
      setShowSuggestions(true)
    } else {
      setShowSuggestions(false)
    }
  }

  const handleSuggestionClick = (suggestion) => {
    setOsVersion(suggestion)
    setShowSuggestions(false)
  }

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!serial_number.trim()) {
      errors.serialNumber = 'Asset serial number is required'
    }

    if (!owner || !owner.trim()) {
      errors.owner = 'Asset owner is required'
    }

    if (!name.trim()) {
      errors.name = 'Asset name is required'
    }
    if (!model.trim()) {
      errors.model = 'Asset model is required'
    }

    if (!os_version.trim()) {
      errors.os_version = 'OS version is required'
    }
    if (!last_checkedin.trim()) {
      errors.last_checkedin = 'Last checked-in date is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const DATE_FORMATS: DateFormat[] = [
    {
      format: 'YYYY-MM-DD',
      regex: /^\d{4}-\d{2}-\d{2}$/,
    },
    {
      format: 'MM/DD/YYYY',
      regex: /^\d{2}\/\d{2}\/\d{4}$/,
    },
  ]

  const formatDate = (dateValue: string | Date | null): string => {
    if (!dateValue) {
      return '--'
    }

    try {
      // If it's already in YYYY-MM-DD format, return as is
      if (typeof dateValue === 'string' && DATE_FORMATS[0].regex.test(dateValue)) {
        return dateValue
      }

      // Handle date object
      if (dateValue instanceof Date) {
        return dateValue.toISOString().split('T')[0]
      }

      // Convert string to date and format
      const date = new Date(dateValue)

      // Check if date is valid
      if (isNaN(date.getTime())) {
        return '--'
      }

      // Format to YYYY-MM-DD
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    } catch (error) {
      return '--'
    }
  }

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()

    const newAsset = {
      serial_number,
      owner,
      name,
      model,
      os_version,
      last_checkedin,
      hd_encryption,
      anti_virus,
      password_policy,
      screen_policy,
      firewall,
      audit_scope,
    }
    const modalContentElement = document.getElementById('kt_modal_2')

    if (!validateForm()) {
      setIsLoading(false)
      return
    }

    const currentDate = new Date() // No need to import the Firestore module
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 // Note: The month is zero-based
    const day = currentDate.getDate()

    const todaysDate = month + '/' + day + '/' + year

    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const assetRef = doc(tenantRef, 'assets', id)
      await updateDoc(assetRef, newAsset)

      toast.success('Asset updated successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })

      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: `${currentUser?.userName} updated asset '${name}'`,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
        action: 'Asset updated',
        target: 'Asset Management',
        email: currentUser?.email,
        username: currentUser?.userName,
        date: todaysDate,
      })

      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
      setTimeout(() => {
        onClose()
      }, 1000)
    } catch (err: any) {
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
    } finally {
      setIsLoading(false)
    }
  }

  const editHandler = async (id: string) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const assetRef = collection(tenantRef, 'assets')

      const unsubscribe = onSnapshot(assetRef, (snapshot) => {
        const assetData: Asset[] = snapshot.docs.map((item) => ({
          ...(item.data() as Asset),
          id: item.id,
        }))

        const matchingAsset = assetData.find((asset) => asset.id === id)

        if (matchingAsset) {
          setSerial_number(matchingAsset.serial_number)
          setOwner(matchingAsset.owner)
          setModel(matchingAsset.model)
          setName(matchingAsset.name)
          setOsVersion(matchingAsset.os_version)
          setLast_checkedin(matchingAsset.last_checkedin)
          setAntiVirus(matchingAsset.anti_virus)
          setHdEncryption(matchingAsset.hd_encryption)
          setPasswordPolicy(matchingAsset.password_policy)
          setScreenPolicy(matchingAsset.screen_policy)
          setFirewall(matchingAsset.firewall)
          setAuditScope(matchingAsset.audit_scope)
        }
      })
    } catch (err) {
      console.error('Error retrieving asset data:', err)
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    if (id !== '' && id !== undefined) {
      editHandler(id)
    }
  }, [id])

  const toggleState = (currentState, setStateFunction) => {
    setStateFunction(currentState === 'Yes' ? 'No' : 'Yes')
  }

  const formattedDate = last_checkedin ? formatDate(last_checkedin) : '--'

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Edit Asset
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={onClose}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>
        <div
          className=' scroll-y overflow-auto p-6'
          tabIndex={-1}
          style={{height: 'calc(100vh - 30px)'}}
        >
          <form className='form' onSubmit={handleSubmit}>
            <div className=''>
              <div className='form-group mb-4'>
                <label className='text-dark fw-bold text-primary my-2 fs-6 required'>
                  Serial Number
                </label>

                <input
                  type='text'
                  className={`form-control ${formErrors.serialNumber ? 'is-invalid' : ''}`}
                  placeholder='Serial number'
                  name='serialNumber'
                  value={serial_number}
                  onChange={(e) => {
                    setSerial_number(e.target.value)
                    setFormErrors((prev) => ({...prev, serialNumber: ''}))
                  }}
                />
                {formErrors.serialNumber && (
                  <span className='text-danger'>{formErrors.serialNumber}</span>
                )}
              </div>

              <div className='form-group '>
                <label className='text-dark fw-bold text-primary my-2 fs-6 required'>
                  Name of Asset
                </label>
                <input
                  type='text'
                  className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                  placeholder='Name of asset'
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                    setFormErrors((prev) => ({...prev, name: ''}))
                  }}
                />
                {formErrors.name && <span className='text-danger'>{formErrors.name}</span>}
              </div>

              <div className='form-group'>
                <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                  Model
                </label>
                <div className='input-group input-group-lg'>
                  <input
                    type='text'
                    className={`form-control ${formErrors.model ? 'is-invalid' : ''}`}
                    placeholder='Model'
                    value={model}
                    onChange={(e) => {
                      setModel(e.target.value)
                      setFormErrors((prev) => ({...prev, model: ''}))
                    }}
                  />
                </div>
                {formErrors.model && <span className='text-danger'>{formErrors.model}</span>}
              </div>

              <div className='form-group'>
                <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                  OS Version
                </label>
                <input
                  type='text'
                  className={`form-control ${formErrors.os_version ? 'is-invalid' : ''}`}
                  placeholder='OS Version'
                  value={os_version}
                  onChange={handleInputChange}
                  onBlur={() => setShowSuggestions(false)}
                  onFocus={() => os_version && setShowSuggestions(true)}
                />
                {showSuggestions && (
                  <ul
                    className='suggestions-list list-unstyled p-0 m-0 border rounded shadow'
                    style={{
                      position: 'absolute',
                      width: '100%',
                      maxHeight: '200px',
                      overflowY: 'auto',
                      backgroundColor: 'white',
                      zIndex: 1000,
                    }}
                  >
                    {filteredOSVersions.map((suggestion, index) => (
                      <li
                        key={index}
                        onMouseDown={() => handleSuggestionClick(suggestion)}
                        className='suggestion-item px-3 fs-6 py-2 text-hover-primary'
                        style={{
                          cursor: 'pointer',
                          transition: 'background-color 0.2s',
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f1f1f1')}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
                {formErrors.os_version && (
                  <span className='text-danger'>{formErrors.os_version}</span>
                )}
              </div>
              <div className='form-group'>
                <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                  Owner Name
                </label>
                <select
                  name='group'
                  className={`form-select ${formErrors.owner ? 'is-invalid' : ''}`}
                  data-control='select2'
                  data-placeholder='Select Owner'
                  onChange={(e) => {
                    setOwner(e.target.value)
                    setFormErrors((prev) => ({...prev, owner: ''}))
                  }}
                  value={owner}
                >
                  <option value='' selected>
                    Select Owner
                  </option>
                  {owners
                    .filter((user) => user.role === 'Admin' || user.role === 'Owner')
                    .map((owner) => (
                      <option key={owner.id} value={owner.full_name}>
                        {owner.full_name}
                      </option>
                    ))}
                </select>
                {formErrors.owner && <span className='text-danger'>{formErrors.owner}</span>}
              </div>
              <div className='form-group'>
                <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                  Last Checked-In
                </label>
                <div className='input-group input-group-lg'>
                  <input
                    type='date'
                    className={`form-control ${formErrors.last_checkedin ? 'is-invalid' : ''}`}
                    placeholder='Last checked in'
                    value={formattedDate}
                    onChange={(e) => {
                      setLast_checkedin(e.target.value)
                      setFormErrors((prev) => ({...prev, last_checkedin: ''}))
                    }}
                  />
                </div>
                {formErrors.last_checkedin && (
                  <span className='text-danger'>{formErrors.last_checkedin}</span>
                )}
              </div>

              <div className='form-group w-100 mt-6'>
                {/* Begin::Heading */}
                <div className='mb-6'>
                  {/* Begin::Title */}
                  <h2 className='mb-3 fs-6'>Device Details</h2>
                  {/* End::Title */}

                  {/* Begin::Description */}
                  <div className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    Select all options that apply
                  </div>
                  {/* End::Description */}
                </div>
                {/* End::Heading */}

                {/* Begin::Input group */}
                <div
                  className='fv-row mb-15 fv-plugins-icon-container'
                  data-kt-buttons='true'
                  data-kt-initialized='1'
                >
                  {/* Begin::Option */}
                  <label
                    className={`form-check form-check-custom btn btn-outline btn-outline-dashed d-flex text-start p-6 mb-6 ${
                      hd_encryption === 'Yes' ? 'active' : ''
                    }`}
                    style={{
                      backgroundColor: hd_encryption === 'Yes' ? '#E8F8F4' : 'transparent',
                      transition: 'background-color 0.3s',
                    }}
                    onMouseEnter={(e) =>
                      ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                    }
                    onMouseLeave={(e) =>
                      ((e.currentTarget as HTMLElement).style.backgroundColor =
                        hd_encryption === 'Yes' ? '#E8F8F4' : 'transparent')
                    }
                  >
                    {/* Begin::Checkbox */}
                    <input
                      type='checkbox'
                      checked={hd_encryption === 'Yes'}
                      onChange={() => toggleState(hd_encryption, setHdEncryption)}
                      className='form-check-input me-2 checkbox'
                    />
                    {/* End::Checkbox */}

                    {/* Begin::Label */}
                    <span className='d-flex align-items-center'>
                      {/* Begin::Info */}
                      <span className='ms-4'>
                        <span className='fs-6 fw-bold text-gray-900 mb-2 d-block'>
                          HD Encryption
                        </span>
                        <span className='fw-semibold fs-6 text-gray-700'>
                          The device's hard drive is fully encrypted
                        </span>
                      </span>
                      {/* End::Info */}
                    </span>
                    {/* End::Label */}
                  </label>

                  {/* End::Option */}

                  {/* Begin::Option */}
                  <label
                    className={`form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed d-flex text-start p-6 ${
                      anti_virus === 'Yes' ? 'active' : ''
                    }`}
                    style={{
                      backgroundColor: anti_virus === 'Yes' ? '#E8F8F4' : 'transparent',
                      transition: 'background-color 0.3s',
                    }}
                    onMouseEnter={(e) =>
                      ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                    }
                    onMouseLeave={(e) =>
                      ((e.currentTarget as HTMLElement).style.backgroundColor =
                        anti_virus === 'Yes' ? '#E8F8F4' : 'transparent')
                    }
                  >
                    {/* Begin::Checkbox */}
                    <input
                      type='checkbox'
                      checked={anti_virus === 'Yes'}
                      onChange={() => toggleState(anti_virus, setAntiVirus)}
                      className='form-check-input me-2'
                    />
                    {/* End::Checkbox */}

                    {/* Begin::Label */}
                    <span className='d-flex align-items-center'>
                      {/* Begin::Info */}
                      <span className='ms-4'>
                        <span className='fs-6 fw-bold text-gray-900 mb-2 d-block'>Antivirus</span>
                        <span className='fw-semibold fs-6 text-gray-700'>
                          The device has up to date antivirus software installed and running
                        </span>
                      </span>
                      {/* End::Info */}
                    </span>
                    {/* End::Label */}
                  </label>
                  {/* End::Option */}

                  {/* Begin::Option */}
                  <label
                    className={`form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed  d-flex text-start p-6 ${
                      password_policy === 'Yes' ? 'active' : ''
                    }`}
                    style={{
                      backgroundColor: password_policy === 'Yes' ? '#E8F8F4' : 'transparent',
                      transition: 'background-color 0.3s',
                    }}
                    onMouseEnter={(e) =>
                      ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                    }
                    onMouseLeave={(e) =>
                      ((e.currentTarget as HTMLElement).style.backgroundColor =
                        password_policy === 'Yes' ? '#E8F8F4' : 'transparent')
                    }
                  >
                    {/* Begin::Checkbox */}
                    <input
                      type='checkbox'
                      checked={password_policy === 'Yes'}
                      onChange={() => toggleState(password_policy, setPasswordPolicy)}
                      className='form-check-input me-2'
                    />
                    {/* End::Checkbox */}

                    {/* Begin::Label */}
                    <span className='d-flex align-items-center'>
                      {/* Begin::Info */}
                      <span className='ms-4'>
                        <span className='fs-6 fw-bold text-gray-900 mb-2 d-block'>
                          Password Policy
                        </span>
                        <span className='fw-semibold fs-6 text-gray-700'>
                          The device’s password meets company requirements (e.g. length, complexity)
                        </span>
                      </span>
                      {/* End::Info */}
                    </span>
                    {/* End::Label */}
                  </label>
                  {/* End::Option */}

                  {/* Begin::Option */}
                  <label
                    className={`form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed d-flex text-start p-6 ${
                      screen_policy === 'Yes' ? 'active' : ''
                    }`}
                    style={{
                      backgroundColor: screen_policy === 'Yes' ? '#E8F8F4' : 'transparent',
                      transition: 'background-color 0.3s',
                    }}
                    onMouseEnter={(e) =>
                      ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                    }
                    onMouseLeave={(e) =>
                      ((e.currentTarget as HTMLElement).style.backgroundColor =
                        screen_policy === 'Yes' ? '#E8F8F4' : 'transparent')
                    }
                  >
                    {/* Begin::Checkbox */}
                    <input
                      type='checkbox'
                      checked={screen_policy === 'Yes'}
                      onChange={() => toggleState(screen_policy, setScreenPolicy)}
                      className='form-check-input me-2'
                    />
                    {/* End::Checkbox */}

                    {/* Begin::Label */}
                    <span className='d-flex align-items-center'>
                      {/* Begin::Info */}
                      <span className='ms-4'>
                        <span className='fs-6 fw-bold text-gray-900 mb-2 d-block'>
                          Screen Policy
                        </span>
                        <span className='fw-semibold fs-6 text-gray-700'>
                          The device automatically locks after a short period of inactivity and
                          requires a password/biometric to unlock
                        </span>
                      </span>
                      {/* End::Info */}
                    </span>
                    {/* End::Label */}
                  </label>
                  {/* End::Option */}

                  {/* Begin::Option */}
                  <label
                    className={`form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed  d-flex text-start p-6 ${
                      firewall === 'Yes' ? 'active' : ''
                    }`}
                    style={{
                      backgroundColor: firewall === 'Yes' ? '#E8F8F4' : 'transparent',
                      transition: 'background-color 0.3s',
                    }}
                    onMouseEnter={(e) =>
                      ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                    }
                    onMouseLeave={(e) =>
                      ((e.currentTarget as HTMLElement).style.backgroundColor =
                        firewall === 'Yes' ? '#E8F8F4' : 'transparent')
                    }
                  >
                    {/* Begin::Checkbox */}
                    <input
                      type='checkbox'
                      checked={firewall === 'Yes'}
                      onChange={() => toggleState(firewall, setFirewall)}
                      className='form-check-input me-2'
                    />
                    {/* End::Checkbox */}

                    {/* Begin::Label */}
                    <span className='d-flex align-items-center'>
                      {/* Begin::Info */}
                      <span className='ms-4'>
                        <span className='fs-6 fw-bold text-gray-900 mb-2 d-block'>Firewall</span>
                        <span className='fw-semibold fs-6 text-gray-700'>
                          The device has firewall enabled to protect against unauthorized network
                          access
                        </span>
                      </span>
                      {/* End::Info */}
                    </span>
                    {/* End::Label */}
                  </label>
                  {/* End::Option */}

                  {/* Begin::Option */}
                  <label
                    className={`form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed  d-flex text-start p-6 ${
                      audit_scope === 'Yes' ? 'active' : ''
                    }`}
                    style={{
                      backgroundColor: audit_scope === 'Yes' ? '#E8F8F4' : 'transparent',
                      transition: 'background-color 0.3s',
                    }}
                    onMouseEnter={(e) =>
                      ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                    }
                    onMouseLeave={(e) =>
                      ((e.currentTarget as HTMLElement).style.backgroundColor =
                        audit_scope === 'Yes' ? '#E8F8F4' : 'transparent')
                    }
                  >
                    {/* Begin::Checkbox */}
                    <input
                      type='checkbox'
                      checked={audit_scope === 'Yes'}
                      onChange={() => toggleState(audit_scope, setAuditScope)}
                      className='form-check-input me-2'
                    />
                    {/* End::Checkbox */}

                    {/* Begin::Label */}
                    <span className='d-flex align-items-center'>
                      {/* Begin::Info */}
                      <span className='ms-4'>
                        <span className='fs-6 fw-bold text-gray-900 mb-2 d-block'>Audit Scope</span>
                        <span className='fw-semibold fs-6 text-gray-700'>
                          The device is within your audit scope.
                        </span>
                      </span>
                      {/* End::Info */}
                    </span>
                    {/* End::Label */}
                  </label>
                  {/* End::Option */}

                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                </div>
                {/* End::Input group */}
              </div>
            </div>

            <div
              className='offcanvas-footer d-flex justify-content-end  border-top py-4'
              style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
            >
              <button
                type='button'
                className='btn btn-sm btn-light fw-bold me-4 px-6 fs-6'
                style={{height: '40px'}}
                onClick={onClose}
              >
                Close
              </button>
              {isLoading ? (
                <button
                  type='submit'
                  className='btn mb-4 btn-sm fs-6'
                  style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  Updating asset...{' '}
                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                </button>
              ) : (
                <button
                  type='submit'
                  className='btn mb-4 btn-sm fs-6'
                  style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  Update asset
                </button>
              )}
            </div>
          </form>
        </div>
      </Drawer>

      <ToastContainer />
    </>
  )
}

export {EditAssetModel}

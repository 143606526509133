import {useEffect, useRef, useState} from 'react'
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import {addDoc, collection, doc, onSnapshot, serverTimestamp} from 'firebase/firestore'
import db from '../../../../services/firebase'
import Drawer from 'react-modern-drawer'
import {toast, ToastContainer} from 'react-toastify'

export interface addGroup {
  groupName: string
  checklist: string
}
const initialState = {
  groupName: '',
  checklist: '',
}

const AddPersonnelGroupModal: React.FC = () => {
  const [form, setForm] = useState(initialState)
  const {groupName, checklist} = form
  const [progress, setProgress] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [checklists, setCheckists] = useState<any>([])
  const [isOpen, setIsOpen] = useState(false)
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const focusRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (focusRef.current) {
          focusRef.current.focus()
        }
      }, 300)
    }
  }, [isOpen])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'personnel_checklist')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setCheckists(risksData)
    })

    return unsubscribe

    // fetchGroups()
  }, [])

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!groupName.trim()) {
      errors.groupName = 'Group name is required'
    }

    if (!checklist.trim()) {
      errors.checklist = 'Checklist is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!validateForm()) {
      setIsLoading(false)
      return
    }

    try {
      setIsLoading(true)

      const newGroup = {
        groupName: groupName,
        checklist: checklist,
        createdOn: new Date().toLocaleDateString(),
        uid: currentUser?.uid,
        tenantId: currentUser?.tenantId,
      }
      // await personnelGroupServices.addGroup(newGroup);
      if (currentUser) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
        const policyRef = collection(tenantRef, 'personnel_group')

        await addDoc(policyRef, {
          ...newGroup,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        })

        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `${currentUser?.userName} added a new personnel group '${groupName}'`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          username: currentUser?.userName,
          // PersonnelManagement: fullName,
          email: currentUser?.email,
          action: 'Group Added',
          target: 'Employees',
          date: todaysDate,
        })
      }

      toast.success('New group added', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })

      window.scrollTo(0, 0)
    } catch (err: any) {
      window.scrollTo(0, 0)
    } finally {
      setIsLoading(false)
    }

    setTimeout(() => {
      setForm(initialState)

      closeDrawer()
    }, 2000)
  }

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setForm({...form, [e.target.name]: e.target.value})
  // }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    setForm({...form, [name]: value})

    setFormErrors((prev) => ({...prev, [name]: ''}))

    if (name === 'groupName' && !value.trim()) {
      setFormErrors((prev) => ({...prev, groupName: 'Group Name is required'}))
    } else if (name === 'checklist' && !value.trim()) {
      setFormErrors((prev) => ({...prev, checklist: 'Checklist is required'}))
    }
  }

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  const closeDrawer = () => {
    setIsOpen(false)
    setFormErrors({})
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-sm fs-6'
        onClick={toggleDrawer}
        data-bs-target='#kt_add_personnel'
        style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
          (e.currentTarget.style.backgroundColor = '#148363')
        }
        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
          (e.currentTarget.style.backgroundColor = '#1CBB8D')
        }
      >
        <KTIcon iconName='plus' className='fs-2 text-white' />
        Add Group
      </button>

      <Drawer open={isOpen} onClose={closeDrawer} direction='right' className='' size='45%'>
      <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Add Group
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={closeDrawer}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>
        <div
          className=' scroll-y overflow-auto p-5 mb-4'
          tabIndex={-1}
          style={{height: 'calc(100vh - 30px)'}}
        >
          {/* <form id='kt_risk_form' className='form' onSubmit={handleSubmit}> */}
          <div className='modal-content'>
            <div className='modal-body'>
              {/* <div className='col-xl-4'> */}
              <form
                id='kt_modal_add_user_form'
                className='form'
                // onSubmit={formik.handleSubmit}
                noValidate
                onSubmit={handleSubmit}
                style={{width: '100%'}}
              >
                {/* begin::Scroll */}
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-5'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  {/* begin::Input group */}
                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className=' fw-bold fs-6 mb-2'>Group Name</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <input
                      placeholder='Enter Group Name'
                      type='text'
                      className={`form-control ${formErrors.groupName ? 'is-invalid' : ''}`}
                      name='groupName'
                      value={groupName}
                      onChange={handleChange}
                      autoComplete='off'
                      ref={focusRef}
                    />
                    {formErrors.groupName && (
                      <div className='invalid-feedback'>{formErrors.groupName}</div>
                    )}
                    {/* end::Input */}
                  </div>
                  {/* end::Input group */}

                  <div className='fv-row mb-3'>
                    {/* begin::Label */}
                    <label className=' fw-bold fs-6 mb-2'>Checklist</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <select
                      name='checklist'
                      data-control='select2'
                      data-placeholder='Select Checklist'
                      className={`form-select ${formErrors.checklist ? 'is-invalid' : ''}`}
                      onChange={handleChange}
                      value={checklist}
                    >
                      <option value=''>Select a checklist</option>
                      {checklists.map((checklist) => (
                        <option key={checklist.id} value={checklist.checklist_name}>
                          {checklist.checklist_name}
                        </option>
                      ))}
                    </select>
                    {formErrors.checklist && (
                      <div className='invalid-feedback'>{formErrors.checklist}</div>
                    )}
                  </div>
                </div>
              </form>
            </div>

            <div
              className='offcanvas-footer d-flex justify-content-end  border-top py-4'
              style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
            >
              {/* <button type='button' className='btn btn-light mx-2' onClick={closeDrawer}>
                   Close
                 </button> */}
              {isLoading ? (
                <button
                  className='btn btn-sm fw-semibold px-6 fs-6'
                  style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  disabled={progress !== null && progress < 100}
                >
                  Adding...
                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                </button>
              ) : (
                <>
                  <button
                    // className='m-1 btn btn-sm btn-light '
                    className='btn btn-sm btn-light fw-semibold me-4 px-6 fs-6'
                    // style={{cursor: 'pointer'}}
                    onClick={closeDrawer}
                    aria-label='Close'
                    style={{height: '40px'}}
                  >
                    Cancel
                  </button>
                  <button
                    className='btn btn-sm fw-semibold px-6 fs-6'
                    onClick={handleSubmit}
                    style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                  >
                    <KTIcon iconName='plus' className='fs-2 text-white' />
                    Add
                  </button>
                </>
              )}
            </div>
          </div>
          {/* </form> */}
        </div>
      </Drawer>
      <ToastContainer />
    </>
  )
}

export {AddPersonnelGroupModal}

import {useEffect, useRef, useState} from 'react'
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import {addDoc, collection, doc, onSnapshot, serverTimestamp} from 'firebase/firestore'
import Drawer from 'react-modern-drawer'
import db from '../../../../services/firebase'
import {toast} from 'react-toastify'

export interface addPersonnel {
  fullName: string
  email: string
  role: string
  startDate: string
  group: string
}
const initialState = {
  full_name: '',
  email: '',
  role: '',
  start_date: '',
  group: '',
}

const AddPersonnelModal: React.FC = () => {
  const [form, setForm] = useState(initialState)
  const {full_name, email, role, start_date, group} = form
  const [groups, setGroups] = useState<any[]>([])
  const Handlebars = require('handlebars')
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const drawerRef = useRef<HTMLDivElement>(null)
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const focusRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (focusRef.current) {
          focusRef.current.focus()
        }
      }, 300)
    }
  }, [isOpen])

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  const closeDrawer = () => {
    setIsOpen(false)
    setFormErrors({})
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        closeDrawer()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'personnel_group')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setGroups(risksData)
    })

    return unsubscribe
  }, [])
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  const templateSource = `
 Welcome to SecureSlate.
 You have been added as a {{role}} in the {{group}} group by {{Addedby}}. Your start date is {{startDate}}. 

 
 `

  //  const encodedLink = encodeURIComponent('https://app.getsecureslate.com/auth');

  // Prepare data for the template
  const data = {
    role: role,
    group: group,
    startDate: start_date,
    Addedby: currentUser ? currentUser.userName : '',
  }
  const template = Handlebars.compile(templateSource)
  // Generate the message text by applying the data to the template
  const messageText = template(data)
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!full_name.trim()) {
      errors.full_name = 'Full name is required'
    }

    if (!email.trim()) {
      errors.email = 'Email is required'
    }
    if (!start_date.trim()) {
      errors.start_date = 'Start Date is required'
    }

    if (!role.trim()) {
      errors.role = 'Role is required'
    }
    if (!group.trim()) {
      errors.group = 'Group is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!validateForm()) {
      setIsLoading(false)
      return
    }

    try {
      setIsLoading(true)
      // Upload file to Firebase Storage

      // const fileName = new Date().getTime() + file.name
      // const storageRef = ref(storage, `personnelImages/${fileName}`)
      // const uploadTask = uploadBytesResumable(storageRef, file)
      // await uploadTask

      // // Get download URL
      // const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)

      const newPersonnel = {
        full_name,
        email,
        role,
        start_date,
        group,
        uid: '',
        tenantId: currentUser?.tenantId,
      }

      // await personnelServices.addPersonnel(newPersonnel)

      // Import the necessary dependencies

      if (currentUser) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
        const policyRef = collection(tenantRef, 'employees')

        await addDoc(policyRef, {
          ...newPersonnel,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        })

        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `${currentUser?.userName} invited a new employee'${full_name}' in ${group} group`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          // email: email,
          uid: currentUser?.uid,
          username: currentUser?.userName,
          action: 'Employee added',
          target: 'Employees',
          email: currentUser?.email,
          PersonnelManagement: full_name,
          date: todaysDate,
        })
      }

      toast.success('Employee invited successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })

      window.scrollTo(0, 0)
    } catch (err: any) {
      window.scrollTo(0, 0)
    } finally {
      setIsLoading(false)
    }
    setTimeout(() => {
      closeDrawer()
      setForm(initialState)
    }, 3000)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    setForm({...form, [name]: value})

    // Clear the error for this field
    setFormErrors((prev) => ({...prev, [name]: ''}))

    if (name === 'email') {
      if (!value.trim()) {
        setFormErrors((prev) => ({...prev, email: 'Email is required'}))
      } else if (!validateEmail(value)) {
        setFormErrors((prev) => ({...prev, email: 'Please enter a valid email address'}))
      }
    } else if (name === 'fullName') {
      if (!value.trim()) {
        setFormErrors((prev) => ({...prev, fullName: 'Full name is required'}))
      }
    } else if (name === 'startDate') {
      if (!value.trim()) {
        setFormErrors((prev) => ({...prev, startDate: 'Start Date is required'}))
      }
    } else if (name === 'role') {
      if (!value.trim()) {
        setFormErrors((prev) => ({...prev, role: 'Role is required'}))
      }
    } else if (name === 'group') {
      if (!value.trim()) {
        setFormErrors((prev) => ({...prev, group: 'Group is required'}))
      }
    }
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-sm fs-6'
        onClick={toggleDrawer}
        style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
          (e.currentTarget.style.backgroundColor = '#148363')
        }
        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
          (e.currentTarget.style.backgroundColor = '#1CBB8D')
        }
      >
        <KTIcon iconName='plus' className='fs-2 text-white' />
        Add Employee
      </button>

      {/* <div className='modal fade' tabIndex={-1} id='kt_add_personnel'> */}
      <Drawer open={isOpen} onClose={closeDrawer} direction='right' className='' size='45%'>
        <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Add Employee
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={closeDrawer}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>
        <div
          className='p-6 scroll-y overflow-auto  '
          tabIndex={-1}
          style={{height: 'calc(100vh - 50px)'}}
        >
          <form id='kt_risk_form' className='form' onSubmit={handleSubmit}>
            <div className='modal-content'>
              <div className='modal-body'>
                <div className='mb-20'>
                  <div className='fv-row mb-7 text-grey-800 fw-semibold fs-6'>
                    Enter the name and email address of the employee you'd like to add to your
                    SecureSlate account.
                  </div>

                  {/* begin::Input group */}
                  <div className='fv-row mb-4'>
                    {/* begin::Label */}
                    <label className=' fw-bold fs-6 mb-2'>Full Name</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <input
                      placeholder='Full name'
                      type='text'
                      className={`form-control ${formErrors.full_name ? 'is-invalid' : ''}`}
                      name='full_name'
                      value={full_name}
                      onChange={handleChange}
                      autoComplete='off'
                      ref={focusRef}
                    />
                    {formErrors.full_name && (
                      <div className='invalid-feedback'>{formErrors.full_name}</div>
                    )}
                    {/* end::Input */}
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}
                  <div className='fv-row mb-4'>
                    {/* begin::Label */}
                    <label className=' fw-bold fs-6 mb-2'>Email</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <input
                      placeholder='Email'
                      className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                      type='email'
                      name='email'
                      autoComplete='off'
                      value={email}
                      onChange={handleChange}
                    />

                    {/* end::Input */}

                    {formErrors.email && (
                      <div className='invalid-feedback d-block'>{formErrors.email}</div>
                    )}
                  </div>
                  {/* end::Input group */}
                  <div className='fv-row mb-4'>
                    {/* begin::Label */}
                    <label className='fw-bold fs-6 mb-2'>Start Date</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <input
                      placeholder='Start Date'
                      //   {...formik.getFieldProps('email')}
                      className={`form-control ${formErrors.start_date ? 'is-invalid' : ''}`}
                      type='date'
                      name='start_date'
                      autoComplete='off'
                      value={start_date}
                      onChange={handleChange}
                    />

                    {/* end::Input */}
                    {formErrors.start_date && (
                      <div className='invalid-feedback d-block'>{formErrors.start_date}</div>
                    )}
                  </div>
                  <div className='fv-row mb-4'>
                    {/* begin::Label */}
                    <label className='fw-bold fs-6 mb-2'>Role</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <select
                      name='role'
                      value={role}
                      className={`form-select ${formErrors.role ? 'is-invalid' : ''}`}
                      data-control='select2'
                      data-placeholder='Select Role'
                      onChange={handleChange}
                    >
                      <option>Select Role</option>
                      <option value='Admin'>Admin</option>
                      <option value='Member'>Member</option>
                    </select>
                    {/* end::Input */}
                    {formErrors.role && (
                      <div className='invalid-feedback d-block'>{formErrors.role}</div>
                    )}
                  </div>
                  <div className='fv-row mb-20'>
                    {/* begin::Label */}
                    <label className='fw-bold fs-6 mb-2'>Group</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <select
                      name='group'
                      className={`form-select ${formErrors.group ? 'is-invalid ' : ''}`}
                      data-placeholder='Select Group'
                      onChange={handleChange}
                      value={group}
                    >
                      <option>Select Group</option>
                      {groups
                        .filter((user) => user.tenantId === currentUser?.tenantId)
                        .map((group) => (
                          <option key={group.id} value={group.groupName}>
                            {group.groupName}
                          </option>
                        ))}
                    </select>
                    {formErrors.group && (
                      <div className='invalid-feedback d-block'>{formErrors.group}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div
          className='offcanvas-footer d-flex justify-content-end  border-top py-4 px-6'
          style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
        >
          <div className='d-flex justify-content-end align-items-center'>
            {isLoading ? (
              <button
                className='btn btn-sm fw-semibold px-6 fs-6'
                type='submit'
                style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
              >
                {`Inviting... `}
                <span className='spinner-border spinner-border-sm align-middle'></span>
              </button>
            ) : (
              <>
                <button
                  className='btn btn-sm btn-light fw-semibold me-4 fs-6'
                  onClick={closeDrawer}
                  aria-label='Close'
                  style={{height: '40px'}}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-sm fw-semibold fs-6'
                  onClick={handleSubmit}
                  style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  Invite
                </button>
              </>
            )}
          </div>
        </div>
      </Drawer>
    </>
  )
}

export {AddPersonnelModal}

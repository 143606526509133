import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {addDoc, collection, doc, onSnapshot, serverTimestamp, updateDoc} from 'firebase/firestore'
import db from '../../../../services/firebase'
import Drawer from 'react-modern-drawer'
import {ToastContainer, toast} from 'react-toastify'

interface AddProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

const EditTask: React.FC<AddProps> = ({isOpen, onClose, id}) => {
  const {currentUser} = useAuth()
  const [dueDate, setDueDate] = useState('')
  const [taskName, setTaskName] = useState('')
  const [priority, setPriority] = useState('')
  const [controlTask, setControlTask] = useState('')
  const [testTask, setTestTask] = useState('')
  const [owners, setOwners] = useState<any[]>([])
  const [owner, setOwner] = useState('')
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setOwners(risksData)
    })

    return unsubscribe
  }, [])
  const getTask = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'tasklists')
      const riskDocRef = doc(PolicyRef, id)

      const unsubscribe = onSnapshot(riskDocRef, (doc) => {
        if (doc.exists()) {
          const taskData = doc.data()

          setTaskName(taskData.task_name)
          setControlTask(taskData.control)
          setTestTask(taskData.test)
          setPriority(taskData.priority)
          setOwner(taskData.owner)
          setDueDate(taskData.duedate)
        }
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting risk:', error)
    }
  }

  useEffect(() => {
    if (id !== '' && id !== undefined) {
      getTask()
    }
  }, [id])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)

    if (!validateForm()) {
      setLoading(false)
      return
    }

    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()

    const todaysDate = `${month}/${day}/${year}`

    try {
      if (currentUser) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
        const tasksRef = collection(tenantRef, 'tasklists')
        const taskDocRef = doc(tasksRef, id)

        await updateDoc(taskDocRef, {
          task_name: taskName.trim(),
          priority,
          owner,
          duedate: dueDate,
          control: controlTask,
          test: testTask,
          date: todaysDate,
        })

        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `${currentUser?.userName} updated task "${taskName.trim()}"`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          action: 'Asset updated',
          target: 'Asset Management',
          email: currentUser?.email,
          username: currentUser?.userName,
          date: todaysDate,
        })

        toast.success('Task edited successfully!', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })

        // Close the drawer after a short delay
        setTimeout(() => {
          onClose()
        }, 1000)
      }
    } catch (error) {
      console.error('Error updating task:', error)
    }

    setLoading(false)
  }

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!taskName.trim()) {
      errors.taskName = 'Task Name is required'
    }

    if (!priority.trim()) {
      errors.priority = 'Task priority is required'
    }

    // if (!owner.trim()) {
    //   errors.owner = 'Task Owner is required'
    // }

    if (!dueDate.trim()) {
      errors.dueDate = 'Task DueDate is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Edit Task
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={onClose}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>
        <div
          className='p-6 scroll-y overflow-auto '
          tabIndex={-1}
          style={{height: 'calc(100vh - 20px)'}}
        >
          <div className='modal-body'>
            <form className='' onSubmit={handleSubmit}>
              <div className='fs-6 mb-4'>
                Delegate tasks which might help you fix your controls and tests to you team members.
                You can also add due dates to ensure audit readiness.{' '}
              </div>
              <div className='fv-row mb-4 '>
                <label className=' fw-semibold fs-6 mb-1 required'>Task Name</label>
                <input
                  placeholder='Enter Task Name'
                  type='text'
                  className={`form-control ${formErrors.taskName ? 'is-invalid' : ''}`}
                  name='taskname'
                  value={taskName}
                  onChange={(e) => {
                    setTaskName(e.target.value)
                    setFormErrors((prev) => ({...prev, taskName: ''}))
                  }}
                  autoComplete='off'
                />
                {formErrors.taskName && (
                  <div className='invalid-feedback'>{formErrors.taskName}</div>
                )}
              </div>

              {/* <div className='fv-row mb-4 '>
                <label className=' fw-semibold fs-6 mb-1'>Map to control</label>
                <select
                  className='form-select  mb-3 mb-lg-0 w-100'
                  value={controlTask}
                  onChange={(e) => setControlTask(e.target.value)}
                >
                  <option selected>Select control</option>
                  <option value='Determining the scope of the information security management system'>
                    Determining the scope of the information security management system
                  </option>
                  <option value='Inventory of information and other associated assets'>
                    Inventory of information and other associated assets
                  </option>
                  <option value='Intellectual property rights'>Intellectual property rights</option>
                  <option value='Security of assets off-premises'>
                    Security of assets off-premises
                  </option>
                  <option value='Storage media'>Storage media</option>
                  <option value='Secure disposal or re-use of equipment'>
                    Secure disposal or re-use of equipment
                  </option>
                </select>
              </div>

              <div className='fv-row mb-4 '>
                <label className=' fw-semibold fs-6 mb-1'>Map to test</label>
                <select
                  className='form-select  mb-3 mb-lg-0 w-100'
                  value={testTask}
                  onChange={(e) => setTestTask(e.target.value)}
                >
                  <option selected>Select Test</option>
                  <option value='A process exists to identify and prioritize security vulnerabilities'>
                    A process exists to identify and prioritize security vulnerabilities
                  </option>
                  <option value='Access request ticket and history'>
                    Access request ticket and history
                  </option>
                  <option value='Company has an approved Asset Management Policy'>
                    Company has an approved Asset Management Policy
                  </option>
                  <option value='Assets returned by employees'>Assets returned by employees</option>
                  <option value='Backups completed for other databases'>
                    Backups completed for other databases
                  </option>
                  <option value='Cloud provider service agreement'>
                    Cloud provider service agreement
                  </option>
                </select>
              </div> */}

              <div className='fv-row mb-4 '>
                <label className=' fw-semibold fs-6 mb-1 required'>Priority</label>
                <select
                  className={`form-select  ${formErrors.priority ? 'is-invalid' : ''}`}
                  value={priority}
                  onChange={(e) => {
                    setPriority(e.target.value)
                    setFormErrors((prev) => ({...prev, priority: ''}))
                  }}
                >
                  <option selected>Select Priority</option>
                  <option value='Low'>Low</option>
                  <option value='Medium'>Medium</option>
                  <option value='High'>High</option>
                </select>
                {formErrors.priority && <span className='text-danger'>{formErrors.priority}</span>}
              </div>
              <div className='fv-row  '>
                <label className=' fw-semibold fs-6 mb-1'>Assign to</label>
                <select
                  data-placeholder='Select Owner'
                  onChange={(e) => {
                    setOwner(e.target.value)
                    setFormErrors((prev) => ({...prev, owner: ''}))
                  }}
                  value={owner}
                  className={`form-select  ${formErrors.owner ? 'is-invalid' : ''}`}
                >
                  <option value='' selected>
                    Select Owner
                  </option>
                  {owners
                    .filter((user) => user.role === 'Admin' || user.role === 'Owner')
                    .map((owner) => (
                      <option key={owner.id} value={owner.full_name}>
                        {owner.full_name}
                      </option>
                    ))}
                </select>
                {formErrors.owner && <span className='text-danger'>{formErrors.owner}</span>}
              </div>

              <div className='form-group'>
                <label className='fw-semibold text-dark mt-5 fs-6 mb-1 required'>Due date</label>
                <input
                  type='date'
                  className={`form-control   ${formErrors.dueDate ? 'is-invalid mb-20' : 'mb-5'}`}
                  placeholder='Selcted due date'
                  name='dateOfLastReview'
                  value={dueDate}
                  onChange={(e) => {
                    setDueDate(e.target.value)
                    setFormErrors((prev) => ({...prev, dueDate: ''}))
                  }}
                />
                {formErrors.dueDate && <span className='text-danger'>{formErrors.dueDate}</span>}
              </div>
            </form>
          </div>
        </div>
        <div
          className='offcanvas-footer d-flex justify-content-end  border-top mt-8 py-4'
          style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
        >
          <div className='d-flex justify-content-end align-items-center'>
            <button
              type='button'
              className='btn btn-sm btn-light fw-bold fs-6'
              style={{height: '40px'}}
              onClick={onClose}
            >
              Cancel
            </button>
            {loading ? (
              <button
                type='submit'
                className='btn  btn-sm fs-6 mx-6'
                style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
                disabled
              >
                Saving... <span className='spinner-border spinner-border-sm align-middle'></span>
              </button>
            ) : (
              <button
                type='submit'
                onClick={handleSubmit}
                className='btn  btn-sm fs-6 mx-6'
                style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
              >
                Save
              </button>
            )}
          </div>
        </div>
      </Drawer>
      <ToastContainer />
    </>
  )
}

export default EditTask

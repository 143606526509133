import ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'
import {toast} from 'react-toastify'

function stripHtmlTags(str) {
  return str.replace(/<\/?[^>]+(>|$)/g, '')
}

export const ExportControls = async (activeFrameworks, controls) => {
  for (const framework of activeFrameworks) {
    const filteredControls = controls.filter((control) => {
      if (control.author === 'Custom Control') return true
      if (!control.control_framework_mappings) return false
      return (
        control.control_framework_mappings.startsWith(framework) &&
        !control.control_framework_mappings.startsWith('ISO 27001:2013')
      )
    })

    if (filteredControls.length > 0) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Controls')

      worksheet.views = [{state: 'frozen', ySplit: 1}]

      worksheet.columns = [
        {header: 'Control', key: 'controlObjective', width: 40},
        {header: 'Control Description', key: 'controlDescription', width: 60},
        {header: 'Category', key: 'clause', width: 30},
        {header: 'Framework', key: 'sec', width: 40},
        {header: 'Author', key: 'author', width: 20},
        {header: 'Platform', key: 'platform', width: 15},
        {header: 'Document', key: 'document', width: 15},
        {header: 'Status', key: 'status', width: 15},
        {header: 'Justification for exclusion', key: 'justification', width: 40},
      ]

      const headerRow = worksheet.getRow(1)
      headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FF000000'},
      }
      headerRow.height = 30
      headerRow.alignment = {horizontal: 'center'}

      for (let i = 1; i <= 11; i++) {
        const cell = headerRow.getCell(i)
        cell.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 11}
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'FF000000'},
        }
        cell.alignment = {horizontal: 'center', vertical: 'middle'}
      }

      const exportData = filteredControls.map((row) => ({
        controlObjective: row.control_title || '',
        controlDescription: stripHtmlTags(row.control_description) || '',
        clause: row.control_category,
        sec: row.control_framework_mappings || '',
        author: row.author || 'SecureSlate',
        platform: row.control_test || '',
        document: row.control_document || '',
        status: row.control_evidence_status || '',
        justification: row.deactivation_control || '',
      }))

      exportData.forEach((item) => {
        const row = worksheet.addRow(item)
        row.height = 20
        row.font = {size: 10}

        if (item.status === 'Deactivated') {
          row.eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'DDEEFF'},
            }
          })
        }

        if (item.status === 'OK') {
          row.getCell('H').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'D9EAD3'},
          }
        } else if (item.status === 'Unhealthy') {
          row.getCell('H').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'F4CCCC'},
          }
        }
      })

      const buffer = await workbook.xlsx.writeBuffer()
      const excelBlob = new Blob([buffer], {type: 'application/octet-stream'})

      saveAs(excelBlob, `controls_${framework}.xlsx`)

      toast.success('Controls data exported successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    }
  }
}
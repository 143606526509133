import React, {useEffect, useRef, useState} from 'react'
import db, {storage} from '../../../../services/firebase'
import {addDoc, collection, doc, serverTimestamp} from 'firebase/firestore'
import {useAuth} from '../../../modules/auth'
import Drawer from 'react-modern-drawer'
import Dropzone from 'react-dropzone'
import {ToastContainer, toast} from 'react-toastify'
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import * as XLSX from 'xlsx';

export interface policy {
  msg: string
  message: string
  fileUrl?: any
}

type Props = {
  isOpen: boolean
  onClose: () => void
}

interface ParsedData {
  [key: string]: string;
}

interface LogoData {
  url: string;
  name: string;
  size: number;
}

const Importcsv: React.FC<Props> = ({isOpen, onClose}) => {
  const {currentUser} = useAuth()
  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File[]>([])
  const drawerRef = useRef<HTMLDivElement>(null)
  const [uploadedFile, setUploadedFile] = useState<{name: string; size: number} | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])


  const handleUpload = (acceptedFiles: File[]) => {
    const xlsxFile = acceptedFiles.find((file) => file.name.toLowerCase().endsWith('.xlsx'));
  
    if (xlsxFile) {
      setFile([xlsxFile]);
      setUploadedFile({
        name: xlsxFile.name,
        size: xlsxFile.size,
      });
    }
  };

  const fieldMapping = {
    'Vendor Name': 'vendor_name',
    'Website URL': 'website_url',
    'Terms URL': 'terms_url',
    'Policy URL': 'policy_url',
    // 'Risk Level': 'risk_level',
    // 'Reliance': 'reliance',
    // 'Category': 'category',
    // 'Two factor': 'two_factor',
    // 'Authentication Type': 'authentication_type'
  };
  
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    if (!file || file.length === 0) {
      setMessage({ error: true, msg: 'Please select an XLSX file' });
      setShowAlert(true);
      setIsLoading(false);
      return;
    }

    try {
      const xlsxFile = file[0];
      const fileReader = new FileReader();
      setIsLoading(true);
      fileReader.onload = async (event) => {
        const arrayBuffer = event.target?.result as ArrayBuffer;
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (data.length <= 1) {
          toast.error('No data found in the XLSX file', {
            position: toast.POSITION.TOP_CENTER,
            className: 'bg-white',
            style: {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              color: 'black',
            },
            autoClose: 3000,
          });
          setIsLoading(false);
          return;
        }

        // Validate the headers
        const headers = data[0] as string[];
        const isValid = Object.keys(fieldMapping).every((header) => headers.includes(header));

        if (!isValid) {
          toast.error('Invalid headers in XLSX file', {
            position: toast.POSITION.TOP_CENTER,
            className: 'bg-white',
            style: {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              color: 'black',
            },
            autoClose: 3000,
          });
          setIsLoading(false);
          return;
        }

        // Process the rows and apply the mapping before sending to Firestore
        const parsedDataArray = data.slice(1).map((row) => {
          const parsedData: ParsedData = headers.reduce((obj: ParsedData, header: string, index: number) => {
            const targetField = fieldMapping[header];
            if (targetField) {
              obj[targetField] = (row as string[])[index] || '';
            }
            return obj;
          }, {});
          return parsedData;
        });

        if (currentUser) {
          const tenantRef = doc(db, 'tenants', currentUser.tenantId!);
          const vendorsRef = collection(tenantRef, 'vendors');

          for (const parsedData of parsedDataArray) {
            const websiteUrl = parsedData['website_url'] || '';
            let logoData: LogoData | null = null;

            // Optional: Fetch and upload logo if Website URL exists
            if (websiteUrl) {
              try {
                const response = await fetch(
                  `https://us-central1-slate-134a0.cloudfunctions.net/fetchLogo?url=${encodeURIComponent(websiteUrl)}`
                );
                if (response.ok) {
                  const blob = await response.blob();
                  const fileName = `${Date.now()}_${parsedData['vendor_name'].replace(/\s+/g, '_')}_logo.png`;
                  const fileRef = ref(storage, `tenants/${currentUser.tenantId}/vendorsLogo/${fileName}`);
                  await uploadBytes(fileRef, blob);
                  const logoUrl = await getDownloadURL(fileRef);
                  logoData = {
                    url: logoUrl,
                    name: `${parsedData['vendor_name']}_logo.png`,
                    size: blob.size,
                  };
                }
              } catch (error) {
                console.error('Error fetching or uploading logo:', error);
              }
            }
            setIsLoading(true);
            // Upload mapped data to Firestore
            await addDoc(vendorsRef, {
              ...parsedData,
              uploadedBy: currentUser.uid,
              uploadedAt: serverTimestamp(),
              logo: logoData ? [logoData] : [],
              tenantId:currentUser?.tenantId,
              uid:currentUser?.uid
            });

            const currentDate = new Date()
            const year = currentDate.getFullYear()
            const month = currentDate.getMonth() + 1
            const day = currentDate.getDate()
            const todaysDate = `${month}/${day}/${year}`

            // Add activity log
            const activitiesRef = collection(tenantRef, 'activities');
            await addDoc(activitiesRef, {
              message: `${currentUser.userName} uploaded a new vendor through XLSX file '${uploadedFile?.name}'`,
              timestamp: serverTimestamp(),
              tenantId: currentUser.tenantId,
              uid: currentUser.uid,
              username: currentUser.userName,
              date: todaysDate,
              email: currentUser.email,
              target: 'Vendor Risk',
              action: 'Vendor added',
            });
          }
        }

        toast.success('Vendors added successfully', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          autoClose: 3000,
        });
        setIsLoading(false);
        setFile([]);
        setUploadedFile(null);
      };

      fileReader.onerror = (error) => {
        setMessage({ error: true, msg: 'Error reading XLSX file' });
        setShowAlert(true);
      };

      fileReader.readAsArrayBuffer(xlsxFile);
    } catch (err) {
      setMessage({ error: true, msg: 'Error uploading XLSX file' });
      setShowAlert(true);
    } finally {
      setIsLoading(false);
      onClose()
    }
  };
  

  const handleRemoveFile = () => {
    setUploadedFile(null)
  }

  return (
    <>
      {' '}
      <ToastContainer />
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        <div
          className=' border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Import Vendors
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary'
              aria-label='Close'
              onClick={onClose}
              style={{
                transition: 'all 0.2s ease',
              }}
              onMouseEnter={(e) => {
                if (e.currentTarget) {
                  e.currentTarget.style.backgroundColor = '#fff'
                  const svgElement = e.currentTarget.querySelector('svg')
                  if (svgElement) {
                    svgElement.style.stroke = '#1CBB8D'
                  }
                }
              }}
              onMouseLeave={(e) => {
                if (e.currentTarget) {
                  e.currentTarget.style.backgroundColor = '#fff'
                  const svgElement = e.currentTarget.querySelector('svg')
                  if (svgElement) {
                    svgElement.style.stroke = '#5A5A5A'
                  }
                }
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>

        {showAlert && (
          <div style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>
            {message?.msg && (
              <div
                className={
                  message?.error
                    ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                    : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
                }
              >
                {message?.error ? (
                  <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ) : (
                  <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                )}

                <div
                  className={
                    message?.error
                      ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                      : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                  }
                >
                  {message?.error ? (
                    <h5 className='mb-1'>Error!!</h5>
                  ) : (
                    <h5 className='mb-1'>Sucess!!</h5>
                  )}
                  <span>{message?.msg}</span>
                </div>
                <button
                  type='button'
                  className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                  onClick={() => setShowAlert(false)}
                >
                  <span className='svg-icon svg-icon-1 svg-icon-success'>
                    <span className='svg-icon svg-icon-2x svg-icon-primary'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='mh-50px'
                      >
                        <rect
                          opacity='0.5'
                          x='6'
                          y='17.3137'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(-45 6 17.3137)'
                          fill='black'
                        ></rect>
                        <rect
                          x='7.41422'
                          y='6'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(45 7.41422 6)'
                          fill='black'
                        ></rect>
                      </svg>
                    </span>
                  </span>
                </button>
              </div>
            )}
          </div>
        )}

        <div
          className=' scroll-y overflow-auto p-7'
          tabIndex={-1}
          style={{height: 'calc(100vh - 20px)'}}
        >
          <div className='fs-6 fw-semibold' style={{color: '#5A5A5A'}}>
            Please download this excel template for vendors. Make sure you add all the required
            details and upload it here.
          </div>
          <div className='mt-4'>
            <div className={`card mb-4 mb-xl-8 mt-2`}>
              <div
                className='card-header border-0 pt-6 mb-5'
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <img
                    src='/media/icon/file2.svg'
                    alt=''
                    className='img-fluid'
                    style={{width: '50px', height: '50px', marginRight: '10px'}} // Adjust marginRight as needed
                  />
                  <div className='d-flex justify-content-start flex-column fw-bold fs-6'>
                    {/* {file[0].name} */}
                    Vendors Template.xlsx
                    <span className=' fw-semibold d-block fs-7' style={{color: '#5A5A5A'}}>
                      9.9 KB
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    transition: 'all 0.2s ease',
                  }}
                  onMouseEnter={(e) => {
                    if (e.currentTarget) {
                      e.currentTarget.style.backgroundColor = '#fff'
                      const svgElement = e.currentTarget.querySelector('svg')
                      if (svgElement) {
                        svgElement.style.stroke = '#1CBB8D'
                      }
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (e.currentTarget) {
                      e.currentTarget.style.backgroundColor = '#fff'
                      const svgElement = e.currentTarget.querySelector('svg')
                      if (svgElement) {
                        svgElement.style.stroke = '#5A5A5A'
                      }
                    }
                  }}
                >
                  <a
                    href='/templates/VendorTemplate.xlsx'
                    download
                    className='text-dark fw-semibold text-hover-primary btn btn-sm btn-active-light-primary ms-6'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5A5A5A'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-cloud-download mx-2 mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4' />
                      <path d='M12 13l0 9' />
                      <path d='M9 19l3 3l3 -3' />
                    </svg>
                    {/* <span className='fw-semibold text-hover-primary' style={{color: '#5A5A5A'}}> */}
                    Download
                    {/* </span> */}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='form'>
            <div className='form-group mb-4'>
              <div className='main-container'>
                <div className='fw-bold fs-6 mb-2 mt-6'> Your Uploads</div>
                {uploadedFile ? (
                  <div className={`card mb-4 mb-xl-8 mt-4`}>
                    <div
                      className='card-header border-0 pt-6 mb-5'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                          src='/media/icon/file2.svg'
                          alt=''
                          className='img-fluid'
                          style={{width: '50px', height: '50px', marginRight: '10px'}}
                        />
                        <div className='d-flex justify-content-start flex-column fw-bold fs-6'>
                          {uploadedFile.name}
                          <span className='fw-semibold d-block fs-6' style={{color: '#5A5A5A'}}>
                            Size: {(uploadedFile.size / 1024).toFixed(2)} KB
                          </span>
                        </div>
                      </div>
                      <div>
                        {' '}
                        <span className='mx-1 fs-7'>Uploaded</span>{' '}
                        <span className='btn btn-icon btn-sm btn-active-light-primary'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            viewBox='0 0 24 24'
                            fill='#5A5A5A'
                            className='icon icon-tabler icons-tabler-filled icon-tabler-circle-x'
                            onClick={handleRemoveFile}
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z' />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Dropzone
                    onDrop={handleUpload}
                    minSize={0}
                    accept={{
                      'application/vnd.ms-excel': [],
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                      '': ['.xlsx'], // This accepts any file with these extensions
                    }}
                    maxSize={5 * 1024 * 1024}
                  >
                    {({getRootProps, getInputProps}) => (
                      <div
                        {...getRootProps({
                          className: 'dropzone',
                          style: {
                            backgroundColor: '#fff', 
                            padding: '20px',
                            border: '2px dashed #ccc',
                            borderRadius: '4px',
                            textAlign: 'center',
                            cursor: 'pointer',
                          },
                        })}
                      >
                        <input {...getInputProps()} />
                        <img
                          src='/media/icon/uploadFile.svg'
                          alt=''
                          loading='lazy'
                          className='mb-4 mt-4'
                        />
                        <p className='fw-bold fs-6'>
                          Drag and drop or <a href='#'>select</a> a files
                        </p>
                        <p className='fs-6'>Upload upto 5 mb. Supported formats: xlsx</p>
                      </div>
                    )}
                  </Dropzone>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className='offcanvas-footer d-flex justify-content-end  border-top pt-4 pb-4 px-6'
          style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
        >
          <button type='button' className='btn btn-light btn-sm mx-2 ' onClick={onClose}>
            Cancel
          </button>
          {isLoading ? (
            <button
              type='submit'
              value='Submit'
              className='btn btn-sm fs-6'
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                height: '40px',
                borderRadius: '4px',

                transition: 'background-color 0.3s',
              }}          
              disabled
            >
       
              Adding vendor...{' '}<span className='spinner-border spinner-border-sm align-middle m-2'></span>
            </button>
          ) : (
            <button
              className='btn btn-sm fs-6'
              onClick={(e) => {
                e.preventDefault();
                setIsLoading(true); 
                handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>);
              }}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              disabled={isLoading}
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                height: '40px',
                borderRadius: '4px',

                transition: 'background-color 0.3s',
              }}
            >
              Add vendor
            </button>
          )}
        </div>
      </Drawer>
    </>
  )
}

export default Importcsv

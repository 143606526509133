/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {registerWithEmailAndPassword} from '../core/_requests'
import {Link} from 'react-router-dom'
import {signInWithPopup, updateProfile} from 'firebase/auth'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {AuthModel, UserModel} from '../core/_models'
import {v4 as uuid} from 'uuid'
import {Helmet} from 'react-helmet'

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  where,
  query,
  writeBatch,
} from '@firebase/firestore'
import db, {auth1, googleProvider} from '../../../../services/firebase'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
  activationCode: '',
  referralId: '',
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  activationCode: Yup.string()
    .matches(/^[a-zA-Z0-9]{10}$/, 'Activation code must be exactly 10 alphanumeric characters')
    .required('Activation code is required'),
  referralId: Yup.string()
    .min(10, 'Minimum 10 symbols')
    .max(50, 'Maximum 50 symbols')
    .notRequired(),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})
const generateUniqueTenantId = () => {
  return uuid()
}

export function Registration() {
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser, googleSignIn} = useAuth()
  const [usersData, setUsersData] = useState<any[]>([])

  useEffect(() => {
    async function fetchUsersData() {
      const usersCollectionRef = collection(db, 'users')
      try {
        const usersSnapshot = await getDocs(usersCollectionRef)
        const usersList = usersSnapshot.docs.map((doc) => doc.data())
        setUsersData(usersList)
      } catch (error) {
        console.error('Error fetching users data:', error)
      }
    }

    fetchUsersData()
  }, [])

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: registrationSchema,
  //   onSubmit: async (values, {setStatus, setSubmitting}) => {
  //     setLoading(true)
  //     try {
  //       const db = getFirestore()

  //       const activationCodesSnapshot = await getDocs(collection(db, 'activation_codes'))

  //       let activationCodeValid = false
  //       let associatedFrameworks: string[] = []

  //       // Iterate over each document to check if the document ID matches the provided activation code
  //       activationCodesSnapshot.forEach((doc) => {
  //         if (doc.id === values.activationCode) {
  //           activationCodeValid = true
  //           const activationCodeData = doc.data()
  //           if (Array.isArray(activationCodeData.framework)) {
  //             associatedFrameworks = activationCodeData.framework
  //           }
  //         }
  //       })

  //       const isEmailUsed = usersData.some((user) => user.email === values.email)
  //       if (isEmailUsed) {
  //         setStatus('Email is already in use.')
  //         setSubmitting(false)
  //         setLoading(false)
  //         return
  //       }

  //       if (!activationCodeValid) {
  //         setStatus('Invalid activation code')
  //         setSubmitting(false)
  //         setLoading(false)
  //         return
  //       }

  //       const authCredential = await registerWithEmailAndPassword(
  //         values.email,
  //         values.password,
  //         values.firstname,
  //         values.lastname,
  //         values.changepassword,
  //         values.activationCode,
  //         values.referralId
  //       )
  //       // Generate a unique tenant ID
  //       const tenantId = generateUniqueTenantId()
  //       const activationCode = values.activationCode

  //       // Update user profile with first name and last name
  //       await updateProfile(authCredential.user, {
  //         displayName: `${values.firstname} ${values.lastname}`,
  //       })

  //       // Fetch user details by token (if needed)
  //       if (!authCredential.user || !authCredential.user.uid) {
  //         throw new Error('User is not authenticated')
  //       }

  //       const authModel: AuthModel = {
  //         api_token: await authCredential.user.getIdToken(),
  //         email: authCredential.user.email,
  //         uid: authCredential.user.uid,
  //         userName: `${values.firstname} ${values.lastname}`,
  //         password: values.password,
  //         first_name: values.firstname,
  //         last_name: values.lastname,
  //         tenantId: tenantId,
  //       }
  //       const userModel: UserModel = {
  //         // map properties from authModel to userModel
  //         uid: authModel.uid || '',
  //         userName: authModel.userName || '',
  //         api_token: authModel.api_token || '',
  //         password: authModel.password || '',
  //         email: authModel.email || '',
  //         first_name: authModel.first_name || '',
  //         last_name: authModel.last_name || '',
  //         tenantId: tenantId || '',
  //         activation_codes: activationCode || '',
  //         referral_id: values.referralId || '',
  //         role: 'Admin',
  //       }

  //       await setDoc(doc(db, 'users', authCredential.user.uid), userModel)

  //       saveAuth(authModel)
  //       setCurrentUser(userModel)

  //       const today = new Date()
  //       const year = today.getFullYear()
  //       const month = String(today.getMonth() + 1).padStart(2, '0')
  //       const day = String(today.getDate()).padStart(2, '0')
  //       const formattedDate = `${year}-${month}-${day}`

  //       const batch = writeBatch(db)

  //       const tenantRef = doc(db, 'tenants', tenantId)
  //       batch.set(tenantRef, {})

  //       // Copy controls
  //       const controlsSnapshot = await getDocs(collection(db, 'default_controls'))
  //       const controlsCollectionRef = collection(tenantRef, 'controls')
  //       controlsSnapshot.forEach((controlDoc) => {
  //         const controlRef = doc(controlsCollectionRef, controlDoc.id)
  //         batch.set(controlRef, controlDoc.data())
  //       })

  //       // Copy tests
  //       const testsSnapshot = await getDocs(collection(db, 'default_tests'))
  //       const testsCollectionRef = collection(tenantRef, 'tests')
  //       testsSnapshot.forEach((testDoc) => {
  //         const testRef = doc(testsCollectionRef, testDoc.id)
  //         batch.set(testRef, testDoc.data())
  //       })

  //       const policySnapshot = await getDocs(collection(db, 'policy_templates'))
  //       const policyCollectionRef = collection(tenantRef, 'policies')
  //       policySnapshot.forEach((policyDoc) => {
  //         const policyRef = doc(policyCollectionRef, policyDoc.id)
  //         batch.set(policyRef, policyDoc.data())
  //       })

  //       // Add default group
  //       const newGroup = {
  //         groupName: '[Default]',
  //         checklist: '[Default]',
  //         createdOn: formattedDate,
  //         tenantId: tenantId,
  //       }
  //       const groupRef = doc(collection(tenantRef, 'personnel_group'))
  //       batch.set(groupRef, newGroup)

  //       // Add default personnel checklist
  //       const newPersonnelChecklist = {
  //         checklist_name: '[Default]',
  //         policies: [],
  //         createdon: formattedDate,
  //         tenantId: tenantId,
  //       }
  //       const checklistRef = doc(collection(tenantRef, 'personnel_checklist'))
  //       batch.set(checklistRef, newPersonnelChecklist)

  //       const newPersonnel = {
  //         created_at: new Date().toISOString(),
  //         tenantId: tenantId,
  //         uid: authModel.uid || '',
  //         full_name: authModel.userName || '',
  //         group: '[Default]',
  //         role: 'Admin',
  //         start_date: formattedDate,
  //         email: authModel.email || '',
  //       }
  //       // const personnelRef = doc(collection(tenantRef, 'employees'))
  //       // batch.set(personnelRef, newPersonnel)

  //       //need to set currentUser.uid as the employee unique id
  //       if (!newPersonnel.uid) {
  //         throw new Error('UID is required to create a personnel document')
  //       }

  //       const personnelRef = doc(collection(tenantRef, 'employees'), newPersonnel.uid)

  //       // Add the newPersonnel object to the batch
  //       batch.set(personnelRef, newPersonnel)

  //       // const frameworks = {
  //       //  frameworks:['ISO 27001:2022']
  //       // }

  //       const frameworks = {
  //         frameworks: associatedFrameworks,
  //       }

  //       const activeRef = doc(collection(tenantRef, 'active_frameworks'))
  //       batch.set(activeRef, frameworks)

  //       const integrations = {
  //         integrations: ['AWS', 'Asana', 'GitLab', 'Sentry'],
  //       }
  //       const integrationsRef = doc(collection(tenantRef, 'active_integrations'))
  //       batch.set(integrationsRef, integrations)

  //       // Commit the batch
  //       await batch.commit()
  //     } catch (error) {
  //       console.error(error)
  //       saveAuth(undefined)
  //       setStatus('The registration details is incorrect')
  //       setSubmitting(false)
  //       setLoading(false)
  //     }
  //   },
  // })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const db = getFirestore();
  
        // Step 1: Register the new user
        const authCredential = await registerWithEmailAndPassword(
          values.email,
          values.password,
          values.firstname,
          values.lastname,
          values.changepassword,
          values.activationCode,
          values.referralId
        );
  
        if (!authCredential.user || !authCredential.user.uid) {
          throw new Error('User is not authenticated');
        }
  
        // Step 2: Validate activation code
        const activationCodesSnapshot = await getDocs(collection(db, 'activation_codes'));
        let activationCodeValid = false;
        let associatedFrameworks: string[] = []
  
        activationCodesSnapshot.forEach((doc) => {
          if (doc.id === values.activationCode) {
            activationCodeValid = true;
            const activationCodeData = doc.data();
            if (Array.isArray(activationCodeData.framework)) {
              associatedFrameworks = activationCodeData.framework;
            }
          }
        });
  
        // Check if email is already in use
        const isEmailUsed = usersData.some((user) => user.email === values.email);
        if (isEmailUsed) {
          setStatus('Email is already in use.');
          setSubmitting(false);
          setLoading(false);
          return;
        }
  
        // Validate activation code
        if (!activationCodeValid) {
          setStatus('Invalid activation code');
          setSubmitting(false);
          setLoading(false);
          return;
        }
  
        // Step 3: Retrieve token to confirm authentication
        const userToken = await authCredential.user.getIdToken();
        const tenantId = generateUniqueTenantId();
  
        // Step 4: Update profile with first and last name
        await updateProfile(authCredential.user, {
          displayName: `${values.firstname} ${values.lastname}`,
        });
  
        // Step 5: Set up auth and user models
        const authModel = {
          api_token: userToken,
          email: authCredential.user.email,
          uid: authCredential.user.uid,
          userName: `${values.firstname} ${values.lastname}`,
          password: values.password,
          first_name: values.firstname,
          last_name: values.lastname,
          tenantId: tenantId,
        };
  
        const userModel = {
          uid: authModel.uid,
          userName: authModel.userName,
          api_token: authModel.api_token,
          password: authModel.password,
          email: authModel.email,
          first_name: authModel.first_name,
          last_name: authModel.last_name,
          tenantId: tenantId,
          activation_codes: values.activationCode,
          referral_id: values.referralId || '',
          role: 'Owner',
        };
  
        // Step 6: Upload user model to Firestore
        await setDoc(doc(db, 'users', authCredential.user.uid), userModel);
  
        // Save auth model and set current user
        saveAuth(authModel);
        setCurrentUser(userModel);
  
        // Create a batch write for setting up tenant data
        const formattedDate = new Date().toISOString().split('T')[0];
        const batch = writeBatch(db);
  
        // Step 7: Setup tenant data
        const tenantRef = doc(db, 'tenants', tenantId);
        batch.set(tenantRef, {});
  
        // if (associatedFrameworks[0] === 'GDPR') {
        //   // Copy default controls, tests, and policies to the new tenant (GDPR specific)
        //   const controlsSnapshot = await getDocs(collection(db, 'gdpr_controls'));
        //   controlsSnapshot.forEach((controlDoc) => {
        //     batch.set(doc(collection(tenantRef, 'controls'), controlDoc.id), controlDoc.data());
        //   });
        // } else {
          // Copy default controls, tests, and policies to the new tenant (Non-GDPR)
          const controlsSnapshot = await getDocs(collection(db, 'default_controls'));
          controlsSnapshot.forEach((controlDoc) => {
            batch.set(doc(collection(tenantRef, 'controls'), controlDoc.id), controlDoc.data());
          });
        // }
  
        const testsSnapshot = await getDocs(collection(db, 'default_tests'));
        testsSnapshot.forEach((testDoc) => {
          batch.set(doc(collection(tenantRef, 'tests'), testDoc.id), testDoc.data());
        });
  
        const policySnapshot = await getDocs(collection(db, 'policy_templates'));
        policySnapshot.forEach((policyDoc) => {
          batch.set(doc(collection(tenantRef, 'policies'), policyDoc.id), policyDoc.data());
        });
  
        // Add default group and personnel checklist
        const groupRef = doc(collection(tenantRef, 'personnel_group'));
        batch.set(groupRef, {
          groupName: '[Default]',
          checklist: '[Default]',
          createdOn: formattedDate,
          tenantId: tenantId,
        });
  
        const checklistRef = doc(collection(tenantRef, 'personnel_checklist'));
        batch.set(checklistRef, {
          checklist_name: '[Default]',
          policies: [],
          createdon: formattedDate,
          tenantId: tenantId,
        });
  
        // Add the new personnel data
        const personnelRef = doc(collection(tenantRef, 'employees'), authModel.uid);
        batch.set(personnelRef, {
          created_at: new Date().toISOString(),
          tenantId: tenantId,
          uid: authModel.uid,
          full_name: authModel.userName,
          group: '[Default]',
          role: 'Owner',
          start_date: formattedDate,
          email: authModel.email,
        });
  
        // Step 8: Add frameworks and integrations
        const activeFrameworksRef = doc(collection(tenantRef, 'active_frameworks'));
        batch.set(activeFrameworksRef, { frameworks: associatedFrameworks });
  
        const integrationsRef = doc(collection(tenantRef, 'active_integrations'));
        batch.set(integrationsRef, { integrations: ['AWS', 'Asana', 'GitLab', 'Sentry'] });
  
        // Step 9: Commit batch write
        await batch.commit();
        console.log('Registration and data upload successful');
  
      } catch (error) {
        console.error('Error during registration:', error);
        saveAuth(undefined);
        setStatus('The registration details are incorrect');
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  
  


  const handleGoogleSignUp = async () => {
    // e.preventDefault();
    setLoading(false)

    try {
      const result = await signInWithPopup(auth1, googleProvider)
      // const result = await googleSignIn()
      const user = result.user
      console.log('User', user)
      // You can customize the data you want to store in Firestore
      const userData = {
        apiToken: user.getIdToken(),
        uid: user.uid,
        email: user.email,
        userName: user.displayName,
        tenantId: user.tenantId,
        // activationCode:user.activationCode
      }

      const usersCollection = collection(db, 'users') // 'users' is the Firestore collection name
      const emailQuery = query(usersCollection, where('email', '==', user.email))
      const emailQuerySnapshot = await getDocs(emailQuery)
      if (!emailQuerySnapshot.empty) {
        setErrorMessage('Email is already in use.')
        // You can handle this case by displaying a message to the user or taking other actions.
        return
      }
      await addDoc(usersCollection, userData)
      setCurrentUser(userData)
    } catch (error) {
      console.error('Google sign-up error:', error)
    }
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  useEffect(() => {
    if (formik.status) {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }, [formik.status])

  return (
    <>
      {' '}
      <Helmet>
        <title>Secureslate</title>
      </Helmet>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
          {/* end::Title */}

          <div className='text-gray-500 fw-semibold fs-6'>
            {' '}
            Safeguarding Your Data with Robust Security Solutions.
          </div>
        </div>
        {/* end::Heading */}

        {/* begin::Login options */}
        {/* <div className='justify-content-center  row g-3 mb-9'> */}

        {/* <div className='col-md-9'>
    
          <button
            onClick={handleGoogleSignUp}
            type='button'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-dark bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </button>
          {errorMessage && (
            <div className='error-message mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{errorMessage}</div>
            </div>
          )}

        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
        </div> */}
        {/* </div> */}
        {/* end::Login options */}

        {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group Firstname */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>First name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        <div className='fv-row mb-8'>
          {/* begin::Form group Lastname */}
          <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
          <input
            placeholder='Last name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.lastname && formik.errors.lastname,
              },
              {
                'is-valid': formik.touched.lastname && !formik.errors.lastname,
              }
            )}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastname}</span>
              </div>
            </div>
          )}
          {/* end::Form group */}
        </div>

        {/* begin::Form group Email */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Password</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>
            Use 8 or more characters with a mix of letters, numbers & symbols.
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        <div className='fv-row mb-8'>
          {/* begin::Form group activation code */}
          <label className='form-label fw-bolder text-dark fs-6'>Activation Code</label>
          <input
            placeholder='Activation Code'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('activationCode')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.activationCode && formik.errors.activationCode,
              },
              {
                'is-valid': formik.touched.activationCode && !formik.errors.activationCode,
              }
            )}
          />
          {formik.touched.activationCode && formik.errors.activationCode && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.activationCode}</span>
              </div>
            </div>
          )}
          {/* end::Form group */}
        </div>

        <div className='fv-row mb-8'>
          {/* begin::Form group activation code */}
          <label className='form-label fw-bolder text-dark fs-6'>Referral ID (Optional)</label>
          <input
            placeholder='Referral Id'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('referralId')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.referralId && formik.errors.referralId,
              },
              {
                'is-valid': formik.touched.referralId && !formik.errors.referralId,
              }
            )}
          />
          {formik.touched.referralId && formik.errors.referralId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.referralId}</span>
              </div>
            </div>
          )}
          {/* end::Form group */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('acceptTerms')}
            />
            <span>
              I Accept the{' '}
              <a
                href='https://getsecureslate.com/policy#terms'
                target='_blank'
                className='ms-1 link-primary fw-semibold'
              >
                Terms
              </a>
              .
            </span>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-4'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
          >
            {!loading && <span className='indicator-label'>Sign up</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <span className='fs-6 fw-semibold'>
            Already have an account?
            <Link to='/auth/login'>{''} Login</Link>
          </span>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}

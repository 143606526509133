import Swal from 'sweetalert2'
import {
  deleteDoc,
  doc,
} from 'firebase/firestore'
import { useAuth } from '../../../../modules/auth'
import db, { storage } from '../../../../../services/firebase'
import { deleteObject, listAll, ref } from 'firebase/storage'

const DataRoomGrouping = ({ selectedItems, onClearSelection }) => {
  const { currentUser } = useAuth()

  const handleDeleteFile = async (fileId, fileName) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${fileName} ?`,
        text: `Are you sure you want to remove ${fileName} from the dataroom? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const fileDocRef = doc(db, 'tenants', currentUser?.tenantId!, 'dataroom', fileId)
        await deleteDoc(fileDocRef)
        onClearSelection()
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `"${fileName}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error deleting file:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the file.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  const deleteFolder = async (folderId, folderName) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${folderName} ?`,
        text: `Are you sure you want to remove ${folderName} from the dataroom? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const folderRef = ref(storage, `/tenants/${tenantId}/dataroom/${folderName}`)

        // List all items (files and subfolders) in the folder
        const folderItems = await listAll(folderRef)

        // Delete all files in the folder from Firebase Storage
        for (const item of folderItems.items) {
          await deleteObject(item)
        }

        // Delete folder document from Firestore
        const folderDocRef = doc(db, 'tenants', currentUser?.tenantId!, 'dataroom', folderId)
        await deleteDoc(folderDocRef)
        onClearSelection()
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `"${folderName}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error deleting folder:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the folder.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  // New function to delete items sequentially
  const deleteSelectedItemsSequentially = async () => {
    for (const item of selectedItems) {
      if (item.is_folder) {
        await deleteFolder(item.id, item.folder_name) // Ensure folder deletion is awaited
      } else {
        await handleDeleteFile(item.id, item.name)   // Ensure file deletion is awaited
      }
    }
  }

  return (
    <div className='d-flex justify-content-end align-items-center'>
      {selectedItems.length > 0 ? (
        <div className='pe-4 text-center' style={{ color: '#A1A5B7' }}>
          <span>
            <p className='pt-4'>{selectedItems.length} Selected</p>
          </span>
        </div>
      ) : null}

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button
          className='btn btn-sm fs-6'
          onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#148363')
          }
          onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#1CBB8D')
          }
          onClick={async () => {
            // Now use the sequential deletion function
            await deleteSelectedItemsSequentially()
          }}
          style={{
            backgroundColor: '#1CBB8D',
            color: '#fff',
            width: '140px',
            height: '40px',
            borderRadius: '4px',
            left: '615px',
            top: '20px',
            transition: 'background-color 0.3s',
          }}
        >
          {' '}
          Delete Selected
        </button>
      </div>
    </div>
  )
}

export { DataRoomGrouping }

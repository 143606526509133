/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import db from '../../../services/firebase'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
  writeBatch,
} from 'firebase/firestore'
import {Overlay, Popover} from 'react-bootstrap'
import {useAuth} from '../../modules/auth'
import {ViewControl} from './components/ViewControl'
import ReactPaginate from 'react-paginate'
import {Helmet} from 'react-helmet'
import {ControlListLoading} from './components/loading/ControlListLoading'
import Swal from 'sweetalert2'
import {ControlListToolbar} from './components/header/ControlListToolbar'
import {useLocation} from 'react-router-dom'
import Deactivate from './components/submodal/Deactivate'

type Props = {
  className: string
  blogs?: any
  getControlID: any
  onDataCountChange: any
}

const ControlManagement: React.FC<Props> = ({getControlID, onDataCountChange}) => {
  const [controls, setControls] = useState([] as any[])
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [showAllFrameworks, setShowAllFrameworks] = useState(false)
  const [searchLength, setSearchLength] = useState(false)
  const [selectedFramework, setSelectedFramework] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedOwner, setSelectedOwner] = useState('')
  const [personnels, setPersonnels] = useState<any[]>([])
  const [selectedAuthor, setSelectedAuthor] = useState('')
  const [filterMatch, setFilterMatch] = useState(false)
  const {currentUser} = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [controlID, setControlID] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [selectedNumber, setSelectedNumber] = useState('100')
  let ITEMS_PER_PAGE = parseInt(selectedNumber)
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [show1, setShow1] = useState(false)
  const target = useRef(null)
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const overlayRef = useRef<HTMLElement>(null)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const id = params.get('id')
  const [showDeactivatemodel, setShowDeactivatemodel] = useState(false)
  const [deactivateId, setDeactivateId] = useState('')
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLElement>(null)

  useEffect(() => {
    setFilteredItems(controls)
  }, [controls])

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target as Node) && show1) {
        setShow1(false)
      }
    }

    if (show1) {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [show1])

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 2000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    if (id) {
      setControlID(id)
      setIsModalOpen(true)
    }
  }, [id])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setPersonnels(risksData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    const unsubscribe = getControls()
    
    return () => unsubscribe && unsubscribe()
  }, [activeFrameworks])

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const setupSubscription = async () => {
      try {
        const tenantId = currentUser?.tenantId;
        
        if (!tenantId) {
          setActiveFrameworks([]);
          return;
        }

        const tenantRef = doc(db, 'tenants', tenantId);
        const frameworksCollectionRef = collection(tenantRef, 'active_frameworks');

        unsubscribe = onSnapshot(
          frameworksCollectionRef,
          (snapshot) => {
            const frameworksData = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
              frameworks: doc.data().frameworks,
            }));
            
            const frameworks = frameworksData[0]?.frameworks || [];
            setActiveFrameworks(frameworks);
          },
          (error) => {
            console.error('Firestore subscription error:', error);
          }
        );
      } catch (error) {
        console.error('Setup subscription error:', error);
      }
    };

    setupSubscription();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser?.tenantId]); 
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && isOpen) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isOpen])

  const getControls = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
          author: doc.data().author,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (control.author === 'Custom Control') return true

          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })
        setControls(filteredControls)
      })



      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const toggleDropdown2 = () => {
    setIsOpen(!isOpen);

  };

  const handleOpenModal = (id) => {
    setIsModalOpen(true)
    if (!id) {
      return
    }
    setControlID(id)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    // Assuming controls is your original data array
    if (searchTerm === '') {
      // If the search term is empty, reset to the original controls
      setFilteredItems(controls)
      setSearchLength(false)
    } else {
      const filteredItems = controls.filter((user) => {
        // Convert the control_id to lowercase for case-insensitive comparison
        const controlIdLower = user.control_title.toLowerCase()

        // Check if the control_id contains the search term
        return controlIdLower.includes(searchTerm)
      })
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }
  const handleReseta = () => {
    setSelectedFramework('')
    setSelectedStatus('')
    setSelectedOwner('')
    setSelectedAuthor('')
  }
  const handleResetClick = () => {
    handleReseta()
  }
  const pageCount = Math.ceil(filteredItems.length / ITEMS_PER_PAGE)

  const handlePageClick = ({selected}: {selected: number}) => {
    setCurrentPage(selected)
  }

  const offset = currentPage * ITEMS_PER_PAGE
  const sortedControls = filteredItems.sort((a, b) => {
    // Prioritize "Custom Control" author
    if (a.author === 'Custom Control' && b.author !== 'Custom Control') {
      return -1;
    } else if (a.author !== 'Custom Control' && b.author === 'Custom Control') {
      return 1;
    }
  
    // If both have the same author type or neither is "Custom Control", sort by another attribute, e.g., control_title
    return a.control_title.localeCompare(b.control_title);
  });

  
  const paginatedData = sortedControls
  .slice()
  .slice(offset, offset + ITEMS_PER_PAGE);

  const handleDeselectClick = () => {
    setCheckedItems([])
    setSelectAll(false)
  }

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked
    setSelectAll(isChecked)
    setCheckedItems(isChecked ? filteredItems.map((item) => item.id) : [])
  }

  const handleCheckChange = (e) => {
    const {checked, value} = e.target
    setCheckedItems((prev) => {
      const newCheckedItems = checked ? [...prev, value] : prev.filter((item) => item !== value)

      // Update selectAll state based on whether all items are checked
      setSelectAll(newCheckedItems.length === filteredItems.length)

      return newCheckedItems
    })
  }

  function processControlValues(inputString: string, inputString2: string): string {
    const concatenatedString = inputString + '+' + inputString2
    const parts = concatenatedString.split('+')

    let numerator = 0
    let denominator = 0

    parts.forEach((part) => {
      const splitPart = part.split('/')
      const num = parseInt(splitPart[0], 10)
      const den = parseInt(splitPart[1], 10)

      if (isNaN(num) || isNaN(den)) {
        console.error('Invalid input format')
        return
      }

      numerator += num
      denominator += den
    })

    if (denominator === 0) {
      return '0/0'
    }

    const processedValue = `${numerator}/${denominator}`
    return processedValue
  }

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const handleAssignOwner = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!selectedOwner) {
      // alert('Please select an owner')
      return
    }

    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) return

    const tenantRef = doc(db, 'tenants', tenantId)
    const batch = writeBatch(db)

    for (const controlId of checkedItems) {
      const testRef = doc(tenantRef, 'controls', controlId)
      batch.update(testRef, {control_owner: selectedOwner})
    }

    try {
      await batch.commit()
      Swal.fire({
        icon: 'success',
        title: 'Updated owner',
        text: ` "Successfully assigned ${selectedOwner} to ${checkedItems.length} control(s)`,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
      setShow1(false)
      setCheckedItems([])
      setSelectAll(false)
      setSelectedOwner('')
    } catch (error) {
      console.error('Error assigning owner:', error)
      alert('Failed to assign owner. Please try again.')
    }
  }

  const handleDeactivateActivate = async (control) => {
    setShowDeactivatemodel(true)
    setDeactivateId(control.id)
  }

  const handleDeactivate = () => {
    setShowDeactivatemodel(false)
  }

  const handleDeleteClick = async (control) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${control.control_title}`,
        text: `Are you sure you want to remove ${control.control_title}? This will also remove its association from all linked risks. This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        // await riskServices.deleteRisk(riskData.id)
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'controls')
        const docRef = doc(policyRef, control.id)
        await deleteDoc(docRef)

        if (control.risk_scenario && control.risk_scenario.length > 0) {
          const riskUpdates = control.risk_scenario.map(async (risk) => {
            const riskRef = doc(collection(tenantRef, 'risks'), risk.riskId)

            const riskDoc = await getDoc(riskRef)
            if (riskDoc.exists()) {
              const riskData = riskDoc.data()

              // Filter out the current control from the added_controls array
              const updatedAddedControls = riskData.added_controls.filter(
                (addedControl) => addedControl.id !== control.id
              )

              // Update the risk document
              await updateDoc(riskRef, {added_controls: updatedAddedControls})
            }
          })

          // Wait for all risk updates to complete
          await Promise.all(riskUpdates)
        }

        getControls()
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `"${control.control_title}" has been deleted and removed from all associated risks.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the control and updating associated risks.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // setDropdownVisible(false)
    }
  }

  const filterData = () => {
    let filteredData = controls.filter((control) => {
      let matchesFramework = true
      let matchesStatus = true
      let matchesOwner = true
      let matchesAuthor = true
      // let matchesCategory = true
      // let matchesEvidenceStatus = true

      if (selectedFramework) {
        matchesFramework = control.control_framework_mappings.includes(selectedFramework)
      }

      if (selectedStatus) {
        const [numerator, denominator] = control.control_test.split('/').map(Number)
        const [docNumerator, docDenominator] = control.control_document.split('/').map(Number)

        if (selectedStatus === 'Pass') {
          // Check both control_test and control_document
          matchesStatus =
            numerator === denominator &&
            denominator === 0 &&
            docNumerator === docDenominator &&
            docDenominator === 0
        } else if (selectedStatus === 'Fail') {
          // Check for failure in either control_test or control_document
          matchesStatus =
            numerator !== denominator ||
            denominator !== 0 ||
            docNumerator !== docDenominator ||
            docDenominator !== 0
        }
      }

      if (selectedOwner) {
        matchesOwner = control.control_owner === selectedOwner
      }

      if (selectedAuthor) {
        if (selectedAuthor === 'SecureSlate') {
          matchesAuthor = !control.author || control.author === 'SecureSlate'
        } else {
          matchesAuthor = control.author === selectedAuthor
        }
      }

      // if (selectedCategory) {
      //   matchesCategory = control.control_category === selectedCategory
      // }

      // if (selectedEvidenceStatus) {
      //   matchesEvidenceStatus = control.control_evidence_status === selectedEvidenceStatus
      // }

      return matchesFramework && matchesStatus && matchesOwner && matchesAuthor
    })

    setFilteredItems(filteredData)
    setFilterMatch(filteredData.length === 0)
  }

  const handleActivateActivate = async (control) => {
    try {
      const newStatus =
        control.control_evidence_status === 'Deactivated' ? 'Incomplete' : 'Deactivated'
      const action = control.control_evidence_status === 'Deactivated' ? 'activate' : 'deactivate'

      const result = await Swal.fire({
        icon: 'warning',
        title: `${action.charAt(0).toUpperCase() + action.slice(1)} ${control.control_title}`,
        text: `Are you sure you want to ${action} ${control.control_title}?`,
        showCancelButton: true,
        confirmButtonText: `Yes, ${action}`,
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const testDocRef = doc(db, `tenants/${tenantId}/controls/${control.id}`)
        await updateDoc(testDocRef, {
          control_evidence_status: newStatus,
          deactivation_control:''
        })

        Swal.fire({
          icon: 'success',
          title: `${action.charAt(0).toUpperCase() + action.slice(1)}d!`,
          text: `"${control.control_title}" has been ${action}d.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: `An error occurred while ${
          control.control_evidence_status === 'Deactivate' ? 'activating' : 'deactivating'
        } the control.`,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Secureslate | Controls</title>
      </Helmet>
      <div className='card-header border-0 pt-6 h-100 '>
        <div className='card-title d-flex justify-content-between align-items-center'>
          {/* Search */}

          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control  w-250px ps-14'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='card-toolbar'>
          {/* begin::Group actions */}
          {/* <ControlListToolbar /> */}
          {checkedItems.length > 0 ? (
            <>
              {checkedItems.length > 0 ? (
                <div className='  border-end pe-4 text-center' style={{color: '#A1A5B7'}}>
                  <span>
                    <p className='pt-4'>{checkedItems.length} Selected</p>
                  </span>
                </div>
              ) : null}
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='mx-5' style={{cursor: 'pointer'}} onClick={handleDeselectClick}>
                  <img src='/media/buttons/Deselect.svg' alt='Deselect' />
                </div>

                <div className='mb-1' style={{cursor: 'pointer'}}>
                  <button
                    className='btn btn-sm fs-6'
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                    ref={target}
                    onClick={() => setShow1(!show1)}
                    style={{
                      backgroundColor: '#1CBB8D',
                      color: '#fff',
                      width: '130px',
                      height: '40px',
                      borderRadius: '4px',
                      left: '615px',
                      top: '20px',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    {' '}
                    Assign Owner
                  </button>
                </div>
                <Overlay
                  show={show1}
                  target={target.current}
                  placement='bottom'
                  container={document.body}
                  containerPadding={10}
                >
                  {(props) => (
                    <Popover id='popover-contained' {...props}>
                      <div ref={overlayRef as React.RefObject<HTMLDivElement>}>
                        {/* <Popover.Header as='h3' className='fw-bold fs-5'>
                      Assign Owner
                    </Popover.Header> */}
                        <Popover.Body onClick={(e) => e.stopPropagation()}>
                          <form onSubmit={handleAssignOwner}>
                            <div className='mb-3'>
                              {/* <label htmlFor='owner'>Owner</label> */}
                              <div className='mb-3 d-flex align-items-center'>
                                <select
                                  id='owner'
                                  className='form-select form-control'
                                  aria-label='Select Owner'
                                  value={selectedOwner}
                                  onChange={(e) => setSelectedOwner(e.target.value)}
                                >
                                  <option value='' disabled selected>
                                    Select Owner
                                  </option>
                                  {personnels
                                    .filter((user) => user.role === 'Admin' || user.role === 'Owner')
                                    .map((personnel) => (
                                      <option key={personnel.id} value={personnel.full_name}>
                                        {personnel.full_name}
                                      </option>
                                    ))}
                                </select>
                                {selectedOwner && (
                                  <button
                                    className='btn btn-link p-0'
                                    onClick={() => setSelectedOwner('')}
                                  >
                                    <span aria-hidden='true' className='mx-2 fw-bold fs-2'>
                                      &times;
                                    </span>
                                  </button>
                                )}
                              </div>
                            </div>
                            <button
                              type='button'
                              onClick={handleResetClick}
                              className='btn btn-sm fs-6'
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#f1fdf9')
                              }
                              style={{
                                backgroundColor: '#f1fdf9',
                                color: '#45dbaf',
                                width: '80px',
                                // height: '40px',
                                borderRadius: '4px',
                                left: '615px',
                                top: '20px',
                                transition: 'background-color 0.3s',
                                marginRight: '12px',
                              }}
                            >
                              Reset
                            </button>
                            <button
                              type='submit'
                              className='btn btn-sm fs-6'
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#1CBB8D')
                              }
                              style={{
                                backgroundColor: '#1CBB8D',
                                color: '#fff',
                                width: '80px',
                                // height: '40px',
                                borderRadius: '4px',
                                left: '615px',
                                top: '20px',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              Apply
                            </button>
                          </form>
                        </Popover.Body>
                      </div>
                    </Popover>
                  )}
                </Overlay>
              </div>
            </>
          ) : (
            <>
              <div className="position-relative" ref={dropdownRef as React.RefObject<HTMLDivElement>}>
              <button
                type='button'
                onClick={toggleDropdown2}
                className='btn btn-sm btn-light me-4 fs-6 rotate'
                style={{
                  height: '40px',
                  background: '#fff',
                  color: '#000',
                  border: '2px solid #C5C5C5',
                }}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
          
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='#5A5A5A'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-filter mx-1 mb-1'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z' />
                </svg>
                Filter
                <i className='ki-duotone ki-down fs-3 ms-3 me-0 rotate-180'></i>
              </button>
            
              {isOpen && (
              <div
                className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                data-kt-menu='true'
                style={{
                  position: 'absolute',
                  top: '100%',
                  right: 0,
                  zIndex: 1000,
                  display: 'block',
                  backgroundColor: '#fff',
                  borderRadius: '0.475rem',
                  boxShadow: '0 0 50px 0 rgba(82,63,105,.15)',
                  border: '1px solid #ebedf3',
                }}
              >
                {/* begin::Header */}
                <div className='px-7 py-5'>
                  <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                </div>
                {/* end::Header */}

                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}

                {/* begin::Content */}
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  {/* begin::Input group */}
                  <div className='mb-3'>
                    <label className='form-label fs-6'>Framework:</label>
                    <div className='mb-3 d-flex align-items-center'>
                      <select
                        id='framework'
                        className='form-select form-control'
                        aria-label='Select Framework'
                        value={selectedFramework}
                        onChange={(e) => setSelectedFramework(e.target.value)}
                      >
                        <option value='' disabled selected>
                          Select Framework
                        </option>
                        <option value='ISO 27001'>ISO 27001</option>
                        <option value='SOC'>SOC 2</option>
                      </select>
                      {selectedFramework && (
                        <button
                          className='btn btn-link p-0'
                          onClick={(e) => {
                            setSelectedFramework('')
                            e.stopPropagation()
                          }}
                        >
                          <span aria-hidden='true' className='mx-2 fw-bold fs-2'>
                            &times;
                          </span>
                        </button>
                      )}
                    </div>
                  </div>

                  {/* end::Input group */}

                  {/* begin::Input group */}
                  <div className='mb-3'>
                    <label className='form-label  fs-6'>Status</label>
                    <div className='mb-3 d-flex align-items-center'>
                      <select
                        id='status'
                        className='form-select form-control'
                        aria-label='Select Status'
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                      >
                        <option value='' disabled selected>
                          Select Status
                        </option>
                        <option value='Pass'>OK</option>
                        <option value='Fail'>Failing</option>
                      </select>
                      {selectedStatus && (
                        <button
                          className='btn btn-link p-0'
                          onClick={(e) => {
                            setSelectedStatus('')
                            e.stopPropagation()
                          }}
                        >
                          <span aria-hidden='true' className='mx-2 fw-bold fs-2'>
                            &times;
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='owner' className='form-label  fs-6'>
                      Owner
                    </label>
                    <div className='mb-3 d-flex align-items-center'>
                      <select
                        id='owner'
                        className='form-select form-control'
                        aria-label='Select Owner'
                        value={selectedOwner}
                        onChange={(e) => setSelectedOwner(e.target.value)}
                      >
                        <option value='' disabled selected>
                          Select Owner
                        </option>
                        {personnels
                          .filter((data) => data.role === 'Admin' || data.role === 'Owner')
                          .map((personnel) => (
                            <option key={personnel.id} value={personnel.full_name}>
                              {personnel.full_name}
                            </option>
                          ))}
                      </select>
                      {selectedOwner && (
                        <button
                          className='btn btn-link p-0'
                          onClick={(e) => {
                            setSelectedOwner('')
                            e.stopPropagation()
                          }}
                        >
                          <span aria-hidden='true' className='mx-2 fw-bold fs-2'>
                            &times;
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                  {/* end::Input group */}
                  <div className='mb-3'>
                    <label htmlFor='author' className='form-label  fs-6'>
                      Author
                    </label>
                    <div className='mb-3 d-flex align-items-center'>
                      <select
                        id='author'
                        className='form-select form-control'
                        aria-label='Select Author'
                        value={selectedAuthor}
                        onChange={(e) => setSelectedAuthor(e.target.value)}
                      >
                        <option value='' disabled selected>
                          Select author
                        </option>
                        <option value='SecureSlate'>SecureSlate</option>
                        <option value='Custom Control'>Custom Control</option>
                      </select>
                      {selectedAuthor && (
                        <button
                          className='btn btn-link p-0'
                          onClick={(e) => {
                            setSelectedAuthor('')
                            e.stopPropagation()
                          }}
                        >
                          <span aria-hidden='true' className='mx-2 fw-bold fs-2'>
                            &times;
                          </span>
                        </button>
                      )}
                    </div>
                  </div>

                  {/* begin::Actions */}
                  <div className='d-flex justify-content-end mt-2'>
                    <button
                      type='button'
                      // disabled={isLoading}

                      className='btn btn-sm btn-light  fw-bold me-4 px-6 fs-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='reset'
                      onClick={(e) => {
                        handleResetClick()
                        e.stopPropagation()
                      }}
                      style={{height: '40px'}}
                    >
                      Reset
                    </button>
                    <button
                      // disabled={isLoading}
                      type='button'
                      onClick={filterData}
                      className='btn btn-sm fw-bold px-6 fs-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='filter'
                      style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                    >
                      Apply
                    </button>
                  </div>
                  {/* end::Actions */}
                </div>
                {/* end::Content */}
              </div>
              )}
              </div>
              <ControlListToolbar />
              {/* end::SubMenu */}
            </>
          )}
          {/* end::Group actions */}
        </div>
      </div>
      <Deactivate isOpen={showDeactivatemodel} onClose={handleDeactivate} id={deactivateId} />
      {/* begin::Body */}
      <div className='card-body pt-0'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold ' style={{color:"#5A5A5A"}}>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={selectAll}
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      onChange={handleSelectAllChange}
                    />
                  </div>
                </th>
                {/* <th className='min-w-150px'>CONTROL ID</th> */}
                <th className='min-w-250px'>CONTROL</th>
                <th className='min-w-100px'>OWNER</th>
                <th className='min-w-100px'>STATUS</th>
                <th className='min-w-100px'>MAPPED FRAMEWORKS</th>
                <th className='min-w-100px'>TESTS</th>
                <th className='min-w-100px'>AUTHOR</th>
                <th className='min-w-50px'></th>
              </tr>
            </thead>
            <ViewControl isOpen={isModalOpen} onClose={handleCloseModal} id={controlID} />
            {paginatedData && filteredItems.length > 0 ? (
              paginatedData.map((controls) => {
                return (
                  <tbody>
                    <tr key={controls.id} className=''>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value={controls.id}
                            checked={selectAll || checkedItems.includes(controls.id)}
                            onChange={handleCheckChange}
                          />
                        </div>
                      </td>
                      {/* <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary fs-6'
                          onClick={() => handleOpenModal(controls.id)}
                        >
                          {controls.control_id}
                        </a>
                      </td> */}
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary fs-6'
                          onClick={() => handleOpenModal(controls.id)}
                        >
                          {controls.control_title}
                        </a>
                      </td>

                      <td>
                        {/* <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {controls.control_owner}
                          </a>
                        </div> */}
                        <div className='d-flex align-items-center'>
                          {controls.control_owner ? (
                            <>
                              <div className='symbol symbol-35px me-5'>
                                <span
                                  className='symbol-label fs-7 fw-bold'
                                  style={{backgroundColor: '#DAF9EF'}}
                                >
                                  {controls.control_owner
                                    ? (() => {
                                        const nameParts = controls.control_owner.split(' ')
                                        if (nameParts.length > 1) {
                                          return `${nameParts[0]
                                            .charAt(0)
                                            .toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}`
                                        } else {
                                          return `${nameParts[0]
                                            .charAt(0)
                                            .toUpperCase()}${nameParts[0].charAt(1).toUpperCase()}`
                                        }
                                      })()
                                    : ''}
                                </span>
                              </div>
                              <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                                  {controls.control_owner}
                                </a>
                              </div>
                            </>
                          ) : (
                            <>
                              <span>-</span>
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        {controls.control_evidence_status === 'Deactivated' ? (
                          <a
                            href='#'
                            className='badge fs-7 text-dark text-bold m-1'
                            style={{background: '#F6F6F6'}}
                          >
                            {controls.control_evidence_status}
                          </a>
                        ) : (
                          <a
                            href='#'
                            className={`badge fs-7 ${
                              controls.control_evidence_status === 'Ok' ||
                              controls.control_evidence_status === 'OK'
                                ? 'badge-primary'
                                : 'badge-danger'
                            }`}
                            style={{
                              color:
                                controls.control_evidence_status === 'Ok' ||
                                controls.control_evidence_status === 'OK'
                                  ? '#1CBB8D'
                                  : '#EE3043',
                              background:
                                controls.control_evidence_status === 'Ok' ||
                                controls.control_evidence_status === 'OK'
                                  ? '#E8F8F4'
                                  : '#FFF5F8',
                            }}
                          >
                            {controls.control_evidence_status === 'Ok' ||
                            controls.control_evidence_status === 'OK'
                              ? 'OK'
                              : 'Unhealthy'}
                          </a>
                        )}
                      </td>
                      {/* <td>
                        <span
                          className='badge px-2 rounded-pill'
                          style={{border: '2px solid #E1E1E1'}}
                        >
                          <span
                            className={`badge border rounded-circle mx-1  ${getBadgeClass(
                              processControlValues(controls.control_document, controls.control_test),controls.author
                            )}`}
                            style={{
                              width: '12px',
                              height: '12px',
                              padding: '3px',
                            }}
                          >
                            {' '}
                          </span>

                          {controls.control_evidence_status === 'Ok' ||
                          controls.control_evidence_status === 'OK'
                            ? 'OK'
                            : 'Unhealthy'}
                        </span>
                      </td> */}
                      <td>
                        <div className='d-flex flex-column '>
                          <div className='d-flex flex-stack mb-2'>
                            <span className=' fs-7  fw-semibold'>
                              {controls.control_framework_mappings &&
                              typeof controls.control_framework_mappings === 'string' ? (
                                <span
                                  className='badge px-3 rounded-pill me-1'
                                  style={{border: '2px solid #E1E1E1'}}
                                >
                                  {controls.control_framework_mappings.split(',')[0].trim()}{' '}
                                </span>
                              ) : (
                                '--'
                              )}

                              {controls.control_framework_mappings &&
                                typeof controls.control_framework_mappings === 'string' &&
                                controls.control_framework_mappings.split(',').length > 1 &&
                                !showAllFrameworks && (
                                  <button
                                    className='btn btn-link '
                                    onClick={() => setShowAllFrameworks(true)}
                                  >
                                    <span
                                      className='badge px-3 rounded-pill'
                                      style={{border: '2px solid #E1E1E1'}}
                                    >
                                      +{controls.control_framework_mappings.split(',').length - 1}
                                    </span>
                                  </button>
                                )}
                            </span>
                          </div>
                          {showAllFrameworks &&
                            controls.control_framework_mappings &&
                            typeof controls.control_framework_mappings === 'string' && (
                              <>
                                {controls.control_framework_mappings
                                  .split(',')
                                  .slice(1)
                                  .map((framework, index) => (
                                    <div key={index} className='d-flex flex-stack mb-2'>
                                      <span className='text-muted me-2 fs-7 fw-semibold'>
                                        <span
                                          className='badge px-3 rounded-pill'
                                          style={{border: '2px solid #E1E1E1'}}
                                        >
                                          {framework.trim()}
                                        </span>
                                      </span>
                                    </div>
                                  ))}
                              </>
                            )}
                        </div>
                      </td>

                      <td>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-semibold'>
                              {processControlValues(
                                controls.control_document,
                                controls.control_test
                              )}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='fs-6 fw-semibold fw-block'>
                          {controls.author ? controls.author : 'SecureSlate'}
                        </div>
                      </td>
                      <td className=''>
                        <div className='dropdown mx-2'>
                          <button
                            className='btn btn-secondary border-0 p-0 m-2'
                            type='button'
                            id='dropdownMenu2'
                            data-bs-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                          >
                            <img
                              src='/media/buttons/Options.svg'
                              alt='Options'
                              onClick={toggleDropdown}
                            />
                          </button>
                          <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                            <li>
                              <button
                                className='dropdown-item text-hover-primary'
                                style={{color: '#5A5A5A'}}
                                type='button'
                                onClick={() => handleOpenModal(controls.id)}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-eye mx-1 '
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                                  <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
                                </svg>{' '}
                                View
                              </button>
                            </li>

                            {/* <li>
                              <button
                                className='dropdown-item text-muted text-hover-primary'
                                type='button'
                                onClick={() => handleEdit(controls.id)}
                              >
                               
                                <img src='/media/buttons/offboardp.svg' className='mx-2' alt='' />{' '}
                                Edit
                              </button>
                            </li> */}

                            <li>
                              {controls.author === 'Custom Control' ? (
                                <>
                                  <button
                                    className='dropdown-item'
                                    type='button'
                                    style={{color: '#F15363'}}
                                    onClick={(e) => handleDeleteClick(controls)}
                                  >
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='18'
                                      height='18'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      stroke='#F15363'
                                      strokeWidth='2'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                    >
                                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                      <path d='M4 7l16 0' />
                                      <path d='M10 11l0 6' />
                                      <path d='M14 11l0 6' />
                                      <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                      <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                    </svg>{' '}
                                    Remove
                                  </button>
                                </>
                              ) : controls.control_evidence_status === 'Deactivated' ? (
                                <button
                                  className='dropdown-item'
                                  type='button'
                                  style={{color: '#5A5A5A'}}
                                  onClick={(e) => handleActivateActivate(controls)}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='18'
                                    height='18'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='#5A5A5A'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-clock-pause mx-1 mb-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M20.942 13.018a9 9 0 1 0 -7.909 7.922' />
                                    <path d='M12 7v5l2 2' />
                                    <path d='M17 17v5' />
                                    <path d='M21 17v5' />
                                  </svg>{' '}
                                  Activate
                                </button>
                              ) : (
                                <button
                                  className='dropdown-item'
                                  type='button'
                                  style={{color: '#5A5A5A'}}
                                  onClick={(e) => handleDeactivateActivate(controls)}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='18'
                                    height='18'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='#5A5A5A'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-clock-pause mx-1 mb-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M20.942 13.018a9 9 0 1 0 -7.909 7.922' />
                                    <path d='M12 7v5l2 2' />
                                    <path d='M17 17v5' />
                                    <path d='M21 17v5' />
                                  </svg>{' '}
                                  Deactivate
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )
              })
            ) : (
              <tbody>
                <tr>
                  <td colSpan={10} className='text-center'>
                    {searchLength ? (
                      <div className='m-20'>
                        <img src='/media/buttons/emptystate.svg' alt='' />
                        <div className='m-5 fs-5'>
                          <p style={{fontWeight: 'bold'}}>No controls found</p>
                        </div>
                        <p>
                          We couldn't find any controls that match your search. Maybe try a
                          different search!
                        </p>
                      </div>
                    ) : !showEmptyState ? (
                      <div
                        className='d-flex justify-content-center align-items-center text-center'
                        style={{height: '500px'}}
                      >
                        <ControlListLoading />
                      </div>
                    ) : filterMatch ? (
                      <div className='m-20'>
                        <img src='/media/buttons/emptystate.svg' alt='' />
                        <div className='fs-5'>
                          <p style={{fontWeight: 'bold'}}>No filter results</p>
                        </div>
                        <p>Maybe try a different filter or reset filters</p>
                      </div>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      {filteredItems.length >= 15 && (
        <div className='d-flex justify-content-between'>
          <div
            id=''
            className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
          >
            <div>
              <select
                name='kt_permissions_table_length'
                aria-controls='kt_permissions_table'
                className='form-select  mx-8'
                id='dt-length-1'
                value={selectedNumber}
                onChange={(e) => setSelectedNumber(e.target.value)}
              >
                {/* <option value='20'>20</option> */}
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='150'>150</option>
              </select>
              {/* <label for='dt-length-1'></label> */}
            </div>
          </div>

          <div className='d-flex justify-content-end my-10'>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
      )}
    </>
  )
}

export {ControlManagement}

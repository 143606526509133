import React, {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import {VendorRisk} from './VendorRisk'
import {AssetListHeader} from './components/header/AssetListHeader'
import {KTCard} from '../../../_metronic/helpers'
import {AssetManagement} from './AssetManagement'
import {useAuth} from '../../modules/auth'
import db from '../../../services/firebase'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import { AssetPageTitle } from '../../../_metronic/layout/core/AssetPageData'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AssetManagementWrapper: FC = () => {
  const [assetID, setAssetID] = useState('')
  const [parentDataCount, setParentDataCount] = useState(0)
  const {currentUser} = useAuth()

  const getAssetIdHandler = (id) => {
    setAssetID(id)
  }
  useEffect(() => {
    getAsset()
  }, [])

  const handleDataCountChange = (dataCount) => {
    // Do something with the received dataCount, e.g., set it in the parent component's state
    // setParentDataCount(dataCount)
  }
  const getAsset = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'assets')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const assetData = snapshot.docs.map((item) => ({
          ...item.data(),
          id: item.id,
          is_repo:item.data().is_repo
        }))
        // setAssets(assetData)
        const newDataCount = assetData.filter((data)=>data.is_repo !== true)
        setParentDataCount(newDataCount.length)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  return (
    <>
      <AssetPageTitle
        icon='Icon-1.svg'
        title='Asset Management'
        // desc={`${parentDataCount + ' ' + 'assets'}`}
        desc='Monitor your devices and repositories that are within your audit scope'
        breadcrumbs={usersBreadcrumbs}
      >
        Asset Inventory Management
      </AssetPageTitle>
      <KTCard>
        <AssetListHeader />
        <AssetManagement
          className='mb-5 mb-xl-8'
          onDataCountChange={handleDataCountChange}
          getAssetID={getAssetIdHandler}
        />
        {/* {assetID !== undefined && (
          <EditAssetModel
            id={assetID}
            setAssetID={setAssetID}
            sendDataToParent={receiveDataFromChild}
          />
        )} */}

      </KTCard>
    </>
  )
}

export default AssetManagementWrapper

import {useEffect, useMemo, useRef, useState} from 'react'
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import Drawer from 'react-modern-drawer'
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import db from '../../../../services/firebase'
import ReactSlider from 'react-slider'
import {Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {toast} from 'react-toastify'

export interface addAsset {
  msg: string
  message: string
  checked?: boolean | undefined
}

interface Controls {
  id: string
  control_title: string
  control_framework_mappings: string
}

const AddRiskModal = () => {
  const [riskSource, setRiskSource] = useState('')
  const [owner, setRiskOwner] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [owners, setOwners] = useState<any[]>([])
  const [inherent_likelihood, setInherentLikelihood] = useState('')
  const [inherent_impact, setInherentImpact] = useState('')
  const [residual_likelihood, setResidualLikelihood] = useState('')
  const [residual_impact, setResidualImpact] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [treatment, setTreatment] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [control, setControl] = useState<any>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [selectedControls, setSelectedControls] = useState<Controls[]>([])
  const [addedControl, setAddedControl] = useState<Controls[]>([])
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const focusRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (focusRef.current) {
          focusRef.current.focus()
        }
      }, 300)
    }
  }, [isOpen])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    getControl()
  }, [activeFrameworks])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setOwners(risksData)
    })

    return unsubscribe
  }, [])

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const getControl = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })

        setControl(filteredControls)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  const closeDrawer = () => {
    setIsOpen(false)
    setFormErrors({})
  }

  const currentDate = new Date() // No need to import the Firestore module
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1 // Note: The month is zero-based
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!riskSource.trim()) {
      errors.riskSource = 'Risk name is required'
    }

    if (!description.trim()) {
      errors.description = 'Risk description is required'
    }

    if (!category.trim()) {
      errors.category = 'Risk category is required'
    }
    if (!owner.trim()) {
      errors.owner = 'Risk Owner is required'
    }

    if (!treatment.trim()) {
      errors.treatment = 'Treatment plan is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const newRisk = {
      riskSource,
      description,
      category,
      owner,
      inherent_impact,
      inherent_likelihood,
      residual_impact,
      residual_likelihood,
      treatment,
      risk_status: 'Incomplete',
      added_controls: addedControl,
      uid: currentUser?.uid,
      tenantId: currentUser?.tenantId,
      author:'Custom risks'
    }

    const modalContentElement = document.getElementById('kt_modal_4')

    if (!validateForm()) {
      setIsLoading(false)
      return
    }

    try {
      if (currentUser) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
        const policyRef = collection(tenantRef, 'risks')

        // Add the new risk document
        const newRiskDocRef = await addDoc(policyRef, {
          ...newRisk,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        })

        // Get the ID of the newly added risk
        const newRiskId = newRiskDocRef.id

        // Update each control that was added to the risk
        for (const control of addedControl) {
          const controlRef = doc(db, 'tenants', currentUser.tenantId!, 'controls', control.id)
          const controlDoc = await getDoc(controlRef)

          if (controlDoc.exists()) {
            const currentRiskScenarios = controlDoc.data().riskScenarios || []

            const newRiskScenario = {
              riskId: newRiskId,
              riskSource: riskSource,
              description: description,
            }

            // Add the new risk scenario to the control's riskScenarios array
            await updateDoc(controlRef, {
              riskScenarios: [...currentRiskScenarios, newRiskScenario],
              updated_at: serverTimestamp(),
            })
          }
        }
        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `${currentUser?.userName} added a new risk '${riskSource}'`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          action: 'Risk scenario added',
          target: 'Risk Management',
          username: currentUser?.userName,
          email: currentUser?.email,
          date: todaysDate,
        })

        toast.success('Risk scenario added successfully', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })

        closeDrawer()

      }

      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }

      // Reset the form and state
      setRiskSource('')
      setDescription('')
      setRiskOwner('')
      setCategory('')
      setInherentImpact('')
      setInherentLikelihood('')
      setResidualImpact('')
      setResidualLikelihood('')
      setTreatment('')
      setSelectedControls([])
      setAddedControl([])

      setTimeout(() => {
        closeDrawer()
        setIsLoading(false)
      }, 1000)
    } catch (err: any) {
      setIsLoading(false)
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
    }
  }

  const getBadgeClass = (risk) => {
    if (risk < 5) {
      return {backgroundColor: '#1CBB8D'} // Low risk (primary blue)
    } else if (risk >= 5 && risk <= 14) {
      return {backgroundColor: '#ffc107'} // Medium risk (warning yellow)
    } else if (risk >= 15 && risk < 25) {
      return {backgroundColor: '#dc3545'} // High risk (danger red)
    } else if (risk >= 25) {
      return {backgroundColor: '#A7222F'} // Critical risk (custom dark red)
    } else {
      return {backgroundColor: ''} // Default or undefined risk (secondary gray)
    }
  }

  const getRiskLevelText = (risk) => {
    if (risk < 5) {
      return 'Low'
    } else if (risk >= 5 && risk <= 14) {
      return 'Medium'
    } else if (risk >= 15 && risk < 25) {
      return 'High'
    } else if (risk >= 25) {
      return 'Critical'
    } else {
      return ''
    }
  }

  const handleChange = (event) => {
    // setTreatment(event.target.value)

    setTreatment(event.target.value)
    setFormErrors((prev) => ({...prev, treatment: ''}))
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const filteredControls = useMemo(() => {
    return control.filter((item) =>
      item.control_title.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }, [control, searchTerm])

  const handleCheckboxChange = (control) => {
    setSelectedControls((prevSelected) => {
      if (prevSelected.some((item) => item.id === control.id)) {
        return prevSelected.filter((item) => item.id !== control.id)
      } else {
        return [...prevSelected, control]
      }
    })
  }

  // Handle adding selected controls
  const addSelectedControls = () => {
    // Map selected controls to only include the desired fields
    const selectedControlData = selectedControls.map((control) => ({
      id: control.id,
      control_title: control.control_title,
      control_framework_mappings: control.control_framework_mappings,
    }))

    setAddedControl((prevAdded) => [...prevAdded, ...selectedControlData])
    setShowModal(false)
  }

  return (
    <>
      <button
        className='btn btn-sm fs-6'
        onClick={toggleDrawer}
        // data-bs-toggle='modal'
        data-bs-target='#kt_modal_4'
        style={{background: '#1CBB8D', height: '40px', color: '#fff'}}
        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
          (e.currentTarget.style.backgroundColor = '#148363')
        }
        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
          (e.currentTarget.style.backgroundColor = '#1CBB8D')
        }
      >
        <KTIcon iconName='plus' className='fs-2 text-white' />
        Add Scenario
      </button>
      {/* <AddRiskControls  isOpen={showModal} onCancel={handleCloseModal} /> */}
      <Drawer open={isOpen} onClose={closeDrawer} direction='right' className='' size='45%'>
      <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Add Risk Scenario
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={closeDrawer}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>

        <div
          className=' scroll-y overflow-auto p-6 mb-4'
          tabIndex={-1}
          style={{height: 'calc(100vh - 30px)'}}
        >
          <form id='kt_risk_form' className='form'>
            <div className='modal-content'>
              <div className='modal-body'>
                <div className=''>
                  <div className='card mb-4 p-4' style={{background: '#F6F6F6'}}>
                    <span
                      className='badge rounded-pill  p-1 mt-2'
                      style={{border: '1px solid #C5C5C5', width: '110px'}}
                    >
                      <span className='mx-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#EE3043'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-xbox-x'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z' />
                          <path d='M9 8l6 8' />
                          <path d='M15 8l-6 8' />
                        </svg>
                      </span>
                      <span className='fw-semibold fs-7 '>Incomplete</span>
                    </span>
                    <div className='fs-6 fw-semibold my-4'>
                      This risk is incomplete. Please fill all the mandatory fields below so that
                      the risk owner can approve.
                    </div>
                  </div>
                  <div className='form-group mb-4'>
                    <label className='fw-semibold fs-6 required'>Risk name</label>
                    <input
                      type='text'
                      className={`form-control ${formErrors.riskSource ? 'is-invalid' : ''}`}
                      value={riskSource}
                      placeholder='Enter Risk name'
                      onChange={(e) => {
                        setRiskSource(e.target.value)
                        setFormErrors((prev) => ({...prev, riskSource: ''}))
                      }}
                      ref={focusRef}
                      
                    />
                    {formErrors.riskSource && (
                      <span className='text-danger'>{formErrors.riskSource}</span>
                    )}
                  </div>
                  <div className='form-group mb-4'>
                    <label className=' fw-semibold fs-6 required'>Risk Description</label>
                    <textarea
                      className={`form-control ${formErrors.description ? 'is-invalid' : ''}`}
                      placeholder='Enter risk description'
                      value={description}
                      id='description'
                      rows={3}
                      onChange={(e) => {
                        setDescription(e.target.value)
                        setFormErrors((prev) => ({...prev, description: ''}))
                      }}
                    ></textarea>
                    {formErrors.description && (
                      <span className='text-danger'>{formErrors.description}</span>
                    )}
                  </div>

                  <div className='form-group mb-4'>
                    <label className='fw-semibold my-2 fs-6 required'>Category</label>

                    <select
                      name='category'
                      className={`form-select ${formErrors.category ? 'is-invalid' : ''}`}
                      data-control='select2'
                      data-placeholder='Select Category'
                      onChange={(e) => {
                        setCategory(e.target.value)
                        setFormErrors((prev) => ({...prev, category: ''}))
                      }}
                      value={category}
                    >
                      <option selected>Select category</option>

                      <option value='Access control'>Access control</option>
                      <option value='Artificial intelligence'>Artificial intelligence</option>
                      <option value='Asset management'>Asset management</option>
                      <option value='Business continuity and disaster recovery'>
                        Business continuity and disaster recovery
                      </option>
                      <option value='Communications security'>Communications security</option>
                      <option value='Compliance'>Compliance</option>
                      <option value='Cryptography'>Cryptography</option>
                      <option value='Environmental, social, and governance'>
                        Environmental, social, and governance
                      </option>
                      <option value='Fraud'>Fraud</option>
                      <option value='People operations'>People operations</option>
                      <option value='Incident response manegement'>
                        Incident response management
                      </option>
                      <option value='Information security policies'>
                        Information security policies
                      </option>
                      <option value='Operations security'>Operations security</option>
                      <option value='Information security operations'>
                        Information security operations
                      </option>
                      <option value='Physical and environmental security'>
                        Physical and environmental security
                      </option>
                      <option value='Privacy'>Privacy</option>
                      <option value='Vendor relationships'>Vendor relationships</option>
                      <option value='Software development and acquisition'>
                        Software development and acquisition
                      </option>
                      <option value='Trustworthiness'>Trustworthiness</option>
                    </select>
                    {formErrors.category && (
                      <span className='text-danger'>{formErrors.category}</span>
                    )}
                  </div>

                  <div className='form-group mb-4'>
                    <label className='fw-semibold my-2 fs-6 required'>Owner</label>

                    <select
                      name='riskOwner'
                      className={`form-select ${formErrors.owner ? 'is-invalid' : ''}`}
                      data-control='select2'
                      data-placeholder='Select Owner'
                      onChange={(e) => {
                        setRiskOwner(e.target.value)
                        setFormErrors((prev) => ({...prev, owner: ''}))
                      }}
                      value={owner}
                    >
                      <option selected value=''>
                        Select owner
                      </option>
                      {owners
                        .filter((user) => user.role === 'Admin' || user.role === 'Owner')
                        .map((owner) => (
                          <option key={owner.id} value={owner.full_name}>
                            {owner.full_name}
                          </option>
                        ))}
                    </select>
                    {formErrors.owner && (
                      <span className='text-danger'>{formErrors.owner}</span>
                    )}
                  </div>
                  <div className='separator my-8'></div>
                  <div className='form-group mb-4'>
                    <h4 className='fw-bold  required'>Risk Score</h4>
                    <span className='fs-6'>
                      Evaluate the risk based on event likelihood and its potential impact on your
                      organization with risk scores.
                    </span>
                    {/* <input
                        type='date'
                        className='form-control '
                        placeholder='Treatment Due'
                        value={treatmentDue}
                        onChange={(e: any) => {
                          setTreatmentDue(e.target.value)
                        }}
                      /> */}
                  </div>
                  <div className='card p-4 mb-8' style={{background: '#F6F6F6'}}>
                    <div className='form-group mb-4'>
                      <div className='d-flex justify-content-between'>
                        <label className='fw-semibold fs-6'>
                          <span className=' required'>Inherent risk </span>
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip id='tooltip-top'>
                                <span>
                                  Inherent risks are vulnerabilities or potential threats that exist
                                  within an organization before any controls or mitigation measures
                                  are implemented.
                                </span>
                              </Tooltip>
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#5A5A5A'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                              <path d='M12 9h.01' />
                              <path d='M11 12h1v4h1' />
                            </svg>
                          </OverlayTrigger>
                        </label>{' '}
                        <span
                          className='badge p-2 rounded-pill'
                          style={{border: '1px solid #C5C5C5'}}
                        >
                          <span
                            className={`badge border rounded-circle mx-1 `}
                            style={{
                              width: '12px',
                              height: '12px',
                              padding: '2px',

                              ...getBadgeClass(Number(inherent_likelihood) * Number(inherent_impact)),
                            }}
                          >
                            {' '}
                          </span>
                          <span className='fw-semibold  fs-7 mx-1'>
                            {getRiskLevelText(Number(inherent_likelihood) * Number(inherent_impact))}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className=' mb-4'>
                      <h6 className='fw-semibold mb-3'>
                        Likelihood{' '}
                        <OverlayTrigger
                          placement='right'
                          overlay={
                            <Tooltip id='tooltip-top'>
                              <span>
                                Likelihood estimates how probable it is that a risk event will
                                occur. Rate the chance of this risk happening based on historical
                                data, current conditions, and expert judgment.
                              </span>
                            </Tooltip>
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                            <path d='M12 9h.01' />
                            <path d='M11 12h1v4h1' />
                          </svg>
                        </OverlayTrigger>
                      </h6>
                      <div style={{padding: '10px 0 10px'}}>
                        <ReactSlider
                          className='mx-0'
                          marks
                          min={1}
                          max={5}
                          step={1}
                          value={inherent_likelihood}
                          onChange={(value) => setInherentLikelihood(value)}
                          renderTrack={(props, state) => {
                            const {index, value} = state
                            setInherentLikelihood(value)
                            const percentage = ((value - 1) / (5 - 1)) * 100
                            return (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: '6px',
                                  top: '0',
                                  background: index === 0 ? '#20c997' : '#e9ecef',
                                  borderRadius: '3px',
                                }}
                              />
                            )
                          }}
                          renderThumb={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '20px',
                                width: '20px',
                                backgroundColor: 'white',
                                border: '2px solid #20c997',
                                borderRadius: '50%',
                                cursor: 'grab',
                                top: '-8px',
                                zIndex: 0,
                              }}
                            />
                          )}
                          renderMark={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                width: '4px',
                                height: '12px',
                                backgroundColor: '#F6F6F6',
                                top: '16px',
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className='d-flex justify-content-between mx-3'>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <span key={num} className='text-bold' style={{fontSize: '12px'}}>
                            {num}
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className=' mb-4'>
                      <h6 className='fw-semibold mb-3'>
                        Impact{' '}
                        <OverlayTrigger
                          placement='right'
                          overlay={
                            <Tooltip id='tooltip-top'>
                              <span>
                                Impact measures the severity of consequences if a risk event occurs.
                                Evaluate how this risk could affect your organization's objectives,
                                operations, finances, or reputation.
                              </span>
                            </Tooltip>
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                            <path d='M12 9h.01' />
                            <path d='M11 12h1v4h1' />
                          </svg>
                        </OverlayTrigger>
                      </h6>
                      <div style={{padding: '10px 0 10px'}}>
                        <ReactSlider
                          className='mx-0'
                          marks
                          min={1}
                          max={5}
                          step={1}
                          value={inherent_impact}
                          onChange={(value) => setInherentImpact(value)}
                          renderTrack={(props, state) => {
                            const {index, value} = state
                            setInherentImpact(value)
                            const percentage = ((value - 1) / (5 - 1)) * 100
                            return (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: '6px',
                                  top: '0',
                                  background: index === 0 ? '#20c997' : '#e9ecef',
                                  borderRadius: '3px',
                                }}
                              />
                            )
                          }}
                          renderThumb={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '20px',
                                width: '20px',
                                backgroundColor: 'white',
                                border: '2px solid #20c997',
                                borderRadius: '50%',
                                cursor: 'grab',
                                top: '-8px',
                                zIndex: 0,
                              }}
                            />
                          )}
                          renderMark={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                width: '4px',
                                height: '12px',
                                backgroundColor: '#F6F6F6',
                                top: '14px',
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className='d-flex justify-content-between mx-3'>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <span key={num} className='text-bold' style={{fontSize: '12px'}}>
                            {num}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className='card p-4 ' style={{background: '#F6F6F6'}}>
                    <div className='form-group mb-4'>
                      <div className='d-flex justify-content-between'>
                        <label className='fw-semibold fs-6'>
                          <span className=' required'>Residual Risk </span>
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip id='tooltip-top'>
                                <span>
                                  Residual Risk represents the remaining risk after control measures
                                  have been implemented.
                                </span>
                              </Tooltip>
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#5A5A5A'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                              <path d='M12 9h.01' />
                              <path d='M11 12h1v4h1' />
                            </svg>
                          </OverlayTrigger>
                        </label>{' '}
                        <span
                          className='badge p-2 rounded-pill'
                          style={{border: '1px solid #C5C5C5'}}
                        >
                          <span
                            className={`badge border rounded-circle mx-1 `}
                            style={{
                              width: '12px',
                              height: '12px',
                              padding: '2px',

                              ...getBadgeClass(Number(residual_likelihood) * Number(residual_impact)),
                            }}
                          >
                            {' '}
                          </span>
                          <span className='fw-semibold  fs-7 mx-1'>
                            {getRiskLevelText(Number(residual_likelihood) * Number(residual_impact))}
                          </span>
                        </span>
                      </div>
                      {/* <input
                        type='date'
                        className='form-control '
                        placeholder='Treatment Due'
                        value={treatmentDue}
                        onChange={(e: any) => {
                          setTreatmentDue(e.target.value)
                        }}
                      /> */}
                    </div>
                    <div className=' mb-4'>
                      <h6 className='fw-semibold mb-3'>
                        Likelihood{' '}
                        <OverlayTrigger
                          placement='right'
                          overlay={
                            <Tooltip id='tooltip-top'>
                              <span>
                                Estimate how probable the risk event is now, considering your
                                current control measures. Has the chance of occurrence decreased?
                              </span>
                            </Tooltip>
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                            <path d='M12 9h.01' />
                            <path d='M11 12h1v4h1' />
                          </svg>
                        </OverlayTrigger>
                      </h6>
                      <div style={{padding: '10px 0 10px'}}>
                        <ReactSlider
                          className='mx-0'
                          marks
                          min={1}
                          max={5}
                          step={1}
                          value={residual_likelihood}
                          onChange={(value) => setResidualLikelihood(value)}
                          renderTrack={(props, state) => {
                            const {index, value} = state
                            setResidualLikelihood(value)
                            const percentage = ((value - 1) / (5 - 1)) * 100
                            return (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: '6px',
                                  top: '0',
                                  background: index === 0 ? '#20c997' : '#e9ecef',
                                  borderRadius: '3px',
                                }}
                              />
                            )
                          }}
                          renderThumb={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '20px',
                                width: '20px',
                                backgroundColor: 'white',
                                border: '2px solid #20c997',
                                borderRadius: '50%',
                                cursor: 'grab',
                                top: '-8px',
                                zIndex: 0,
                              }}
                            />
                          )}
                          renderMark={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                width: '4px',
                                height: '12px',
                                backgroundColor: '#F6F6F6',
                                top: '14px',
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className='d-flex justify-content-between mx-3'>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <span key={num} className='text-bold' style={{fontSize: '12px'}}>
                            {num}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className=' mb-4'>
                      <h6 className='fw-semibold mb-3'>
                        Impact{' '}
                        <OverlayTrigger
                          placement='right'
                          overlay={
                            <Tooltip id='tooltip-top'>
                              <span>
                                Evaluate the potential consequences if the risk occurs, taking into
                                account your mitigation strategies. Has the severity of the impact
                                been reduced?
                              </span>
                            </Tooltip>
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                            <path d='M12 9h.01' />
                            <path d='M11 12h1v4h1' />
                          </svg>
                        </OverlayTrigger>
                      </h6>
                      <div style={{padding: '10px 0 10px'}}>
                        <ReactSlider
                          className='mx-0'
                          marks
                          min={1}
                          max={5}
                          step={1}
                          value={residual_impact}
                          onChange={(value) => setResidualImpact(value)}
                          renderTrack={(props, state) => {
                            const {index, value} = state
                            setResidualImpact(value)
                            const percentage = ((value - 1) / (5 - 1)) * 100
                            return (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: '6px',
                                  top: '0',
                                  background: index === 0 ? '#20c997' : '#e9ecef',
                                  borderRadius: '3px',
                                }}
                              />
                            )
                          }}
                          renderThumb={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '20px',
                                width: '20px',
                                backgroundColor: 'white',
                                border: '2px solid #20c997',
                                borderRadius: '50%',
                                cursor: 'grab',
                                top: '-8px',
                                zIndex: 0,
                              }}
                            />
                          )}
                          renderMark={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                width: '4px',
                                height: '12px',
                                backgroundColor: '#F6F6F6',
                                top: '14px',
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className='d-flex justify-content-between mx-3'>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <span key={num} className='text-bold' style={{fontSize: '12px'}}>
                            {num}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='separator my-8'></div>
            <div className='form-group mb-4'>
              <h4 className='fw-bold required'>Treatment</h4>
              <span className='fs-6'>
                Select a treatment plan for this risk based on your risk source.
              </span>
              {/* <div className='d-flex justify-content-start mt-4'>
                <div className='form-check form-check-custom form-check-solid mx-2 p-1'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='Mitigate'
                    id='treatmentMitigate'
                    name='treatment'
                    checked={treatment === 'Mitigate'}
                    onChange={handleChange}
                  />
                </div>
                <span>
                  <span className='text-dark fw-semibold  d-block fs-6'>Mitigate</span>
                  <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                    Implement controls to reduce the risk's impact or likelihood
                  </span>
                </span>
              </div>

              <div className='d-flex justify-content-start mt-4'>
                <div className='form-check form-check-custom form-check-solid mx-2 p-1'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='Avoid'
                    id='treatmentAvoid'
                    name='treatment'
                    checked={treatment === 'Avoid'}
                    onChange={handleChange}
                  />
                </div>
                <span>
                  <span className='text-dark fw-semibold  d-block fs-6'>Avoid</span>
                  <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                    Stop activities that create risk for your organization
                  </span>
                </span>
              </div>

              <div className='d-flex justify-content-start mt-4'>
                <div className='form-check form-check-custom form-check-solid mx-2 p-1'>
                  <input
                    className='form-check-input'
  
                    type='radio'
                    value='Transfer'
                    id='treatmentTransfer'
                    name='treatment'
                    checked={treatment === 'Transfer'}
                    onChange={handleChange}
                  />
                </div>
                <span>
                  <span className='text-dark fw-semibold  d-block fs-6'>Transfer</span>
                  <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                    Shift responsibility for the risk to another party, such as an insurer
                  </span>
                </span>
              </div>

              <div className='d-flex justify-content-start mt-4 mb-4'>
                <div className='form-check form-check-custom form-check-solid mx-2 p-1'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='Accept'
                    id='treatmentAccept'
                    name='treatment'
                    checked={treatment === 'Accept'}
                    onChange={handleChange}
                  />
                </div>
                <span>
                  <span className='text-dark fw-semibold  d-block fs-6'>Accept</span>
                  <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                    Keep the risk as is without taking additional action
                  </span>
                </span>
              </div> */}

              {['Mitigate', 'Avoid', 'Transfer', 'Accept'].map((value) => (
                <div key={value} className='d-flex justify-content-start mt-4'>
                  <div className='form-check form-check-custom form-check-solid mx-2 p-1'>
                    <input
                      className={`form-check-input `}
                      type='radio'
                      value={value}
                      id={`treatment${value}`}
                      name='treatment'
                      checked={treatment === value}
                      onChange={handleChange}
                      style={formErrors.treatment ? {border: '1px solid red'} : {}}
                    />
                  </div>
                  <span>
                    <span className='text-dark fw-semibold d-block fs-6'>{value}</span>
                    <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                      {value === 'Mitigate' &&
                        "Implement controls to reduce the risk's impact or likelihood"}
                      {value === 'Avoid' &&
                        'Stop activities that create risk for your organization'}
                      {value === 'Transfer' &&
                        'Shift responsibility for the risk to another party, such as an insurer'}
                      {value === 'Accept' && 'Keep the risk as is without taking additional action'}
                    </span>
                  </span>
                </div>
              ))}
              {formErrors.treatment && <span className='text-danger'>{formErrors.treatment}</span>}
            </div>

            <div className='separator my-8'></div>

            <div className='form-group mb-4'>
              <h4 className='fw-bold'>
                Map Controls
                <span
                  className='fs-6'
                  style={{color: '#78829D', fontWeight: '500', paddingLeft: '5px'}}
                >
                  Optional
                </span>
              </h4>

              <div className='fs-6 mb-2'>
                Select controls that may reduce the residual risk score.
              </div>
              <button
                type='button'
                className='btn btn-sm fs-6'
                onClick={() => setShowModal(true)}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
                style={{
                  backgroundColor: '#1CBB8D',
                  color: '#fff',
                  width: '130px',
                  height: '40px',
                  borderRadius: '4px',
                  marginRight: '0px',
                  transition: 'background-color 0.3s',
                }}
              >
                Add Controls
              </button>
            </div>

            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              backdropClassName='bg-opacity-1'
              centered
              size='lg'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
              }}
              dialogClassName='modal-90w'
            >
              <Modal.Header
                closeButton
                style={{borderTopLeftRadius: '0.475rem', borderTopRightRadius: '0.475rem'}}
              >
                <Modal.Title>Add Controls</Modal.Title>
              </Modal.Header>
              <Modal.Body
                className='p-0 mt-4'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'calc(80vh - 60px)',
                  borderBottomLeftRadius: '0.475rem',
                  borderBottomRightRadius: '0.475rem',
                  overflow: 'hidden',
                }}
              >
                <div className='static-content'>
                  <div className='d-flex align-items-center position-relative my-1 px-6 '>
                    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                    <input
                      type='text'
                      data-kt-user-table-filter='search'
                      className='form-control ps-14'
                      placeholder='Search..'
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                </div>

                <div className='table-container' style={{overflowY: 'auto', flexGrow: 1}}>
                  <table className='table table-hover table-rounded gy-7 gs-7 m-0'>
                    <thead>
                      <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-top-2 border-gray-200'>
                        <th className='min-w-50px'></th>
                        <th className='min-w-500px'>CONTROLS</th>
                        <th className='min-w-100px'>MAPPED FRAMEWORKS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredControls && filteredControls.length > 0 ? (
                        filteredControls.map((item) => (
                          <tr key={item.id} className='px-6'>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input widget-9-check'
                                  type='checkbox'
                                  value={item.id}
                                  checked={selectedControls.some(
                                    (control) => control.id === item.id
                                  )}
                                  onChange={() => handleCheckboxChange(item)}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='fs-6 fw-bold'>{item.control_title}</div>
                            </td>
                            <td>{item.control_framework_mappings}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3} style={{height: '300px', border: 'none'}}>
                            <div
                              className='d-flex justify-content-center align-items-center text-center'
                              style={{height: '100%'}}
                            >
                              <div>
                                <img
                                  src='/media/buttons/emptystate.svg'
                                  alt=''
                                  className='img-fluid'
                                  style={{maxWidth: '100px'}}
                                />
                                <div className='fs-6'>
                                  <p style={{fontWeight: 'bold'}}>No control found!</p>
                                </div>
                                <p className='fs-7'>
                                  We couldn't find any control that matches your search. Maybe try a
                                  different search!
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className='modal-footer p-0 my-4 pt-4 px-6'>
                  <button
                    className='btn btn-sm btn-light fs-6'
                    onClick={() => setShowModal(false)}
                    style={{
                      width: '80px',
                      height: '40px',
                      borderRadius: '4px',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    Cancel
                  </button>
                  {isLoading ? (
                    <button
                      className='btn btn-sm fs-6'
                      // type='submit'
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                      style={{
                        backgroundColor: '#1CBB8D',
                        color: '#fff',
                        width: '190px',
                        height: '40px',
                        borderRadius: '4px',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      Add Controls...{' '}
                      <span className='spinner-border spinner-border-sm align-middle'></span>
                    </button>
                  ) : (
                    <button
                      className='btn btn-sm fs-6'
                      // type='submit'
                      onClick={addSelectedControls}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                      style={{
                        backgroundColor: '#1CBB8D',
                        color: '#fff',
                        width: '190px',
                        height: '40px',
                        borderRadius: '4px',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      Add Selected Controls
                    </button>
                  )}
                </div>
              </Modal.Body>
            </Modal>
            <div className='card mb-4' style={{background: '#F6F6F6'}}>
              <div
                className='card-header fs-6 fw-bold p-8 '
                style={{borderBottom: '1px solid #C5C5C5'}}
              >
                {' '}
                CONTROLS
              </div>
              <div className='card-body p-0 px-8'>
                {addedControl && addedControl.length > 0 ? (
                  addedControl.map((control) => (
                    // <div
                    //   key={control.id}
                    //   className='fs-6 fw-semibold py-8'
                    //   style={{
                    //     display: 'flex',
                    //     justifyContent: 'space-between',
                    //     width: '100%',
                    //     borderBottom: '1px dotted #C5C5C5',
                    //     padding: '8px 0',
                    //   }}
                    // >
                    //   <span>{control.control_title}</span>
                    //   <span>{control.control_framework_mappings}</span>
                    //   <button
                    //     onClick={() => {
                    //       setAddedControl((prev) => prev.filter((item) => item.id !== control.id)) // Remove control by id
                    //     }}
                    //     className='btn btn-sm btn-light-danger p-0'
                    //     style={{marginLeft: '10px'}}
                    //   >
                    //     <svg
                    //       xmlns='http://www.w3.org/2000/svg'
                    //       width='24'
                    //       height='24'
                    //       viewBox='0 0 24 24'
                    //       fill='none'
                    //       stroke='currentColor'
                    //       stroke-width='2'
                    //       stroke-linecap='round'
                    //       stroke-linejoin='round'
                    //       className='icon icon-tabler icons-tabler-outline icon-tabler-square-x'
                    //     >
                    //       <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    //       <path d='M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z' />
                    //       <path d='M9 9l6 6m0 -6l-6 6' />
                    //     </svg>
                    //   </button>
                    // </div>
                    <table className='table'>
                      <tbody>
                        {addedControl.map((control) => (
                          <tr key={control.id} style={{borderBottom: '1px dotted #C5C5C5'}}>
                            <td className='fs-6 fw-semibold py-8'>{control.control_title}</td>
                            <td className='fs-6 fw-semibold py-8'>
                              {control.control_framework_mappings}
                            </td>
                            <td className='fs-6 fw-semibold py-8'>
                              <button
                                onClick={() => {
                                  setAddedControl((prev) =>
                                    prev.filter((item) => item.id !== control.id)
                                  ) // Remove control by id
                                }}
                                className='btn btn-sm btn-light-danger p-0'
                                style={{marginLeft: '10px'}}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='currentColor'
                                  strokeWidth='2'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-square-x'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z' />
                                  <path d='M9 9l6 6m0 -6l-6 6' />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ))
                ) : (
                  <>
                    <div
                      className='card-body'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5A5A5A'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-adjustments-horizontal'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M14 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                        <path d='M4 6l8 0' />
                        <path d='M16 6l4 0' />
                        <path d='M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                        <path d='M4 12l2 0' />
                        <path d='M10 12l10 0' />
                        <path d='M17 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                        <path d='M4 18l11 0' />
                        <path d='M19 18l1 0' />
                      </svg>
                      <br />
                      <span className='fs-6 fw-semibold'>No Controls added</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              className='offcanvas-footer d-flex justify-content-end  border-top py-4'
              style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
            >
              <button type='button' className='btn btn-light fs-6 mx-4' onClick={closeDrawer}>
                Cancel
              </button>
              {isLoading ? (
                <button
                  type='submit'
                  className='btn'
                  style={{backgroundColor: '#1CBB8D', color: '#FFFFFF'}}
                >
                  Adding risk...
                  <span className='spinner-border spinner-border-sm align-middle'></span>
                </button>
              ) : (
                <button
                  type='submit'
                  onClick={handleSubmit}
                  className='btn btn-sm fs-6'
                  style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  Add risk
                </button>
              )}
            </div>
            {/* </div> */}
          </form>
        </div>
      </Drawer>
    </>
  )
}

export {AddRiskModal}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {doc, getDoc} from 'firebase/firestore'
import db from '../../../../services/firebase'
import Referal from './components/Referal'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined)
  const [showModal, setShowModal] = useState(false)
  const [userName, setUsername] = useState('')

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    const fetchIdentityData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''

        if (!tenantId) {
          // If either tenantId or userId is not available, exit early
          return
        }

        const tenantRef = doc(db, 'tenants', tenantId)
        const identityDocRef = doc(tenantRef, 'settings', 'user_detail')

        // Fetch the identity document
        const identityDoc = await getDoc(identityDocRef)
        setUsername(
          identityDoc.data()
            ? (identityDoc.data()?.firstname ?? '') + ' ' + (identityDoc.data()?.lastname ?? '')
            : currentUser?.userName ?? ''
        )
        setAvatarUrl(identityDoc.data() ? identityDoc.data()?.image_url : '')
      } catch (error) {
        console.error('Error fetching identity data:', error)
      }
    }

    fetchIdentityData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.tenantId, currentUser?.uid])


  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              {avatarUrl && avatarUrl !== '/media/svg/avatars/blank.svg' ? (
                <img
                  alt='logo'
                  src={avatarUrl}
                  {...(typeof window !== 'undefined' &&
                    'loading' in HTMLImageElement.prototype && {loading: 'lazy'})}
                />
              ) : (
                <div className='symbol symbol-35px'>
                  <span className='symbol-label fs-4 fw-bold ' style={{backgroundColor: '#DAF9EF'}}>
                    {currentUser?.userName
                      ? `${currentUser?.userName
                          ?.split(' ')[0]
                          ?.charAt(0)
                          .toUpperCase()}${currentUser?.userName
                          ?.split(' ')[1]
                          ?.charAt(0)
                          .toUpperCase()}`
                      : ''}
                  </span>
                </div>
              )}
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-6'>
                {currentUser?.userName}
                <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                  {currentUser?.role}
                </span>
              </div>
              <a href='#' className='fw-bold text-hover-primary fs-7' style={{color:'#5A5A5A'}}>
                {currentUser?.email}
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}

        {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Settings</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}

        {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      > */}
        {/* <a href='#' className='menu-link px-5'>
          <span className='menu-title'>Company Settings</span>
          <span className='menu-arrow'></span>
        </a> */}

        {/* <div className='menu-sub menu-sub-dropdown w-175px py-4'> */}
        {/* <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Monitoring
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Employee Onboarding
            </a>
          </div> */}

        {/* <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Refer and earn $500
            </a>
          </div> */}

        {/* <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div> */}

        {/* <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Monitoring</span>
              </label>
            </div>
          </div> */}
        {/* </div>
      </div> */}

        {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          Monitoring
        </a>
      </div> */}
        {(currentUser?.role === 'Admin' || currentUser?.role === 'Owner') && (
          <>
            <div className='menu-item px-5'>
              <a href='/company-settings' className='menu-link px-5'>
                Settings
              </a>
            </div>

            <div className='menu-item px-5'>
              <Link to='/training' className='menu-link px-5'>
                Employee Onboarding
              </Link>
            </div>
          </>
        )}
        <div className='menu-item px-5'>
          <a href='#' className='menu-link px-5' onClick={handleShowModal}>
            Refer and earn $150
          </a>
        </div>

        <div className='separator my-2'></div>

        {/* <Languages /> */}

        <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5 text-danger'>
            Sign Out
          </a>
        </div>
      </div>
      {showModal && (
        <>
          <div
            className='modal-backdrop show'
            style={{opacity: 0.5}}
            onClick={handleCloseModal}
          ></div>
          <Referal onClose={handleCloseModal} />
        </>
      )}
    </>
  )
}

export {HeaderUserMenu}

import React, {useEffect, useRef, useState} from 'react'
import db, {storage} from '../../../../services/firebase'
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import {addDoc, collection, doc, onSnapshot, serverTimestamp, updateDoc} from 'firebase/firestore'
import {useAuth} from '../../../modules/auth'
import Drawer from 'react-modern-drawer'
import Dropzone from 'react-dropzone'
import {toast} from 'react-toastify'

export interface policy {
  msg: string
  message: string
  fileUrl?: any
}

type FileDataType = {
  name: string
  url: string
  date: string
}
interface ViewProps {
  isOpen: boolean
  onClose: () => void
}

const AddPolicyModal: React.FC<ViewProps> = ({isOpen, onClose}) => {
  const [policy, setPolicy] = useState('')
  const {currentUser} = useAuth()
  const [frameworks, setFrameworks] = useState<string[]>([])
  const [owner, setOwner] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  // const [isOpen, setIsOpen] = useState(false)
  const [file, setFile] = useState<File[]>([])
  const drawerRef = useRef<HTMLDivElement>(null)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [dateOneYearFromNow, setDateOneYearFromNow] = useState('')
  const [owners, setOwners] = useState<any[]>([])
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const focusRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (focusRef.current) {
          focusRef.current.focus()
        }
      }, 300)
    }
  }, [isOpen])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setOwners(risksData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    const date = new Date()
    // setDate(date.toISOString().slice(0, 10))
    date.setFullYear(date.getFullYear() + 1)

    setDateOneYearFromNow(date.toISOString().slice(0, 10))
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleUpload = (acceptedFiles: File[]) => {
    // Add the accepted files to the component state
    setFile([...file, ...acceptedFiles])
  }

  const calculateTotalSize = (file: File[]): number => {
    return file.reduce((totalSize, file) => totalSize + file.size, 0)
  }

  const handleRemoveFile = () => {
    setFile([])
  }

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1 // Note: The month is zero-based
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!policy.trim()) {
      errors.policy = 'Policy name is required'
    }

    if (frameworks.length === 0) {
      errors.frameworks = 'Policy Framework is required'
    }

    if (!owner.trim()) {
      errors.owner = 'Policy Owner is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const modalContentElement = document.getElementById('kt_modal_2')
    if (!validateForm()) {
      setIsLoading(false)
      return
    }

    let fileData: FileDataType[] = []

    let totalSizeInKB = '0'

    if (file && file.length > 0) {
      const allowedTypes = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ]
      const totalSizeLimit = 5 * 1024 * 1024
      const totalSize = calculateTotalSize(file)
      totalSizeInKB = (totalSize / 1024).toFixed(2)

      if (totalSize > totalSizeLimit) {
        setErrorMessage(
          'Total file size exceeds 5 MB. Please upload files with a total size less than 5 MB.'
        )
        setIsLoading(false)
        return
      }

      const filteredFiles = file.filter((file) => allowedTypes.includes(file.type))

      if (filteredFiles.length === 0) {
        setErrorMessage('Invalid file type. Please select files with supported formats: docx, pdf')
        setIsLoading(false)
        return
      }

      try {
        for (const fileItem of filteredFiles) {
          const fileName = fileItem.name || ''

          const fileRef = ref(
            storage,
            `tenants/${currentUser?.tenantId}/policy/custom_template/${fileName}`
          )
          const uploadTask = uploadBytesResumable(fileRef, fileItem)

          const snapshot = await uploadTask
          const downloadURL = await getDownloadURL(snapshot.ref)
          fileData.push({name: fileName, url: downloadURL, date: dateOneYearFromNow})
        }
      } catch (err) {
        setErrorMessage('Error uploading file')
        setIsLoading(false)
        return
      }
    }

    try {
      const newPolicy = {
        policy,
        frameworks,
        owner,
        tenantId: currentUser?.tenantId,
        status: 'Incomplete',
        uid: currentUser?.uid,
        isChecked: false,
        fileSize: '',
        fileName: '',
        fileUrl: [] as string[],
        author: 'Custom policy',
      }

      if (fileData.length > 0) {
        newPolicy.fileSize = totalSizeInKB
        newPolicy.fileName = fileData[0].name
        newPolicy.fileUrl = fileData.map((file) => file.url)
      }

      if (currentUser) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
        const policyRef = collection(tenantRef, 'policies')

        await addDoc(policyRef, {
          ...newPolicy,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        })
        const testsCollectionRef = collection(tenantRef, 'tests')
        const policyToFilter = policy

        const unsubscribe = onSnapshot(testsCollectionRef, async (snapshot) => {
          const testsData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            policies: doc.data().policies,
          }))

          const filteredTests = testsData.filter((test) => test?.policies === policyToFilter)

          if (filteredTests.length > 0) {
            for (const test of filteredTests) {
              const testDocRef = doc(tenantRef, 'tests', test.id)

              try {
                await updateDoc(testDocRef, {
                  status: 'Pass',
                })
              } catch (error) {
                console.error(`Error updating test ${test.id}:`, error)
              }
            }
          }
        })

        toast.success('Custom policy added successfully', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })

        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `Added a new custom policy ${policy}`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          username: currentUser?.userName,
          date: todaysDate,
          email: currentUser?.email,
          target: 'policy Library',
          action: 'Policy added',
        })

        onClose()
      }

      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
    } catch (err) {
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
    } finally {
      setIsLoading(false)
      setOwner('')
      setPolicy('')
      setFrameworks([])
      setFile([])
    }
  }

  const handleFrameworkChange = (framework: string) => {
    setFrameworks((prevSelectedFrameworks) => {
      const updatedFrameworks = prevSelectedFrameworks.includes(framework)
        ? prevSelectedFrameworks.filter((f) => f !== framework)
        : [...prevSelectedFrameworks, framework]

      if (updatedFrameworks.length === 0) {
        setFormErrors((prev) => ({...prev, frameworks: 'Please select at least one framework.'}))
      } else {
        setFormErrors((prev) => ({...prev, frameworks: ''}))
      }

      return updatedFrameworks
    })
  }

  function bytesToKB(bytes) {
    return (bytes / 1024).toFixed(2)
  }

  const onCancel = () => {
    setFormErrors({})
    onClose()
  }

  return (
    <>
      {' '}
      <Drawer open={isOpen} onClose={onCancel} direction='right' className='' size='45%'>
        <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Add Custom Policy
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={onCancel}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>

        <div
          className='scroll-y overflow-auto p-7 mb-4'
          tabIndex={-1}
          style={{height: 'calc(100vh - 30px)'}}
        >
          <form id='kt_risk_form' className='form' onSubmit={handleSubmit}>
            <div className='modal-content'>
              <div className='modal-body'>
                <div className=''>
                  <div className='form-group mb-4'>
                    <label className='fw-semibold mb-2 fs-6 required'>Policy Name</label>
                    <input
                      type='text'
                      className={`form-control ${formErrors.policy ? 'is-invalid' : ''}`}
                      value={policy}
                      placeholder='Enter policy name'
                      onChange={(e) => {
                        setPolicy(e.target.value)
                        setFormErrors((prev) => ({...prev, policy: ''}))
                      }}
                      ref={focusRef}
                    />
                    {formErrors.policy && <span className='text-danger'>{formErrors.policy}</span>}
                  </div>

                  <div className='form-group mb-4'>
                    <label className='text-dark fw-semibold my-2 fs-6  required'>Owner Name</label>

                    <select
                      name='group'
                      className={`form-select ${formErrors.owner ? 'is-invalid' : ''}`}
                      data-control='select2'
                      data-placeholder='Select Owner'
                      value={owner}
                      onChange={(e) => {
                        setOwner(e.target.value)
                        setFormErrors((prev) => ({...prev, owner: ''}))
                      }}
                    >
                      <option value='' selected>
                        Select Owner
                      </option>
                      {owners
                        .filter((user) => user.role === 'Admin' || user.role === 'Owner')
                        .map((owner) => (
                          <option key={owner.id} value={owner.full_name}>
                            {owner.full_name}
                          </option>
                        ))}
                    </select>
                    {formErrors.owner && <span className='text-danger'>{formErrors.owner}</span>}
                  </div>
                  {/* <div className='form-group mb-4'>
                    <label className='fw-semibold my-2 fs-6 required'>Frameworks</label>
                    <select
                      className={`form-select ${formErrors.frameworks ? 'is-invalid' : ''}`}
                      id='framework'
                      value={frameworks}
                      onChange={handleFrameworkChange}
                      multiple
                    >
                      <option value='' disabled>
                        Select Frameworks
                      </option>
                      {activeFrameworks.map((framework) => (
                        <option key={framework} value={framework}>
                          {framework}
                        </option>
                      ))}
                    </select>
                    {formErrors.frameworks && (
                      <div className='invalid-feedback'>{formErrors.frameworks}</div>
                    )}
                  </div> */}

                  <div className='form-group w-100 mt-6'>
                    {/* Begin::Heading */}
                    <div className='mb-6'>
                      {/* Begin::Title */}
                      <h2 className='mb-3 fs-6'>Frameworks</h2>
                      {/* End::Title */}
                      {/* Begin::Description */}
                      <div className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                        Select all options that apply
                      </div>
                      {/* End::Description */}
                    </div>
                    {/* End::Heading */}

                    {/* Begin::Input group */}
                    <div
                      className='fv-row mb-4 fv-plugins-icon-container'
                      data-kt-buttons='true'
                      data-kt-initialized='1'
                    >
                      {activeFrameworks.map((framework) => (
                        <label
                          key={framework}
                          className={`form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed d-flex text-start p-6 ${
                            frameworks.includes(framework) ? 'active' : ''
                          }`}
                          style={{
                            backgroundColor: frameworks.includes(framework)
                              ? '#E8F8F4'
                              : 'transparent',
                            transition: 'background-color 0.3s',
                          }}
                          onMouseEnter={(e) =>
                            ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                          }
                          onMouseLeave={(e) =>
                            ((e.currentTarget as HTMLElement).style.backgroundColor = frameworks.includes(
                              framework
                            )
                              ? '#E8F8F4'
                              : 'transparent')
                          }
                        >
                          {/* Begin::Checkbox */}
                          <input
                            type='checkbox'
                            checked={frameworks.includes(framework)}
                            onChange={() => handleFrameworkChange(framework)}
                            className='form-check-input me-2'
                          />
                          {/* End::Checkbox */}

                          {/* Begin::Label */}
                          <span className='d-flex align-items-center'>
                            {/* Begin::Info */}
                            <span className='ms-4'>
                              <span className='fs-6 fw-bold text-gray-900 d-block'>
                                {framework}
                              </span>
                              {/* <span className='fw-semibold fs-6 text-gray-700'>
                                {getFrameworkDescription(framework)}
                              </span> */}
                            </span>
                            {/* End::Info */}
                          </span>
                          {/* End::Label */}
                        </label>
                      ))}

                      <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
                        {formErrors.frameworks && <div>{formErrors.frameworks}</div>}
                      </div>
                    </div>
                    {/* End::Input group */}
                  </div>

                  <div className='form-group mb-4'>
                    <div className='main-container '>
                      <label className='fw-semibold my-2  fs-6'>Template (Optional)</label>
                      {file.length > 0 ? (
                        <div className={`card mb-4 mb-xl-8 mt-4`}>
                          <div
                            className='card-header border-0 pt-6 mb-5'
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <img
                                src='/media/icon/file2.svg'
                                alt=''
                                className='img-fluid'
                                style={{width: '50px', height: '50px', marginRight: '10px'}}
                              />
                              <div className='d-flex justify-content-start flex-column fw-bold fs-5'>
                                {file[0].name}
                                <span
                                  className=' fw-semibold d-block fs-6'
                                  style={{color: '#5A5A5A'}}
                                >
                                  Size: {bytesToKB(file[0].size)} KB
                                </span>
                              </div>
                            </div>
                            <a href='#' className='btn btn-sm btn-active-light-primary btn-icon'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-x '
                                onClick={handleRemoveFile}
                                style={{cursor: 'pointer'}}
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M18 6l-12 12' />
                                <path d='M6 6l12 12' />
                              </svg>
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className='mb-4'>
                          <Dropzone
                            onDrop={handleUpload}
                            minSize={1024}
                            accept={{
                              'application/pdf': [],
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                [],
                            }}
                            maxSize={5 * 1024 * 1024}
                          >
                            {({getRootProps, getInputProps}) => (
                              <div
                                {...getRootProps({
                                  className: 'dropzone',
                                  style: {
                                    backgroundColor: '#fff', 
                                    padding: '20px',
                                    border: '2px dashed #ccc',
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                  },
                                })}
                              >
                                <input {...getInputProps()} />
                                <img
                                  src='/media/icon/uploadFile.svg'
                                  alt=''
                                  loading='lazy'
                                  className='mb-4 mt-4'
                                />
                                <p className='fw-bold fs-6'>
                                  Drag and drop or <a href='#'>select</a> a files
                                </p>
                                <p className='fs-6'>
                                  Upload upto 5 mb. Supported formats: docx, pdf
                                </p>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='offcanvas-footer d-flex justify-content-end  border-top py-4'
              style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
            >
              <button type='button' className='btn btn-light mx-2 mb-2' onClick={onCancel}>
                Cancel
              </button>
              {isLoading ? (
                <button
                  type='submit'
                  className='btn btn-sm'
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  style={{
                    backgroundColor: '#1CBB8D',
                    color: '#fff',
                    height: '40px',
                    borderRadius: '4px',
                    transition: 'background-color 0.3s',
                  }}
                  disabled
                >
                  Adding policy..{' '}
                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                </button>
              ) : (
                <button
                  className='btn btn-sm'
                  onClick={handleSubmit}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  // disabled={activities.length === 0}
                  style={{
                    backgroundColor: '#1CBB8D',
                    color: '#fff',
                    width: '110px',
                    height: '40px',
                    borderRadius: '4px',

                    transition: 'background-color 0.3s',
                  }}
                >
                  Add Policy
                </button>
              )}
            </div>
          </form>
        </div>
      </Drawer>
    </>
  )
}

export default AddPolicyModal

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
// import firebase from '../../../../services/firebase'
import {Helmet} from 'react-helmet'
import {collection, onSnapshot, doc, deleteDoc} from '@firebase/firestore'
import db from '../../../services/firebase'
import {useAuth} from '../../modules/auth'
import {PersonnelListToolbar} from './components/header/PersonnelListToolbar'
import Swal from 'sweetalert2'
import personnelServices from './services/personnel.services'
import {PersonnelListLoading} from './components/loading/PersonnelListLoading'
import 'react-modern-drawer/dist/index.css'
import ViewPersonnel from './components/ViewPersonnel'
import Offboarding from './components/Offboarding'
import {format, parseISO} from 'date-fns'
import {toast} from 'react-toastify'
import {getAuth, deleteUser} from 'firebase/auth'

type Props = {
  className: string
  allPoliciesChecked: boolean
}
interface UserData {
  uid: string
  id: string
  full_name: string
  role: string
  avatarUrl: string
  tenantId: string
  policiesAccepted?: boolean
  start_date: string
  group: string
  end_date: string
  email: string
  onboardingEnded: string
}

const PersonnelManagement: React.FC<Props> = ({className, allPoliciesChecked}) => {
  const [users, setUsers] = useState<UserData[]>([])
  const {currentUser} = useAuth()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredItems, setFilteredItems] = useState<UserData[]>([])
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [searchLength, setSearchLength] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [personnelID, setPersonnelID] = useState('')
  const [sortBy, setSortBy] = useState('') // Track currently sorted column
  const [sortDirection, setSortDirection] = useState('asc') // asc or desc

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    setCheckedItems([])
    setSelectAll(false)
  }, [])

  useEffect(() => {
    getPersonnel()
  }, [])

  useEffect(() => {
    setFilteredItems(users)
  }, [users])

  const handleOpenModal = (id) => {
    setIsModalOpen(true)
    setPersonnelID(id)
  }

  // Function to handle sorting based on column header click
  const handleSort = (column) => {
    const newSortDirection = column === sortBy && sortDirection === 'asc' ? 'desc' : 'asc'
    setSortBy(column)
    setSortDirection(newSortDirection)
  }

  // Function to render sort icon based on current sorting state
  const renderSortIcon = (column) => {
    if (column === sortBy) {
      return (
        <span
          className={`ms-1 icon-xxs text-muted ${
            sortDirection === 'asc' ? 'bi bi-arrow-up' : 'bi bi-arrow-down'
          }`}
        />
      )
    }
    return null
  }

  const handleOpenModal2 = (id) => {
    setIsModalOpen2(true)
    setPersonnelID(id)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    if (searchTerm === '') {
      setFilteredItems(users)
      setSearchLength(false)
    } else {
      const filteredItems = users.filter(
        (user) => user.full_name && user.full_name.toLowerCase().includes(searchTerm)
      )
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }

  const getPersonnel = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'employees')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs.map((doc) => ({
          ...(doc.data() as UserData),
          id: doc.id,
        }))

        setUsers(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting personnels:', error)
    }
  }

  function canOffboard(currentUserRole, targetUserRole) {
    if (currentUserRole === 'Admin') {
      return targetUserRole === 'Member'
    }

    if (currentUserRole === 'Owner') {
      return targetUserRole === 'Admin' || targetUserRole === 'Member'
    }
    return false
  }

  const handleCheckChange = (e) => {
    const {checked, value} = e.target
    setCheckedItems((prev) => (checked ? [...prev, value] : prev.filter((item) => item !== value)))
  }
  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked)
    if (e.target.checked) {
      setCheckedItems(
        filteredItems
          .filter((user) => user.tenantId === currentUser?.tenantId)
          .map((item) => item.id)
      )
    } else {
      setCheckedItems([])
    }
  }
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const handleDeselectClick = () => {
    setCheckedItems([])
    setSelectAll(false)
  }
  const handleCheckedRemoveClick = () => {
    checkedItems.forEach(async (id) => {
      try {
        const result = await Swal.fire({
          icon: 'warning',
          title: `Remove all checked employees ?`,
          text: `Are you sure you want to remove checked employees from the organaization? This action cannot be undone.`,
          showCancelButton: true,
          confirmButtonText: 'Yes, Remove',
          cancelButtonText: 'Cancel',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-secondary',
          },
          buttonsStyling: false,
        })

        if (result.isConfirmed) {
          // User confirmed, proceed with the deletion
          await personnelServices.deletePersonnel(id)
          // getPolicy()
          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: ` Employees has been offboarded.`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while deleting the policy.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      } finally {
        setDropdownVisible(false)
      }
    })
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCloseModal2 = () => {
    setIsModalOpen(false)
  }

  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      let date
      if (typeof timestamp === 'string') {
        // Handle ISO string format
        date = parseISO(timestamp)
      } else if (timestamp.seconds) {
        // Handle Firestore timestamp
        date = new Date(timestamp.seconds * 1000)
      } else {
        // Handle regular Date object
        date = new Date(timestamp)
      }

      if (isNaN(date.getTime())) {
        return 'Invalid Timestamp'
      }

      return format(date, 'd MMMM, yyyy')
    }
    return '--'
  }

  const handleDelete = async (item) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${item.full_name}`,
        text: `Are you sure you want to remove ${item.full_name} from the organization ? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const employeeRef = collection(tenantRef, 'employees')
        const docRef = doc(employeeRef, item.id)
        await deleteDoc(docRef)

        const userRef = collection(db, 'users')
        const docsRef = doc(userRef, item.uid)
        await deleteDoc(docsRef)

        // await deleteUser(item.uid)

        toast.success(`${item.full_name} has been removed from the organization successfully!`, {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })

        // const currentDate = new Date() // No need to import the Firestore module
        // const year = currentDate.getFullYear()
        // const month = currentDate.getMonth() + 1 // Note: The month is zero-based
        // const day = currentDate.getDate()

        // const todaysDate = month + '/' + day + '/' + year

        // const activitiesRef = collection(tenantRef, 'activities')
        // await addDoc(activitiesRef, {
        //   message: `${currentUser?.userName} has deleted vendor '${Data.vendor_name}'`,
        //   timestamp: serverTimestamp(),
        //   tenantId: currentUser?.tenantId,
        //   uid: currentUser?.uid,
        //   action: 'Vendor deleted',
        //   target: 'Vendor Risk',
        //   username: currentUser?.userName,
        //   date: todaysDate,
        // })

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${item.full_name}" has been removed.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while removing an employee.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // setDropdownVisible(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>Secureslate | Personnel Management</title>
      </Helmet>
      <div className='card-header border-0 pt-6 '>
        <div className='card-title'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control  w-250px ps-14'
              placeholder='Search Personnel..'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>

          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {checkedItems.length > 0 ? (
            <>
              {checkedItems.length > 0 ? (
                <div className='  border-end pe-4 text-center' style={{color: '#A1A5B7'}}>
                  <span>
                    <p className='pt-4'>{checkedItems.length} Selected</p>
                  </span>
                </div>
              ) : null}

              <div className='mx-5' style={{cursor: 'pointer'}} onClick={handleDeselectClick}>
                <img src='/media/buttons/Deselect.svg' alt='Deselect' />
              </div>

              <div className='mb-1' style={{cursor: 'pointer'}} onClick={handleCheckedRemoveClick}>
                <img
                  src='/media/buttons/offboard.svg'
                  alt='Offboard Employee'
                  style={{width: '185px'}}
                />
              </div>
            </>
          ) : (
            <PersonnelListToolbar />
          )}
          {/* <PersonnelListToolbar /> */}
          {/* end::Group actions */}
        </div>
      </div>

      {/* begin::Body */}
      <div className='card-body py-3' style={{minHeight: '100vh'}}>
        {/* begin::Table container */}
        <div className='table-responsive' style={{minHeight: '100vh'}}>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold fs-7' style={{color: '#5A5A5A'}}>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={selectAll}
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      onChange={handleSelectAllChange}
                    />
                  </div>
                </th>
                <th className='min-w-250px' onClick={() => handleSort('fullName')}>
                  NAME {renderSortIcon('fullName')}
                </th>
                <th className='min-w-100px' onClick={() => handleSort('group')}>
                  GROUP {renderSortIcon('group')}
                </th>
                <th className='min-w-100px' onClick={() => handleSort('startDate')}>
                  START DATE {renderSortIcon('startDate')}
                </th>
                <th className='min-w-100px' onClick={() => handleSort('endDate')}>
                  END DATE {renderSortIcon('endDate')}
                </th>
                <th className='min-w-100px' onClick={() => handleSort('onboardingEnded')}>
                  ONBOARDING COMPLETED ON {renderSortIcon('onboardingEnded')}
                </th>
                <th className='min-w-100px' onClick={() => handleSort('status')}>
                  STATUS {renderSortIcon('status')}
                </th>
                <th className='min-w-50px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <Offboarding isOpen={isModalOpen2} onClose={handleCloseModal2} id={personnelID} />

            {filteredItems && filteredItems.length > 0 ? (
              filteredItems
                .sort((a, b) => {
                  const key = sortBy
                  const direction = sortDirection === 'asc' ? 1 : -1
                  if (a[key] < b[key]) return -1 * direction
                  if (a[key] > b[key]) return 1 * direction
                  return 0
                })
                .map((item) => (
                  <tbody>
                    <tr key={item.id}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value={item.id}
                            checked={selectAll || checkedItems.includes(item.id)}
                            onChange={handleCheckChange}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-40px me-2'>
                            {item.full_name ? (
                              <>
                                <div className='symbol symbol-35px me-2'>
                                  <span
                                    className='symbol-label fs-7 fw-bold'
                                    style={{backgroundColor: '#DAF9EF'}}
                                  >
                                    {item.full_name
                                      ? (() => {
                                          const nameParts = item.full_name.split(' ')
                                          if (nameParts.length > 1) {
                                            return `${nameParts[0]
                                              .charAt(0)
                                              .toUpperCase()}${nameParts[1]
                                              .charAt(0)
                                              .toUpperCase()}`
                                          } else {
                                            return `${nameParts[0]
                                              .charAt(0)
                                              .toUpperCase()}${nameParts[0]
                                              .charAt(1)
                                              .toUpperCase()}`
                                          }
                                        })()
                                      : ''}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <span>-</span>
                              </>
                            )}
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary fs-6'
                              onClick={() => handleOpenModal(item.id)}
                            >
                              {item.full_name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {item.role}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center fw-bold'>
                          <div className='symbol symbol-20px me-15'>
                            <div className=' fs-6'>
                              <div>{item.group}</div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center fw-bold'>
                          <div className='symbol symbol-20px me-15'>
                            <div className=' fs-6'>{formatTimestamp(item.start_date)}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center fw-bold'>
                          <div className='symbol symbol-20px me-15'>
                            <div className=' fs-6'>
                              {item.end_date ? formatTimestamp(item.end_date) : '--'}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center fw-bold'>
                          <div className='symbol symbol-20px me-15'>
                            <div className=' fs-6'>
                              {item.onboardingEnded ? formatTimestamp(item.onboardingEnded) : '--'}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <span
                          className='badge fs-7'
                          style={{
                            color: !item.end_date ? '#1CBB8D' : '#EE3043',
                            background: !item.end_date ? '#E8F8F4' : '#FFF5F8',
                          }}
                        >
                          {' '}
                          {!item.end_date ? 'Active' : 'Ended'}
                        </span>
                      </td>
                      <td className=''>
                        <div className='dropdown mx-2'>
                          <button
                            className='btn btn-secondary border-0 p-0 m-2'
                            type='button'
                            id='dropdownMenu2'
                            data-bs-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                          >
                            <img
                              src='/media/buttons/Options.svg'
                              alt='Options'
                              onClick={toggleDropdown}
                            />
                          </button>
                          <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                            <li>
                              <button
                                className='dropdown-item text-muted text-hover-primary'
                                type='button'
                                onClick={() => handleOpenModal(item.id)}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-eye mx-1'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                                  <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
                                </svg>{' '}
                                View Employee
                              </button>
                            </li>

                            <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                style={{color: '#F15363'}}
                                onClick={() => handleOpenModal2(item.id)}
                                disabled={!canOffboard(currentUser?.role, item.role)}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='#F15363'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-user-minus mx-1 mb-1'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0' />
                                  <path d='M6 21v-2a4 4 0 0 1 4 -4h4c.348 0 .686 .045 1.009 .128' />
                                  <path d='M16 19h6' />
                                </svg>{' '}
                                Offboard Employee
                              </button>
                            </li>
                            {currentUser?.role === 'Owner' && currentUser.uid !== item.id ? (
                              <li>
                                <button
                                  className='dropdown-item text-muted'
                                  type='button'
                                  onClick={(e) => handleDelete(item)}
                                >
                                  <i className='fas fa-trash mx-2'></i> Remove Employee
                                </button>
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      </td>
                    </tr>{' '}
                  </tbody>
                ))
            ) : (
              <tbody>
                <tr>
                  {searchLength ? (
                    <td colSpan={6} className='text-center'>
                      <div className='m-20'>
                        <img src='/media/buttons/emptystate.svg' alt='' />
                        <div className='m-5 fs-5'>
                          <p style={{fontWeight: 'bold'}}>No personnel Found</p>
                        </div>
                        <p className=''>
                          We couldn't find any personnel that match your search. Maybe try a
                          different search!
                        </p>
                      </div>
                    </td>
                  ) : (
                    <td colSpan={6}>
                      <div
                        className='d-flex justify-content-center align-items-center text-center'
                        style={{height: '100%'}}
                      >
                        {!showEmptyState ? (
                          <div className='m-20'>
                            <PersonnelListLoading />
                          </div>
                        ) : (
                          <div className='m-20'>
                            <img src='/media/buttons/emptystate.svg' alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No personnel Added</p>
                            </div>
                            <p>
                              All the employees within your organization are required to address
                              here.
                            </p>
                          </div>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              </tbody>
            )}

            {/* end::Table body */}
          </table>

          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <ViewPersonnel isOpen={isModalOpen} onClose={handleCloseModal} id={personnelID} />
    </>
  )
}

export {PersonnelManagement}

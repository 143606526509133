/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import { KTIcon, useDebounce} from '../../../../../_metronic/helpers'
import { useAuth } from '../../../../modules/auth';
import db from '../../../../../services/firebase';
import {
  collection,
  doc,
  onSnapshot,
} from 'firebase/firestore'
interface DataRoomSearchComponentProps {
  onSearchTermChange: (term: string) => void;
}
const DataRoomSearchComponent = ({ onSearchTermChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { currentUser } = useAuth();
  const [items, setItems] = useState<any[]>([])
  const debouncedSearchTerm = useDebounce(searchTerm, 150);

  useEffect(() => {
    getData();
  }, [debouncedSearchTerm]);

  const getData = () => {
    try {
      const tenantId = currentUser?.tenantId || '';
      const tenantRef = doc(db, 'tenants', tenantId);
      const folderCollectionRef = collection(tenantRef, 'dataroom');

      const unsubscribe = onSnapshot(folderCollectionRef, (snapshot) => {
        const policies = snapshot.docs
          .filter((item) => {
            const data = item.data();
            return data.is_folder === true || data.is_file === true;
          })
          .map((item) => ({
            ...item.data(),
            id: item.id,
            folder_name:item.data().folder_name
          }));

        setItems(policies);
      });

      return unsubscribe;
    } catch (error) {
      console.error('Error getting policies:', error);
    }
  };


  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    
    onSearchTermChange(term);
  };


  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control w-250px ps-14'
          placeholder='Search Files..'
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export {DataRoomSearchComponent}

import React, {useEffect, useMemo, useState} from 'react'
import {Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {collection, doc, getDoc, onSnapshot, updateDoc} from 'firebase/firestore'
import db from '../../../../../services/firebase'
import {useAuth} from '../../../../modules/auth'

type Props = {
  controlId?: string
  isOpen: boolean
  onCancel: () => void
}

const AddRiskScenario: React.FC<Props> = ({controlId, isOpen, onCancel}) => {
  const [control, setControl] = useState<any>(null)
  const [risks, setRisks] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [showRecommendedOnly, setShowRecommendedOnly] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRisks, setSelectedRisks] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (controlId) {
      getControl()
      getRisks()
    }
  }, [controlId])

  const getControl = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const controlRef = doc(db, 'tenants', tenantId, 'controls', controlId!)
      const controlDoc = await getDoc(controlRef)
      if (controlDoc.exists()) {
        setControl({...controlDoc.data(), id: controlDoc.id})
      }
    } catch (error) {
      console.error('Error getting control:', error)
    }
  }

  const getRisks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const risksCollectionRef = collection(tenantRef, 'risks')

      const unsubscribe = onSnapshot(risksCollectionRef, (snapshot) => {
        const risksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setRisks(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting risks:', error)
    }
  }

  const handleToggleRecommended = () => {
    setShowRecommendedOnly(!showRecommendedOnly)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleSelectRisk = (riskId: string) => {
    setSelectedRisks((prev) =>
      prev.includes(riskId) ? prev.filter((id) => id !== riskId) : [...prev, riskId]
    )
  }

  const filteredRisks = useMemo(() => {
    return risks.filter((item) => {
      const matchesSearch = item.riskSource.toLowerCase().includes(searchTerm.toLowerCase())
      const isRecommended = item.recommendedControlsId?.includes(controlId) || false

      if (showRecommendedOnly) {
        return matchesSearch && isRecommended
      }
      return matchesSearch
    })
  }, [risks, searchTerm, showRecommendedOnly, controlId])

  const addSelectedRisks = async () => {
    try {
      setIsLoading(true)
      const tenantId = currentUser?.tenantId || ''
      const controlRef = doc(db, 'tenants', tenantId, 'controls', controlId!)

      const addedRisks: {
        id: string
        riskSource: string
        riskStatus: string
      }[] = []

      for (const riskId of selectedRisks) {
        const riskRef = doc(db, 'tenants', tenantId, 'risks', riskId)
        const riskDoc = await getDoc(riskRef)
        const currentAddedControls = riskDoc.exists() ? riskDoc.data().added_controls || [] : []

        const selectedRisk = risks.find((risk) => risk.id === riskId)

        if (selectedRisk) {
          const newControl = {
            id: controlId,
            control_title: control?.control_title || '',
            control_framework_mappings: control?.control_framework_mappings || [],
          }

          const controlExists = currentAddedControls.some((ctrl: any) => ctrl.id === controlId)

          if (!controlExists) {
            await updateDoc(riskRef, {
              added_controls: [...currentAddedControls, newControl],
            })

            addedRisks.push({
              id: selectedRisk.id,
              riskSource: selectedRisk.riskSource,
              riskStatus: selectedRisk.risk_status,
            })
          } 
        } 
      }

      // Update the control document with the added risks
      if (addedRisks.length > 0) {
        const controlDoc = await getDoc(controlRef)
        const currentRiskScenarios = controlDoc.exists()
          ? controlDoc.data().risk_scenario || []
          : []

        await updateDoc(controlRef, {
          risk_scenario: [
            ...currentRiskScenarios,
            ...addedRisks.map((risk) => ({
              riskSource: risk.riskSource,
              riskId: risk.id,
              //   isRecommended: risk.recommendedControlsId?.includes(controlId) || false,
              status: risk.riskStatus,
            })),
          ],
        })
      }

      setIsLoading(false)
      setSelectedRisks([])
      onCancel()
    } catch (error) {
      console.error('Error adding selected risks:', error)
      setIsLoading(false)
    }
  }

  return (
    <Modal
      show={isOpen}
      onHide={onCancel}
      backdropClassName='bg-opacity-1'
      centered
      size='lg'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
      dialogClassName='modal-90w'
    >
      <Modal.Header
        closeButton
        style={{borderTopLeftRadius: '0.475rem', borderTopRightRadius: '0.475rem'}}
      >
        <Modal.Title>
          Add Risks{' '}
          <OverlayTrigger
            placement='right'
            overlay={
              <Tooltip id='tooltip-top'>
                <span>
                  Add the Risk Scenario in the Risk Management page so that it can be displayed
                  here.
                </span>
              </Tooltip>
            }
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              viewBox='0 0 24 24'
              fill='none'
              stroke='#5A5A5A'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
              className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
            >
              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
              <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
              <path d='M12 9h.01' />
              <path d='M11 12h1v4h1' />
            </svg>
          </OverlayTrigger>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='p-0 mt-4'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(80vh - 60px)',
          borderBottomLeftRadius: '0.475rem',
          borderBottomRightRadius: '0.475rem',
          overflow: 'hidden',
        }}
      >
        <div className='static-content'>
          <div className='d-flex align-items-center position-relative  pb-6 px-6 pt-2'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control ps-14'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          {/* <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid my-4 px-6 mb-8'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={showRecommendedOnly}
              onChange={handleToggleRecommended}
            />
            <span className='fs-7 mx-2 fw-block fw-semibold'>Show recommended only</span>
          </div> */}
        </div>

        <div className='table-container' style={{overflowY: 'auto', flexGrow: 1}}>
          <table className='table table-hover table-rounded gy-7 gs-7 m-0'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-top-2 border-gray-200'>
                <th className='min-w-50px'></th>
                <th className='min-w-500px'>RISKS</th>
                <th className='min-w-100px'>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {filteredRisks && filteredRisks.length > 0 ? (
                filteredRisks.map((item) => (
                  <tr key={item.id} className='px-6'>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                          checked={selectedRisks.includes(item.id)}
                          onChange={() => handleSelectRisk(item.id)}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='fs-6 fw-bold'>
                        {item.riskSource}{' '}
                        {item.recommendedControlsId?.includes(controlId) && (
                          <span className='badge badge-light-success'>Recommended</span>
                        )}
                      </div>
                    </td>
                    <td>
                      {/* <span
                        className='badge fs-7'
                        style={{
                          color: item.riskStatus === 'Approved' ? '#1CBB8D' : '#EE3043',
                          background: item.riskStatus === 'Approved' ? '#E8F8F4' : '#FFF5F8',
                        }}
                      >
                        {' '}
                        {item.riskStatus}
                      </span> */}
                      <span
                        className={`badge fs-7 ${
                          item.risk_status === 'Incomplete'
                            ? 'badge-danger'
                            : item.risk_status === 'Needs approval'
                            ? 'badge-warning'
                            : 'badge-primary'
                        }`}
                        style={{
                          color:
                            item.risk_status === 'Incomplete'
                              ? '#EE3043'
                              : item.risk_status === 'Needs approval'
                              ? '#FFA800'
                              : '#1CBB8D',
                          background:
                            item.risk_status === 'Incomplete'
                              ? '#FFF5F8'
                              : item.risk_status === 'Needs approval'
                              ? '#FFF8DD'
                              : '#E8F8F4',
                        }}
                      >
                        {item.risk_status}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} style={{height: '300px', border: 'none'}}>
                    <div
                      className='d-flex justify-content-center align-items-center text-center'
                      style={{height: '100%'}}
                    >
                      <div>
                        <img
                          src='/media/buttons/emptystate.svg'
                          alt=''
                          className='img-fluid'
                          style={{maxWidth: '100px'}}
                        />
                        <div className='fs-6'>
                          <p style={{fontWeight: 'bold'}}>No risks found!</p>
                        </div>
                        <p className='fs-7'>
                          We couldn't find any risks that match your search. Maybe try a different
                          search!
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className='modal-footer p-0 my-4 pt-4 px-6'>
          <button
            className='btn btn-sm btn-light fs-6'
            onClick={onCancel}
            style={{
              width: '80px',
              height: '40px',
              borderRadius: '4px',
              transition: 'background-color 0.3s',
            }}
          >
            Cancel
          </button>
          {isLoading ? (
            <button
              className='btn btn-sm fs-6'
              type='submit'
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                width: '190px',
                height: '40px',
                borderRadius: '4px',
                transition: 'background-color 0.3s',
              }}
              disabled
            >
              Adding Risks...{' '}
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </button>
          ) : (
            <button
              className='btn btn-sm fs-6'
              type='submit'
              onClick={addSelectedRisks}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#148363')}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#1CBB8D')}
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                width: '190px',
                height: '40px',
                borderRadius: '4px',
                transition: 'background-color 0.3s',
              }}
            >
              Add Selected Risks
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddRiskScenario

import React, { useEffect, useState } from 'react';
import { SettingPageTitle } from '../components/header/SettingPageTitle';
import { collection, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import db from '../../../../services/firebase';
import { useAuth } from '../../../modules/auth';

const SettingFramework = () => {
  const [activeFrameworks, setActiveFrameworks] = useState<string[]>([]);
  const [snoozedFrameworks, setSnoozedFrameworks] = useState<string[]>([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    getActiveFrameworks();
  }, []);

  const getActiveFrameworks = () => {
    try {
      const tenantId = currentUser?.tenantId || '';
      const tenantRef = doc(db, 'tenants', tenantId);
      const testsCollectionRef = collection(tenantRef, 'active_frameworks');

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }));
        const frameworks = frameworksData[0]?.frameworks || [];
        setActiveFrameworks(frameworks);
      });

      return unsubscribe;
    } catch (error) {
      console.error('Error getting frameworks:', error);
    }
  };

  const toggleSnoozeFramework = async (framework) => {
    const tenantId = currentUser?.tenantId || '';
    const tenantRef = doc(db, 'tenants', tenantId);
    const updatedFrameworks = activeFrameworks.includes(framework)
      ? activeFrameworks.filter((fw) => fw !== framework)
      : [...activeFrameworks, framework];
    
    // Update the Firestore document
    await updateDoc(doc(tenantRef, 'active_frameworks', 'txMY8H6hn0kF5mX0lsit'), {
      frameworks: updatedFrameworks,
    });

    // Update the local state
    if (activeFrameworks.includes(framework)) {
      setActiveFrameworks(activeFrameworks.filter((fw) => fw !== framework));
      setSnoozedFrameworks([...snoozedFrameworks, framework]);
    } else {
      setSnoozedFrameworks(snoozedFrameworks.filter((fw) => fw !== framework));
      setActiveFrameworks([...activeFrameworks, framework]);
    }
  };

  return (
    <>
      <SettingPageTitle icon='Settings.svg' title='Settings' desc='Manage your settings'>
        Personnel Management
      </SettingPageTitle>
      <div className='card py-3'>
        <div className='card-body'>
          <div className='table-responsive' style={{ height: '200px', overflowY: 'auto' }}>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold fs-7' style={{color:'#5A5A5A'}}>
                  <th className='min-w-200px '>FRAMEWORK</th>
                  <th className='min-w-100px'>STATUS</th>
                  <th className='min-w-50px'></th>
                </tr>
              </thead>
              <tbody>
                {activeFrameworks.map((framework) => (
                  <tr key={framework}>
                    <td>
                      <div className='d-flex align-items-center fs-6'>{framework}</div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <a
                              href='#'
                              className='badge fs-7'
                              style={{
                                color: '#1CBB8D' ,
                                background:'#E8F8F4',
                              }}
                            >
                              {' '}
                            Active
                            </a>
                      </div>
                    </td>
                    {/* <td>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-sm fs-6'
                          style={{ background: '#1CBB8D', color: '#fff' }}
                          // onClick={() => toggleSnoozeFramework(framework)}
                        >
                          Snooze
                        </button>
                      </div>
                    </td> */}
                  </tr>
                ))}
                {/* {snoozedFrameworks.map((framework) => (
                  <tr key={framework}>
                    <td>
                      <div className='d-flex align-items-center mx-5 fs-6'>{framework}</div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                         Paused
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <button
                          className='btn btn-sm fs-6'
                          style={{ background: '#FF5733', color: '#fff' }}
                          onClick={() => toggleSnoozeFramework(framework)}
                        >
                          Unsnooze
                        </button>
                      </div>
                    </td>
                  </tr>
                ))} */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingFramework;




import { createUserWithEmailAndPassword, getAuth, User , signInWithEmailAndPassword, updateProfile,UserCredential, sendPasswordResetEmail } from 'firebase/auth';
import db, { auth1 } from '../../../../services/firebase';
import { collection, doc, setDoc } from 'firebase/firestore';
// import {  } from '@firebase/auth-types';

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
// export function login(email: string, password: string) {
//   return axios.post<AuthModel>(LOGIN_URL, {
//     email,
//     password,
//   })
// }
export function login(email: string, password: string): Promise<UserCredential> {
  return signInWithEmailAndPassword(auth1, email, password)
    .then((userCredential) => {
      // You can add additional tasks here if needed
      
      return userCredential;
    })
  
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

// Server should return AuthModel
export function registerWithEmailAndPassword(
  email: string,
  password: string,
  firstname: string,
  lastname: string,
  changePassword:string,
  activationCode:string,
  referralId:string
) {
  return createUserWithEmailAndPassword(auth1, email, password)
    .then(async (userCredential) => {
      // Update user profile with first name and last name
      await updateProfile(userCredential.user, {
        displayName: `${firstname} ${lastname}`,
        
        
      });
    //   const userDocRef = doc(collection(db, 'users'), userCredential.user.uid);
    // await setDoc(userDocRef, {
    //   tenantId: userCredential.user.tenantId, 
    //   // ... other properties
    // });

//       // You can add additional tasks here if needed
      return userCredential;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

// Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email: string) {
//   return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
//     email,
//   })
// }

// export function getUserByToken(token: string) {
//   return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
//     api_token: token,
//   })
// }
export function requestPassword(email: string): Promise<void> {
  const auth = getAuth();
  return sendPasswordResetEmail(auth, email);
}

export function getUserByToken(): Promise<User | null> {
  const auth = getAuth();
  return Promise.resolve(auth.currentUser);
}

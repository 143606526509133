import React, {useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {collection, doc, getDoc, onSnapshot, updateDoc} from 'firebase/firestore'
import db from '../../../../../services/firebase'
import {useAuth} from '../../../../modules/auth'

type Props = {
  id?: string
  isOpen: boolean
  onCancel: () => void
}

const AddRiskControls: React.FC<Props> = ({id, isOpen, onCancel}) => {
  const [risk, setRisk] = useState([] as any[])
  const [control, setControl] = useState<any>([])
  const {currentUser} = useAuth()
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [showRecommendedOnly, setShowRecommendedOnly] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedControls, setSelectedControls] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    getRisk()
  }, [id, showRecommendedOnly])

  useEffect(() => {
    getControl()
  }, [activeFrameworks])

  const getRisk = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'risks')

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            recommendedControlsId: doc.data().recommendedControlsId || [],
          }))
          .filter((data) => data.id === id)
        setRisk(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting risks:', error)
    }
  }
  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const getControl = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })

        setControl(filteredControls)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  useEffect(() => {
    if (control.length > 0 && risk.length > 0) {
      logMatchingRecommendedControls()
    }
  }, [control, risk])

  const logMatchingRecommendedControls = () => {
    const matchingControls = control.filter((item) => {
      return risk[0]?.recommendedControlsId?.includes(item.id) || false
    })
  }



  const handleToggleRecommended = () => {
    setShowRecommendedOnly(!showRecommendedOnly)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleSelectControl = (controlId: string) => {
    setSelectedControls((prev) =>
      prev.includes(controlId) ? prev.filter((id) => id !== controlId) : [...prev, controlId]
    )
  }

  const filteredControls = useMemo(() => {
    return control.filter((item) => {
      const matchesSearch = item.control_title.toLowerCase().includes(searchTerm.toLowerCase())
      const isRecommended = risk[0]?.recommendedControlsId?.includes(item.id) || false

      if (showRecommendedOnly) {
        return matchesSearch && isRecommended
      }
      return matchesSearch // This will show all controls that match the search when showRecommendedOnly is false
    })
  }, [control, searchTerm, showRecommendedOnly, risk])

  const addSelectedControls = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if(id){
      const riskRef = doc(db, 'tenants', tenantId, 'risks', id)
      const addedControls: {
        id: string
        control_title: string
        control_framework_mappings: string
      }[] = []

      for (const controlId of selectedControls) {
        const controlRef = doc(db, 'tenants', tenantId, 'controls', controlId)
        const controlDoc = await getDoc(controlRef)
        const currentRiskScenarios = controlDoc.exists() ? controlDoc.data().risk_scenario || [] : []

        // Find the corresponding control in the control array (not filteredControls)
        const selectedControl = control.find((ctrl) => ctrl.id === controlId)

        if (selectedControl) {
          const newRiskScenario = {
            riskSource: risk[0].riskSource,
            riskId: risk[0].id,
            isRecommended: risk[0]?.recommendedControlsId?.includes(selectedControl.id) || false,
            status:risk[0].risk_status
          }

          const riskScenarioExists = currentRiskScenarios.some(
            (scenario: any) =>
              scenario.riskId === risk[0].id && scenario.controlId === selectedControl.id
          )
          setIsLoading(true)
          if (!riskScenarioExists) {
            await updateDoc(controlRef, {
              risk_scenario: [...currentRiskScenarios, newRiskScenario],
            })

            addedControls.push({
              id: selectedControl.id,
              control_title: selectedControl.control_title,
              control_framework_mappings: selectedControl.control_framework_mappings,
            })
           
          } else {
            setIsLoading(false)
          }
        } else {
          setIsLoading(false)
        }
      }

      // Update the risk document with the added controls
      if (addedControls.length > 0) {
        const riskDoc = await getDoc(riskRef)
        const currentAddedControls = riskDoc.exists() ? riskDoc.data().added_controls || [] : []

        await updateDoc(riskRef, {
          added_controls: [...currentAddedControls, ...addedControls],
        })
      }
      setIsLoading(false)
      setSelectedControls([])
      onCancel()
    }
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      show={isOpen}
      onHide={onCancel}
      backdropClassName='bg-opacity-1'
      centered
      size='lg'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
      dialogClassName='modal-90w'
      onClick={(e) => e.stopPropagation()}
    >
      <Modal.Header
        closeButton
        style={{borderTopLeftRadius: '0.475rem', borderTopRightRadius: '0.475rem'}}
      >
        <Modal.Title>Add Controls</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='p-0 mt-4'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(80vh - 60px)',
          borderBottomLeftRadius: '0.475rem',
          borderBottomRightRadius: '0.475rem',
          overflow: 'hidden',
        }}
      >
        <div className='static-content'>
          <div className='d-flex align-items-center position-relative my-1 px-6 '>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control ps-14'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid my-4 px-6 mb-8'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={showRecommendedOnly}
              onChange={handleToggleRecommended}
            />
            <span className='fs-7 mx-2 fw-block fw-semibold'>Show recommended only</span>
          </div>
        </div>

        <div className='table-container' style={{overflowY: 'auto', flexGrow: 1}}>
          <table className='table table-hover table-rounded gy-7 gs-7 m-0'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-top-2 border-gray-200'>
                <th className='min-w-50px'></th>
                <th className='min-w-500px'>CONTROLS</th>
                <th className='min-w-100px'>MAPPED FRAMEWORKS</th>
              </tr>
            </thead>
            <tbody>
              {filteredControls && filteredControls.length > 0 ? (
                filteredControls.map((item) => (
                  <tr key={item.id} className='px-6'>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                          checked={selectedControls.includes(item.id)}
                          onChange={() => handleSelectControl(item.id)}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='fs-6 fw-bold'>{item.control_title}   {risk[0]?.recommendedControlsId?.includes(item.id) && (
                        <span className='badge badge-light-success'>Recommended</span>
                      )}</div>
                    
                    </td>
                    <td>{item.control_framework_mappings}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} style={{height: '300px', border: 'none'}}>
                    <div
                      className='d-flex justify-content-center align-items-center text-center'
                      style={{height: '100%'}}
                    >
                      <div>
                        <img
                          src='/media/buttons/emptystate.svg'
                          alt=''
                          className='img-fluid'
                          style={{maxWidth: '100px'}}
                        />
                        <div className='fs-6'>
                          <p style={{fontWeight: 'bold'}}>No control found!</p>
                        </div>
                        <p className='fs-7'>
                          We couldn't find any control that matches your search. Maybe try a
                          different search!
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className='modal-footer p-0 my-4 pt-4 px-6'>
          <button
            className='btn btn-sm btn-light fs-6'
            onClick={onCancel}
            style={{
              width: '80px',
              height: '40px',
              borderRadius: '4px',
              transition: 'background-color 0.3s',
            }}
          >
            Cancel
          </button>
          {isLoading ? (
            <button
              className='btn btn-sm fs-6'
              type='submit'
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                width: '190px',
                height: '40px',
                borderRadius: '4px',
                transition: 'background-color 0.3s',
              }}
              disabled
            >
              Adding Controls...{' '}
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </button>
          ) : (
            <button
              className='btn btn-sm fs-6'
              type='submit'
              onClick={addSelectedControls}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                width: '190px',
                height: '40px',
                borderRadius: '4px',
                transition: 'background-color 0.3s',
              }}
            >
              Add Selected Controls
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddRiskControls

/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../../../services/firebase'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const [users, setUsers] = useState<any[]>([])
  const {currentUser} = useAuth()

  useEffect(() => {
    getPersonnel()
  }, [])

  const getPersonnel = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'employees')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            end_date: doc.data().end_date,
          }))
          .filter((data) => data.id === currentUser?.uid)
        setUsers(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting personnels:', error)
    }
  }

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fw-bold fs-8 ls-1'>Overview</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/dashboard'
        icon='home'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 fw-bold ls-1'>
            Compliance
          </span>
        </div>
      </div>
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}
      <SidebarMenuItem to='/controls' icon='element-11' title='Controls' fontIcon='bi-layers' />
      <SidebarMenuItem to='/tests' icon='tablet-text-down' title='Tests' fontIcon='bi-layers' />
      {/* <SidebarMenuItem to='/tasks' icon='files-tablet' title='Tasks' fontIcon='bi-layers' /> */}
      <SidebarMenuItem to='/frameworks' icon='cube-2' title='Frameworks' fontIcon='bi-layers' />
      <SidebarMenuItem to='/reports' icon='chart-simple' title='Reports' fontIcon='bi-layers' />

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fw-bold fs-8 ls-1'>
            Governance
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/policy-library/lists'
        icon='document'
        title='Policy Library'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem to='/employees' icon='profile-user' title='Employees' fontIcon='bi-layers' />
      <SidebarMenuItem
        to='/asset-management'
        icon='monitor-mobile'
        title='Asset Management'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem to='/data-room' icon='folder-added' title='Data Room' fontIcon='bi-layers' />

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fw-bold fs-8 ls-1'>Risk</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/risk-management'
        icon='shield-tick'
        title='Risk Management'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/vendor-risk'
        icon='abstract-26'
        title='Vendor Risk'
        fontIcon='bi-layers'
      />
      {/* <SidebarMenuItemWithSub
        to='/clouds'
        title='Vulnerabilities'
        fontIcon='bi-archive'
        icon='share'
      >
          <SidebarMenuItem
        to='/clouds'
        icon='cloud'
        title='Clouds'
        fontIcon='bi-layers'
      />
        <SidebarMenuItem
        to='/domains'
        icon='abstract-32'
        title='Domains'
        fontIcon='bi-layers'
      />
      </SidebarMenuItemWithSub> */}

      {users[0]?.account_type === 'internal' ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-2 pb-2'>
              <span className='menu-section text-muted text-uppercase fw-bold fs-8 ls-1'>
                Connections
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to='/integrations'
            icon='arrows-loop'
            title='Integrations'
            fontIcon='bi-layers'
          />
        </>
      ) : (
        <></>
      )}

      {/* <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fw-bold fs-8 ls-1'>Vulnerabilities</span>
        </div>
      </div>
        <SidebarMenuItem
        to='/clouds'
        icon='cloud'
        title='Clouds'
        fontIcon='bi-layers'
      />
        <SidebarMenuItem
        to='/domains'
        icon='abstract-32'
        title='Domains'
        fontIcon='bi-layers'
      /> */}

      {/* <SidebarMenuItem to='/controls' icon='share' title='Controls' fontIcon='bi-layers' /> */}
      {/* <SidebarMenuItem to='/reports' icon='chart-simple' title='Frameworks' fontIcon='bi-layers' /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Compliance</span>
        </div>
      </div> */}

      {/* <SidebarMenuItem to='/reports' icon='cube-2' title='Frameworks' fontIcon='bi-layers' /> */}
      {/* {isChecked && (
        <SidebarMenuItem to='/chatgpt' icon='message-text-2' title='Chat' fontIcon='bi-layers' />
        )} */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}

import {useListView} from '../../../vendor/core/ListViewProvider'
import AddPolicyModal from '../AddPolicyModal'
import {useAuth} from '../../../../modules/auth'
import {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import db from '../../../../../services/firebase'
import {collection, doc, onSnapshot} from 'firebase/firestore'

type FileItemType = {
  id: string
  policy: string
  storage: string
  frameworks: any
  created_at: any
  owner: string
  fileUrl: string
  isChecked?: boolean
  tenantId?: string
  uid?: string
  reviewDate?: string
  updated_at?: string
  status?: string
  last_approved?: string
}
const PolicyListToolbar = (props) => {
  const {setItemIdForUpdate} = useListView()
  const {currentUser} = useAuth()
  const [policy, setPolicy] = useState<FileItemType[]>([])
  const [isCSV, setIsCSV] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const handleMannually = () => {
    setIsCSV(!isCSV)
  }

  const handleOpenModal2 = () => {
    setIsCSV(true)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    getPolicy()
  }, [])

  const getPolicy = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'policies')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const policyData = snapshot.docs.map((doc) => ({
          ...(doc.data() as FileItemType),
          id: doc.id,
        }))
        setPolicy(policyData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const handleExportAll = async () => {
    // Define a mapping of framework names to file URLs
    const frameworkToFileUrl = {
      'SOC 2': '/templates/SOC_2 Policies.zip',
      'ISO 27001:2022': '/templates/ISO 27001 Policies.zip',
    }

    // Check each active framework and download the corresponding file
    activeFrameworks.forEach((framework) => {
      const fileUrl = frameworkToFileUrl[framework] // Ensure the framework name matches the keys in the mapping
      if (fileUrl) {
        // Download the file for this framework
        const link = document.createElement('a')
        link.href = fileUrl
        link.setAttribute('download', '') // This will prompt the file to be downloaded
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    })
  }

  const handleDownloadClick = async (policyData: FileItemType) => {
    try {
      // Assuming policyData.fileUrl contains the URL to the policy file
      const fileUrl = policyData.fileUrl

      // Fetch the file content
      const response = await fetch(fileUrl)
      const fileContent = await response.blob()

      // Create a Blob with the file content
      const blob = new Blob([fileContent], {type: 'application/octet-stream'})

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob)

      // Create a hidden link
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = `${policyData.policy}.docx`

      // Append the link to the document
      document.body.appendChild(link)

      // Programmatically trigger a click on the link
      link.click()

      // Remove the link and revoke the Blob URL from the document
      document.body.removeChild(link)
      URL.revokeObjectURL(blobUrl)
    } catch (error) {
      console.error('Error downloading policy file:', error)
    }
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <PolicyListFilter /> */}
      {/* <AddTemplates/> */}
      <AddPolicyModal isOpen={isModalOpen} onClose={handleCloseModal} />

      <button
        // href='/templates/SOC_2 Policies.zip'
        type='button'
        className=' btn btn-sm btn-light me-4 fs-6'
        onClick={handleExportAll}
        // download
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#5A5A5A'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button>

      <button
        className='btn btn-sm fs-6'
        style={{background: '#1CBB8D', height: '40px', color: '#fff'}}
        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
          (e.currentTarget.style.backgroundColor = '#148363')
        }
        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
          (e.currentTarget.style.backgroundColor = '#1CBB8D')
        }
        onClick={handleOpenModal}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-plus'
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M12 5l0 14' />
          <path d='M5 12l14 0' />
        </svg>
        <span className='mx-2'>Add Policy</span>
      </button>
    </div>
  )
}

export {PolicyListToolbar}

import React, {FC, useEffect, useState} from 'react'
import {KTCard} from '../../../_metronic/helpers'
import {ControlManagement} from './ControlManagement'
import ControlCard from './components/header/ControlCard'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import db from '../../../services/firebase'
import { useAuth } from '../../modules/auth'

const ControlManagementWrapper: FC = () => {
  const [assetID, setAssetID] = useState('')
  const [parentDataCount, setParentDataCount] = useState(0)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [controls, setControls] = useState([] as any[])
  const [isLoading, setIsLoading] = useState(true)

  const getAssetIdHandler = (id) => {
    setAssetID(id)
  }

  const handleDataCountChange = (dataCount) => {
    setParentDataCount(dataCount)
  }

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    getControls()
  }, [activeFrameworks])

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting tests:', error)
      setIsLoading(false)
    }
  }
    
  const getControls = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })

        setControls(filteredControls)
        setIsLoading(false)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
      setIsLoading(false)
    }
  }

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {isLoading ? (
        <div style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <ControlCard />
          <KTCard>
            <ControlManagement
              className='mb-5 mb-xl-8'
              onDataCountChange={handleDataCountChange}
              getControlID={getAssetIdHandler}
            />
          </KTCard>
        </>
      )}
    </div>
  )
}

export default ControlManagementWrapper